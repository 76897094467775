import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeFlashMessage, getFlashMessage } from "../../../store/slices/commons/flashSliceCommon";

const Flash = () => {
    
    const dispatch = useDispatch();
	const { show, color, message } = useSelector(getFlashMessage);

    React.useEffect(() => {
        return () => {
            dispatch(closeFlashMessage());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDismiss = () => {
        dispatch(closeFlashMessage());
    }

    if (show) {
        return (
            <div className={`alert ${color} mt-5`}>
                {message}
                <button type="button" className="dismiss la la-times" onClick={handleDismiss} />
            </div>
        );
    };

    return "";
}
 
export default Flash;