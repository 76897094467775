import React from "react";

const FormMenuIngredients = ({ ingredients, menuIngredients, handleNewRow, handleDeleteRow, handleDetailsInputChange }) => {
   
    return ( 
        <React.Fragment>
            <div className="row">
                <table className="table mt-5 w-full">
                    <thead>
                        <tr>
                            <th className="text-left">Ingredient</th>
                            <th className="text-left" width="15%">Quantity</th>
                            <th className="text-left" width="1%"/>
                        </tr>
                    </thead>
                    <tbody>
                        {menuIngredients.map((menuIngredient, row) => 
                            <tr key={row}>
                                <td>
                                    <select id="ingredient_id" name="ingredient_id" className="form-control" value={menuIngredient.ingredient_id} data-id={row} data-section="menu_ingredients" onChange={handleDetailsInputChange}>
                                        <option value="">- Select -</option>
                                        {ingredients.map(ingredient => <option key={ingredient.id} value={ingredient.id}>{ingredient.name}</option>)}
                                    </select>
                                </td>
                                <td>
                                    <input id="quantity" name="quantity" type="text" className="form-control" value={menuIngredient.quantity} data-id={row} data-section="menu_ingredients" onChange={handleDetailsInputChange} />
                                </td>
                                <td className="text-right">
                                    <button className="btn btn-danger btn-icon" onClick={() => handleDeleteRow(row, "menu_ingredients")}>
                                        <span className="las la-trash-alt" />
                                    </button>
                                </td>
                            </tr>
                        )}
                        <tr>
                            <td colSpan="3" className="border-bottom-0 p-0">
                                <button type="button" className="btn btn-secondary w-full text-center" onClick={e => handleNewRow(e, "menu_ingredients", { ingredient_id: 0, quantity: 0 })}>
                                    <i className="las la-plus-circle" /> &nbsp; Add an ingredient
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
}
 
export default FormMenuIngredients;