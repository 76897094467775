import React from "react";
import PageHeader from "../elements/pageHeader";
import CompactSidebar from "../elements/compactSidebar";
import PageFooter from "../elements/pageFooter";
import OverlayPage from "../elements/overlayPage";

const Main = props => {

	const defaultNavigationItems = { 
		store: false, 
		library: false, 
		purchase: false, 
		report: false 
	};
	const [navigationItems, selectedNavigationItem] = React.useState(defaultNavigationItems);

	const handleClick = selectedItem => {
		selectedNavigationItem({ 
			...defaultNavigationItems, 
			[selectedItem]: !navigationItems[selectedItem] 
		});
	}

	const hadleResetActiveNavigationItem = () => {
		selectedNavigationItem({ ...defaultNavigationItems });
	}

    return ( 
        <React.Fragment>
			<PageHeader />
			<CompactSidebar navigationItems={navigationItems} handleClick={handleClick} />
			{(navigationItems.store || navigationItems.library || navigationItems.purchase) && <OverlayPage handleClick={hadleResetActiveNavigationItem} />}
			<div className="main-content">
				<div className="page-content">
					{props.children}
				</div>
				<PageFooter />
			</div>
		</React.Fragment>
    );
}
 
export default Main;