import { denormalize, normalize, schema } from "normalizr";

export const normalizeEntity = (datasources) => {
	const isArray = datasources instanceof Array;
	const dataset = isArray ? "payments" : "payment";
	const paymetnCategoryEntity = new schema.Entity("paymentCategory");
	const paymentEntity = new schema.Entity(
		dataset,
		{payment_category: paymetnCategoryEntity}
	);
	const result = normalize(
		datasources, 
		isArray ? [paymentEntity] : paymentEntity
	);

	return result.entities;
}

export const denormalizeEntity = (datasources) => {
	const isArray = datasources.payments;
	const dataset = isArray ? "payments" : "payment";
	const paymentEntity = new schema.Entity(dataset)
	const entities = isArray ? datasources.payments : datasources.payment;

	return denormalize(
		{ [dataset]: isArray ? Object.keys(entities) : Object.keys(entities).toString() }, 
		{ [dataset]: isArray ? [paymentEntity] : paymentEntity }, 
		{ [dataset]: entities }
	);
}