import { denormalize, normalize, schema } from "normalizr";

export const normalizeEntity = (datasources) => {
	const isArray = datasources instanceof Array;
	const dataset = isArray ? "diningTableSections" : "diningTableSection";
	const storeBranchEntity = new schema.Entity("storeBranch");
	const diningTableSectionEntity = new schema.Entity(
		dataset,
		{store_branch: storeBranchEntity}
	);
	const result = normalize(
		datasources, 
		isArray ? [diningTableSectionEntity] : diningTableSectionEntity
	);
	
	return result.entities;
}

export const denormalizeEntity = (datasources) => {
	const isArray = datasources.diningTableSections;
	const dataset = isArray ? "diningTableSections" : "diningTableSection";
	const diningTableSectionEntity = new schema.Entity(dataset)
	const entities = isArray ? datasources.diningTableSections : datasources.diningTableSection;

	return denormalize(
		{ [dataset]: isArray ? Object.keys(entities) : Object.keys(entities).toString() }, 
		{ [dataset]: isArray ? [diningTableSectionEntity] : diningTableSectionEntity }, 
		{ [dataset]: entities }
	);
}