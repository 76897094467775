import { createAsyncThunk } from "@reduxjs/toolkit";
import fsrHttpInstance, { fsrHttpConfiguration } from "../serviceFsrClients";
import { normalizeEntity } from "../entities/taxEntity";
import { setFlashSuccess, setFlashError } from "../slices/commons/flashSliceCommon";

export const fetchTaxes = createAsyncThunk(
	"datasources/taxes/fetchTaxes",
	async (request, thunkAPI) => {
		try {
			const params = request ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.get("vendor/taxes/index", {...params, ...fsrHttpConfiguration()}));
			const normalized = normalizeEntity(response.data.taxes);

			return { 
				...normalized, 
				pagination: response.data.pagination || {}
			};
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The tax could not be loaded. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const fetchTax = createAsyncThunk(
	"datasources/taxes/fetchTax",
	async (request, thunkAPI) => {
		try {
			const params = request ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.get("vendor/taxes/view", {...params, ...fsrHttpConfiguration()}));
			const normalized = normalizeEntity(response.data.tax);
			
			return normalized;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The tax could not be loaded. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const saveTax = createAsyncThunk(
	"datasources/taxes/saveTax",
	async (request, thunkAPI) => {
		try {
			const formData = { ...request };
			const action = formData.id ? "edit" : "add";
			const response = await fsrHttpInstance.then(fsrService => fsrService.post(`vendor/taxes/${action}`, formData, fsrHttpConfiguration()));

			thunkAPI.dispatch(setFlashSuccess("The tax has been saved."));
			return response.data;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The tax could not be saved. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const deleteTax = createAsyncThunk(
	"datasources/taxes/deleteTax",
	async (request, thunkAPI) => {
		try {
			const params = request.query ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.post("vendor/taxes/delete", null, {...params, ...fsrHttpConfiguration()}));

			thunkAPI.dispatch(setFlashSuccess("The tax has been deleted."));
			return response.data;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The tax could not be deleted. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);