import { createEntityAdapter, createSelector, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { fetchDiningTables, fetchDiningTable } from "../../thunks/diningTablesThunk";
import { fetchDiningTableSections, fetchDiningTableSection, saveDiningTableSection, deleteDiningTableSection } from "../../thunks/diningTableSectionsThunk";

const dataAdapter = createEntityAdapter();
const dataView = dataAdapter.getSelectors(state => state.datasources.diningTableSections);
const diningTableSectionsSliceDatasource = createSlice({
	name: "datasources/diningTableSections",
	initialState: dataAdapter.getInitialState({
		pagination: {}
	}),
	reducers: {
		unloaded: (diningTableSections, _) => {
			dataAdapter.removeAll(diningTableSections, {});
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchDiningTableSections.fulfilled, (diningTableSections, action) => {
				if (action.payload.hasOwnProperty("pagination")) {
					diningTableSections.pagination = action.payload.pagination;
				}
				
				if (action.payload.hasOwnProperty("diningTableSections")) {
					dataAdapter.setAll(diningTableSections, action.payload.diningTableSections);
				}
			})
			.addCase(fetchDiningTableSection.fulfilled, (diningTableSection, action) => {
				if (action.payload.hasOwnProperty("diningTableSection")) {
					dataAdapter.upsertMany(diningTableSection, action.payload.diningTableSection);
				}
			})
			.addCase(saveDiningTableSection.fulfilled, (diningTableSection, action) => {
				dataAdapter.upsertOne(diningTableSection, action.payload.diningTableSection);
			})
			.addCase(deleteDiningTableSection.fulfilled, (diningTableSection, action) => {
				dataAdapter.removeOne(diningTableSection, action.payload.diningTableSection.id);
			})
			.addMatcher(isAnyOf(fetchDiningTables.fulfilled, fetchDiningTable.fulfilled), (diningTableSection, action) => {
				if (action.payload.hasOwnProperty("diningTableSection")) {
					dataAdapter.setAll(diningTableSection, action.payload.diningTableSection);
				}
			});
	}
});
export default diningTableSectionsSliceDatasource.reducer;

export const unloadDiningTableSections = () => diningTableSectionsSliceDatasource.actions.unloaded();
export const getDiningTableSections = dataView.selectAll;
export const getDiningTableSectionEntities= dataView.selectEntities;
export const getDiningTableSectionPagination = createSelector(
	state => state.datasources.diningTableSections.pagination,
	pagination => pagination
);
export const getDiningTableSection = id => createSelector(
	state => state.datasources.diningTableSections.entities,
	diningTableSections => diningTableSections[id]
);