import React from "react";

const PageSubHeader = ({ title }) => {

    React.useEffect(() => {
        document.title = `FSR - ${title}`;
    });

    return (
        <div className="pageSubHeader bg-white">
            <h4>{title}</h4>
        </div>
    );
}
 
export default PageSubHeader;