import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { fetchStore } from "../../thunks/storesThunk";

const dataAdapter = createEntityAdapter();
const storeDocumentSettingsSliceDatasource = createSlice({
	name: "datasources/storeDocumentSettings",
	initialState: dataAdapter.getInitialState(),
	reducers: {
		unloaded: (storeDocumentSettings, _) => {
			dataAdapter.removeAll(storeDocumentSettings, {});
		}
	},
	extraReducers: build => {
		build
			.addCase(fetchStore.fulfilled, (storeDocumentSettings, action) => {
				if (action.payload.hasOwnProperty("storeDocumentSettings")) {
					dataAdapter.setAll(storeDocumentSettings, action.payload.storeDocumentSettings || []);
				}
			});
	}
});
export default storeDocumentSettingsSliceDatasource.reducer;

export const unloadStoreDocumentSettings = () => storeDocumentSettingsSliceDatasource.actions.unloaded();