import React from "react";

const FormMenuOptions = ({ menuOptions, handleNewRow, handleDeleteRow, handleDetailsInputChange }) => {

    return ( 
        <React.Fragment>
            <div className="row">
                <table className="table mt-5 w-full">
                    <thead>
                        <tr>
                            <th className="text-left"></th>
                            <th className="text-left" width="1%"/>
                        </tr>
                    </thead>
                    <tbody>
                        {menuOptions.map((menuOption, row) => 
                            <tr key={row}>
                                <td>
                                    <input id="option" name="option" type="text" className="form-control" value={menuOption.option} data-id={row} data-section="menu_options" onChange={handleDetailsInputChange} />
                                </td>
                                <td className="text-right">
                                    <button className="btn btn-danger btn-icon" onClick={() => handleDeleteRow(row, "menu_options")}>
                                        <span className="las la-trash-alt" />
                                    </button>
                                </td>
                            </tr>
                        )}
                        <tr>
                            <td colSpan="3" className="border-bottom-0 p-0">
                                <button type="button" className="btn btn-secondary w-full text-center" onClick={e => handleNewRow(e, "menu_options", { option: "" })}>
                                    <i className="las la-plus-circle" /> &nbsp; Add an Option
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
}
 
export default FormMenuOptions;