import axios from "axios";
import { configure } from "../config/app";

const fsrHttpInstance = async () => {
    const getHttpConfigure = async () => {
        return await axios.get("/configs.json");
    }

    const { data: configure } = await getHttpConfigure();
    return axios.create({ baseURL: configure.fsrServiceURL });
}
export default fsrHttpInstance();

export const fsrHttpConfiguration = () => {
	let authorization =  {};
	if (localStorage.getItem(configure.StorageKey.Vendor) !== null) {
		authorization = {
			"Authorization": `bearer ${JSON.parse(localStorage.getItem(configure.StorageKey.Vendor))['token']}`
		};
	}

	let currentStore =  {};
	if (localStorage.getItem(configure.StorageKey.CurrentStore) !== null) {
		currentStore = {
			"Current-Store": JSON.parse(localStorage.getItem(configure.StorageKey.CurrentStore))['id']
		};
	}

	return { 
		headers: { 
			...authorization, 
			...currentStore 
		}
	};
}