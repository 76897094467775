import { createEntityAdapter, createSelector, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { fetchCustomerCategories, fetchCustomerCategory, saveCustomerCategory, deleteCustomerCategory } from "../../thunks/customerCategoriesThunk";
import { fetchCustomers, fetchCustomer } from "../../thunks/customersThunk";

const dataAdapter = createEntityAdapter();
const dataView = dataAdapter.getSelectors(state => state.datasources.customerCategories);
const customerCategoriesSliceDatasource = createSlice({
	name: "datasources/customerCategories",
	initialState: dataAdapter.getInitialState({
		pagination: {}
	}),
	reducers: {
		unloaded: (customerCategories, _) => {
			dataAdapter.removeAll(customerCategories, {});
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchCustomerCategories.fulfilled, (customerCategories, action) => {
				if (action.payload.hasOwnProperty("pagination")) {
					customerCategories.pagination = action.payload.pagination;
				}

				if (action.payload.hasOwnProperty("customerCategories")) {
					dataAdapter.setAll(customerCategories, action.payload.customerCategories);
				}
			})
			.addCase(fetchCustomerCategory.fulfilled, (customerCategory, action) => {
				if (action.payload.hasOwnProperty("customerCategory")) {
					dataAdapter.upsertMany(customerCategory, action.payload.customerCategory);
				}
			})
			.addCase(saveCustomerCategory.fulfilled, (customerCategory, action) => {
				dataAdapter.upsertOne(customerCategory, action.payload.customerCategory);
			})
			.addCase(deleteCustomerCategory.fulfilled, (customerCategory, action) => {
				dataAdapter.removeOne(customerCategory, action.payload.customerCategory.id);
			})
			.addMatcher(isAnyOf(fetchCustomers.fulfilled, fetchCustomer.fulfilled), (customerCategory, action) => {
				if (action.payload.hasOwnProperty("customerCategory")) {
					dataAdapter.setAll(customerCategory, action.payload.customerCategory);
				}
			});
	}
});
export default customerCategoriesSliceDatasource.reducer;

export const unloadCustomerCategories = () => customerCategoriesSliceDatasource.actions.unloaded();
export const getCustomerCategories = dataView.selectAll;
export const getCustomerCategoryEntities = dataView.selectEntities;
export const getCustomerCategoryPagination = createSelector(
	state => state.datasources.customerCategories.pagination, 
	pagination => pagination
);
export const getCustomerCategory = id => createSelector(
	state => dataView.selectById(state, id), 
	customerCategory => customerCategory
);