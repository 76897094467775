import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { fetchWaiters, fetchWaiter, saveWaiter, deleteWaiter } from "../../thunks/waitersThunk";

const dataAdapter = createEntityAdapter();
const dataView = dataAdapter.getSelectors(state => state.datasources.waiters);
const waitersSliceDatasource = createSlice({
	name: "datasources/waiters",
	initialState: dataAdapter.getInitialState({
		pagination: {}
	}),
	reducers: {
		unloaded: (waiters, _) => {
			dataAdapter.removeAll(waiters, {});
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchWaiters.fulfilled, (waiters, action) => {
				if (action.payload.hasOwnProperty("pagination")) {
					waiters.pagination = action.payload.pagination;
				}
				
				if (action.payload.hasOwnProperty("waiters")) {
					dataAdapter.setAll(waiters, action.payload.waiters);
				}
			})
			.addCase(fetchWaiter.fulfilled, (waiter, action) => {
				if (action.payload.hasOwnProperty("waiter")) {
					dataAdapter.upsertMany(waiter, action.payload.waiter);
				}
			})
			.addCase(saveWaiter.fulfilled, (waiter, action) => {
				dataAdapter.upsertOne(waiter, action.payload.waiter);
			})
			.addCase(deleteWaiter.fulfilled, (waiter, action) => {
				dataAdapter.removeOne(waiter, action.payload.waiter.id);
			});
	}
});
export default waitersSliceDatasource.reducer;

export const unloadWaiters = () => waitersSliceDatasource.actions.unloaded();
export const getWaiters = dataView.selectAll;
export const getWaiterEntities = dataView.selectEntities;
export const getWaiterPagination = createSelector(
	state => state.datasources.waiters.pagination,
	pagination => pagination
);
export const getWaiter = id => createSelector(
	state => state.datasources.waiters.entities,
	waiters => waiters[id]
);