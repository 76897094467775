import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { unwrapResult } from '@reduxjs/toolkit'
import { fetchStation, saveStation } from "../../../store/thunks/stationsThunk";
import { getStation, unloadStations } from "../../../store/slices/datasources/stationsSliceDatasource";
import WithLoadingPage from "../commons/withLoadingPage";
import Flash from "../elements/flash";
import PageSubHeader from "../elements/pageSubHeader";

const StationForm = props => {
    
	const dispatch = useDispatch();
    const history = useHistory();
	const routeParams = useParams();
    const { id: stationId } = routeParams;
	const station = useSelector(getStation(stationId));
	const { setLoading } = props;
	const [request, setRequest] = React.useState({ id: null, store_branch_id: 0, name: "", printer: "", description: "" });
	const [error, setError] = React.useState({ name: null, printer: null });

    React.useEffect(() => {
        return () => {
            dispatch(unloadStations());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {		
        async function initialize() {
            setLoading(true);
            if (parseInt(stationId)) {
                await dispatch(fetchStation(getQueryFilter()));
            }
            setLoading(false);
        }
		
        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, stationId]); 
	
	React.useEffect(() => {
		if (station) {
			const mapViewToModel = {
				id: station.id,
				store_branch_id: station.store_branch_id,
				name: station.name,
				printer: station.printer,
				description: station.description
			}

			setRequest(prev => { 
                return { 
                    ...prev, 
                    ...mapViewToModel 
                }
            });
		}
	}, [station]);

    const getQueryFilter = () => {
        const queryFilter = {
            id: stationId,
        };

        return {
            query: queryFilter
        };
    }

	const handleInputChange = element => {
		const { name, value } = element.target;
		setRequest({...request, [name]: value});
	}

	const handleFormSubmit = async event => {
		event.preventDefault();
		setLoading(true);
        console.log(request);
		await dispatch(saveStation(request))
			.then(unwrapResult)
			.then(() => {
				setLoading(false);
				setError({ ...error, name: null, printer: null });
				history.push('/data/stations');
			})
			.catch(exception => {
				setLoading(false);
				setError(exception.errors);
			});
	}

    return (
        <React.Fragment>
            <PageSubHeader title={`${stationId === "add" ? "New" : "Edit"} Station`} />
            <div className="mx-auto w-2/4">
                <Flash />
                <div className="classic card">
                    <div className="card-header bg-light-600">
                        <h3 className="card-title">Station Form</h3>
                    </div>
                    <div className="card-body text-left">
                        <form className="w-full" onSubmit={handleFormSubmit}>
                            <div className="form-group">
                                <label className="form-label" htmlFor="name">Name</label>
                                <input id="name" name="name" type="text" className="form-control" value={request.name} onChange={handleInputChange} />
                            </div>
                            <div className="form-group">
                                <label className="form-label" htmlFor="printer">Printer</label>
                                <input id="printer" name="printer" type="text" className="form-control" value={request.printer} onChange={handleInputChange} />
                            </div>
                            <div className="form-group">
                                <label className="form-label" htmlFor="description">Description</label>
                                <textarea id="description" name="description" type="text" className="form-control" value={request.description} onChange={handleInputChange} rows="5" resize="none" />
                            </div>
                            <div className="form-group text-right">
                                <NavLink to="/data/stations" className="btn btn-secondary mr-4">
                                    <i className="las la-undo" />
                                    Back
                                </NavLink>
                                <button className="btn btn-primary mr-4">
                                    <i className="las la-save" />
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
 
export default WithLoadingPage(StationForm);