import { denormalize, normalize, schema } from "normalizr";

export const normalizeEntity = (datasources) => {
	const isArray = datasources instanceof Array;
	const dataset = isArray ? "salesInvoices" : "salesInvoice";
	const salesOrderEntity = new schema.Entity("salesOrder");
	const diningTableEntity = new schema.Entity("diningTable");
	const waiterEntity = new schema.Entity("waiter");
	const customerEntity = new schema.Entity("customer");
	const menuEntity = new schema.Entity("menu");
	const salesInvoiceDetailsEntity = new schema.Entity(
		"salesInvoiceDetails",
		{ menu: menuEntity }
	);
	const salesInvoicePaymentsEntity = new schema.Entity("salesInvoicePayments");
	const salesInvoiceEntity = new schema.Entity(
		dataset,
		{ 
			sales_order: salesOrderEntity,
			dining_table: diningTableEntity,
			cashier: waiterEntity,
			customer: customerEntity,
			sales_invoice_details: [salesInvoiceDetailsEntity],
			sales_invoice_payments: [salesInvoicePaymentsEntity],
		}
	);
	const result = normalize(
		datasources, 
		isArray ? [salesInvoiceEntity] : salesInvoiceEntity
	);
	
	return result.entities;
}

export const denormalizeEntity = (datasources) => {
	const isArray = datasources.salesInvoices;
	const dataset = isArray ? "salesInvoices" : "salesInvoice";
	const salesInvoiceEntity = new schema.Entity(dataset)
	const entities = isArray ? datasources.salesInvoices : datasources.salesInvoice;

	return denormalize(
		{ [dataset]: isArray ? Object.keys(entities) : Object.keys(entities).toString() }, 
		{ [dataset]: isArray ? [salesInvoiceEntity] : salesInvoiceEntity }, 
		{ [dataset]: entities }
	);
}