import { Route, Redirect } from "react-router-dom";
import { isAuthorized, hasSelectedStore } from "./authentication";

const AppRouter = ({ component: Component, layout: Layout, authorizedRequired, selectedStoreRequired, ...rest }) => {
	
	return (
		<Route 
			{...rest}
			render = {props => {
				if (authorizedRequired && !isAuthorized()) {
					return (<Redirect to={{ pathname: "/login" }} />);
				}

				if (selectedStoreRequired && !hasSelectedStore()) {
					return (<Redirect to={{ pathname: "/my-stores" }} />);
				}
				
				return (
					<Layout>
						<Component {... props} />
					</Layout>
				);
			}}
		/>
	);
};

export default AppRouter;