import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { unwrapResult } from '@reduxjs/toolkit'
import { fetchTax, saveTax } from "../../../store/thunks/taxesThunk";
import { getTax, unloadTaxes } from "../../../store/slices/datasources/taxesSliceDatasource";
import WithLoadingPage from "../commons/withLoadingPage";
import Flash from "../elements/flash";
import PageSubHeader from "../elements/pageSubHeader";

const TaxForm = props => {
    
	const dispatch = useDispatch();
    const history = useHistory();
	const routeParams = useParams();
    const { id: taxId } = routeParams;
	const tax = useSelector(getTax(taxId));
	const { setLoading } = props;
	const [request, setRequest] = React.useState({ id: null, periode: "", service_charge_percent: 0, vat_percent: 0, description: "" });
	const [error, setError] = React.useState({ periode: null, service_charge_percent: null, vat_percent: null });
 
    React.useEffect(() => {
        return () => {
            dispatch(unloadTaxes());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {		
        async function initialize() {
            setLoading(true);
            if (parseInt(taxId)) {
                await dispatch(fetchTax(getQueryFilter()));
            }
            setLoading(false);
        }
		
        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, taxId]); 
	
	React.useEffect(() => {
		if (tax) {
			const mapViewToModel = {
				id: tax.id,
				periode: tax.periode,
				service_charge_percent: tax.service_charge_percent,
				vat_percent: tax.vat_percent,
				description: tax.description
			}

			setRequest(prev => { 
                return { 
                    ...prev, 
                    ...mapViewToModel 
                }
            });
		}
	}, [tax]);

    const getQueryFilter = () => {
        const queryFilter = {
            id: taxId,
        };

        return {
            query: queryFilter
        };
    }

	const handleInputChange = element => {
		const { name, value } = element.target;
		setRequest({...request, [name]: value});
	}

	const handleFormSubmit = async event => {
		event.preventDefault();
		setLoading(true);
		await dispatch(saveTax(request))
			.then(unwrapResult)
			.then(() => {
				setLoading(false);
				setError({ ...error, periode: null, service_charge_percent: null, vat_percent: null });
				history.push('/data/taxes');
			})
			.catch(exception => {
				setLoading(false);
				setError(exception.errors);
			});
	}

    return (
        <React.Fragment>
            <PageSubHeader title={`${taxId === "add" ? "New" : "Edit"} Tax`} />
            <div className="mx-auto w-2/4">
                <Flash />
                <div className="classic card">
                    <div className="card-header bg-light-600">
                        <h3 className="card-title">Tax Form</h3>
                    </div>
                    <div className="card-body text-left">
                        <form className="w-full" onSubmit={handleFormSubmit}>
                            <div className="form-group">
                                <label className="form-label" htmlFor="periode">Periode</label>
                                <input id="periode" name="periode" type="text" className="form-control" value={request.periode} onChange={handleInputChange} placeholder="yyyy-mm-dd" />
                            </div>
                            <div className="form-group">
                                <div className="flex">
                                    <div className="w-1/2 mr-20">
                                        <label className="form-label" htmlFor="service_charge_percent">Service Charge Percent</label>
                                        <input id="service_charge_percent" name="service_charge_percent" type="text" className="form-control" value={request.service_charge_percent} onChange={handleInputChange} />
                                    </div>
                                    <div className="w-1/2 ml-2">
                                        <label className="form-label" htmlFor="vat_percent">VAT Percent</label>
                                        <input id="vat_percent" name="vat_percent" type="text" className="form-control" value={request.vat_percent} onChange={handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="form-label" htmlFor="description">Description</label>
                                <textarea id="description" name="description" type="text" className="form-control" value={request.description} onChange={handleInputChange} rows="5" resize="none" />
                            </div>
                            <div className="form-group text-right">
                                <NavLink to="/data/taxes" className="btn btn-secondary mr-4">
                                    <i className="las la-undo" />
                                    Back
                                </NavLink>
                                <button className="btn btn-primary mr-4">
                                    <i className="las la-save" />
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
 
export default WithLoadingPage(TaxForm);