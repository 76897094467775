import { createAsyncThunk } from "@reduxjs/toolkit";
import fsrHttpInstance, { fsrHttpConfiguration } from "../serviceFsrClients";
import { normalizeEntity } from "../entities/diningTableEntity";
import { setFlashSuccess, setFlashError } from "../slices/commons/flashSliceCommon";

export const fetchDiningTables = createAsyncThunk(
	"datasources/diningTables/fetchDiningTables",
	async (request, thunkAPI) => {
		try {
			const params = request ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.get("vendor/dining-tables/index", {...params, ...fsrHttpConfiguration()}));
			const normalized = normalizeEntity(response.data.diningTables);

			return { 
				...normalized, 
				pagination: response.data.pagination || {}  
			};
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The dining table could not be loaded. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const fetchDiningTable = createAsyncThunk(
	"datasources/diningTables/fetchDiningTable",
	async (request, thunkAPI) => {
		try {
			const params = request ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.get("vendor/dining-tables/view", {...params, ...fsrHttpConfiguration()}));
			const normalized = normalizeEntity(response.data.diningTable);
		
			return normalized;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The dining table could not be loaded. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const saveDiningTable = createAsyncThunk(
	"datasources/diningTables/saveDiningTable",
	async (request, thunkAPI) => {
		try {
			const formData = { ...request };
			const action = formData.id ? "edit" : "add";
			const response = await fsrHttpInstance.then(fsrService => fsrService.post(`vendor/dining-tables/${action}`, formData, fsrHttpConfiguration()));

			thunkAPI.dispatch(setFlashSuccess("The dining table has been saved."));
			return response.data;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The dining table could not be saved. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const deleteDiningTable = createAsyncThunk(
	"datasources/diningTables/deleteDiningTable",
	async (request, thunkAPI) => {
		try {
			const params = request.query ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.post("vendor/dining-tables/delete", null, {...params, ...fsrHttpConfiguration()}));

			thunkAPI.dispatch(setFlashSuccess("The dining table has been deleted."));
			return response.data;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The dining table could not be deleted. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);