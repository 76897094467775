import React from "react";

const Authentication = props => {
	
    return ( 
        <React.Fragment>
			<div className="authentication">
				{props.children}
			</div>
		</React.Fragment>
    );
}
 
export default Authentication;