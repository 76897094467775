import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { fetchStores, fetchStore, saveStore } from "../../thunks/storesThunk";

const dataAdapter = createEntityAdapter();
const dataView = dataAdapter.getSelectors(state => state.datasources.stores);
const storesSliceDatasource = createSlice({
	name: "datasources.stores",
	initialState: dataAdapter.getInitialState({
		pagination: {}
	}),
	reducers: {
		unloaded: (stores, _) => {
			dataAdapter.removeAll(stores, {});
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchStores.fulfilled, (stores, action) => {
				if (action.payload.hasOwnProperty("pagination")) {
					stores.pagination = action.payload.pagination;
				}
				
				if (action.payload.hasOwnProperty("stores")) {
					dataAdapter.setAll(stores, action.payload.stores);
				}
			})
			.addCase(fetchStore.fulfilled, (store, action) => {
				if (action.payload.hasOwnProperty("store")) {
					dataAdapter.upsertMany(store, action.payload.store);
				}
			})
			.addCase(saveStore.fulfilled, (store, action) => {
				dataAdapter.upsertOne(store, action.payload.store);
			});
	}
});
export default storesSliceDatasource.reducer;

export const unloadStores = () => storesSliceDatasource.actions.unloaded();
export const getStores = dataView.selectAll;
export const getStoreEntities = dataView.selectEntities;
export const getStore = id => createSelector(
	state => state.datasources.stores.entities,
	stores => stores[id]
);
export const getStoreDocumentSettings = storeId => createSelector(
	state => dataView.selectById(state, storeId),
	state => state.datasources.storeDocumentSettings.ids.map(id => state.datasources.storeDocumentSettings.entities[id]),
	(store, storeDocumentSettings) => {
		return Object.keys(storeDocumentSettings)
			.map(key => storeDocumentSettings[key])
			.filter(storeDocumentSetting => store.store_document_settings.includes(storeDocumentSetting.id));
	}
);