import { NavLink } from "react-router-dom";

const DataTable = ({ menus, menuCategoryEntities, handleDelete }) => {

    return (
        <table className="table table-striped">
            <thead>
                <tr>
                    <th className="text-left" width="15%">Code</th>
                    <th className="text-left" width="20%">Name</th>
                    <th className="text-left" width="20%">Category</th>
                    <th className="text-left">Description</th>
                    <th className="text-left" width="1%"/>
                    <th className="text-left" width="1%"/>
                </tr>
            </thead>
            <tbody>
                {menus.map(menu => 
					<tr key={menu.id}>
						<td>{menu.code}</td>
						<td>{menu.name}</td>
						<td>{menuCategoryEntities[menu.menu_category_id]?.name}</td>
						<td>{menu.description}</td>
						<td className="text-right">
							<NavLink to={`/data/menus/${menu.id}`} className="btn btn-primary btn-icon">
                                <span className="las la-pen" />
                            </NavLink>
                        </td>
						<td className="text-right">
							<button className="btn btn-danger btn-icon" onClick={() => handleDelete(menu)}>
                                <span className="las la-trash-alt" />
                            </button>
						</td>
					</tr>
				)}
            </tbody>
        </table>
    );
}
 
export default DataTable;