import { NavLink } from "react-router-dom";

const DataTable = ({ customers, customerCategoryEntities }) => {

    return (
        <table className="table table-striped">
            <thead>
                <tr>
                    <th className="text-left" width="20%">Code</th>
                    <th className="text-left" width="20%">Category</th>
                    <th className="text-left">Name</th>
                    <th className="text-left" width="1%"/>
                </tr>
            </thead>
            <tbody>
                {customers.map(customer => 
					<tr key={customer.id}>
						<td>{customer.code}</td>
						<td>{customerCategoryEntities[customer.customer_category_id]?.name}</td>
						<td>{customer.first_name}</td>
						<td className="text-right">
							<NavLink to={`/data/customers/${customer.id}`} className="btn btn-primary btn-icon">
                                <span className="las la-pen" />
                            </NavLink>
                        </td>
					</tr>
				)}
            </tbody>
        </table>
    );
}
 
export default DataTable;