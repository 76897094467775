import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { unwrapResult } from '@reduxjs/toolkit'
import { fetchWaiter, saveWaiter } from "../../../store/thunks/waitersThunk";
import { getWaiter, unloadWaiters } from "../../../store/slices/datasources/waitersSliceDatasource";
import WithLoadingPage from "../commons/withLoadingPage";
import Flash from "../elements/flash";
import PageSubHeader from "../elements/pageSubHeader";

const WaiterForm = props => {
    
	const dispatch = useDispatch();
    const history = useHistory();
	const routeParams = useParams();
    const { id: waiterId } = routeParams;
	const waiter = useSelector(getWaiter(waiterId));
	const { setLoading } = props;
	const [request, setRequest] = React.useState({ id: null, nik: "", first_name: "", last_name: "", address_line_1: "", address_line_2: "", mobile: "", email: "" });
	const [error, setError] = React.useState({ nik: null, first_name: null, address_line_1: null, mobile: null, email: null });

    React.useEffect(() => {
        return () => {
            dispatch(unloadWaiters());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {		
        async function initialize() {
            setLoading(true);
            if (parseInt(waiterId)) {
                await dispatch(fetchWaiter(getQueryFilter()));
            }
            setLoading(false);
        }
		
        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, waiterId]); 
	
	React.useEffect(() => {
		if (waiter) {
			const mapViewToModel = {
				id: waiter.id,
				nik: waiter.nik,
				first_name: waiter.first_name,
				last_name: waiter.last_name,
				address_line_1: waiter.address_line_1,
				address_line_2: waiter.address_line_2,
				mobile: waiter.mobile,
				email: waiter.email
			}

			setRequest(prev => { 
                return { 
                    ...prev, 
                    ...mapViewToModel 
                }
            });
		}
	}, [waiter]);

    const getQueryFilter = () => {
        const queryFilter = {
            id: waiterId,
        };

        return {
            query: queryFilter
        };
    }

	const handleInputChange = element => {
		const { name, value } = element.target;
		setRequest({...request, [name]: value});
	}

	const handleFormSubmit = async event => {
		event.preventDefault();
		setLoading(true);
		await dispatch(saveWaiter(request))
			.then(unwrapResult)
			.then(() => {
				setLoading(false);
				setError({ ...error, nik: null, first_name: null, last_name: null });
				history.push('/data/waiters');
			})
			.catch(exception => {
				setLoading(false);
				setError(exception.errors);
			});
	}

    return (
        <React.Fragment>
            <PageSubHeader title={`${waiterId === "add" ? "New" : "Edit"} Waiter`} />
            <div className="mx-auto w-2/4">
                <Flash />
                <div className="classic card">
                    <div className="card-header bg-light-600">
                        <h3 className="card-title">Waiter Form</h3>
                    </div>
                    <div className="card-body text-left">
                        <form className="w-full" onSubmit={handleFormSubmit}>
                            <div className="form-group">
                                <label className="form-label" htmlFor="nik">NIK</label>
                                <input id="nik" name="nik" type="text" className="form-control" value={request.nik} onChange={handleInputChange} />
                            </div>
                            <div className="form-group">
                                <div className="flex">
                                    <div className="w-1/2 mr-20">
                                        <label className="form-label" htmlFor="first_name">First Name</label>
                                        <input id="first_name" name="first_name" type="text" className="form-control" value={request.first_name} onChange={handleInputChange} />
                                    </div>
                                    <div className="w-1/2 ml-2">
                                        <label className="form-label" htmlFor="last_name">Last Name</label>
                                        <input id="last_name" name="last_name" type="text" className="form-control" value={request.last_name} onChange={handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="form-label" htmlFor="address_line_1">Address</label>
                                <input id="address_line_1" name="address_line_1" type="text" className="form-control mb-5" value={request.address_line_1} onChange={handleInputChange} />
                                <input id="address_line_2" name="address_line_2" type="text" className="form-control" value={request.address_line_2} onChange={handleInputChange} />
                            </div>
                            <div className="form-group">
                                <div className="flex">
                                    <div className="w-1/2 mr-20">
                                        <label className="form-label" htmlFor="mobile">Mobile</label>
                                        <input id="mobile" name="mobile" type="text" className="form-control" value={request.mobile} onChange={handleInputChange} />
                                    </div>
                                    <div className="w-1/2 ml-2">
                                        <label className="form-label" htmlFor="email">Email</label>
                                        <input id="email" name="email" type="text" className="form-control" value={request.email} onChange={handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group text-right">
                                <NavLink to="/data/waiters" className="btn btn-secondary mr-4">
                                    <i className="las la-undo" />
                                    Back
                                </NavLink>
                                <button className="btn btn-primary mr-4">
                                    <i className="las la-save" />
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
 
export default WithLoadingPage(WaiterForm);