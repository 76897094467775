import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { unwrapResult } from '@reduxjs/toolkit'
import { fetchStoreBranches } from "../../../store/thunks/storeBranchesThunk";
import { fetchDiningTableSection, saveDiningTableSection } from "../../../store/thunks/diningTableSectionsThunk";
import { getStoreBranches, unloadStoreBranches } from "../../../store/slices/datasources/storeBranchesSliceDatasource";
import { getDiningTableSection, unloadDiningTableSections } from "../../../store/slices/datasources/diningTableSectionsSliceDatasource";
import WithLoadingPage from "../commons/withLoadingPage";
import Flash from "../elements/flash";
import PageSubHeader from "../elements/pageSubHeader";

const DiningTableSectionForm = props => {
    
	const dispatch = useDispatch();
    const history = useHistory();
	const routeParams = useParams();
    const { id: diningTableSectionId } = routeParams;
	const storeBranches = useSelector(getStoreBranches);
	const diningTableSection = useSelector(getDiningTableSection(diningTableSectionId));
	const { setLoading } = props;
	const [request, setRequest] = React.useState({ id: null, store_branch_id: 0, name: "", description: "" });
	const [error, setError] = React.useState({ store_branch_id: null, name: null });

    React.useEffect(() => {
        return () => {
            dispatch(unloadStoreBranches());
            dispatch(unloadDiningTableSections());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        async function initialize() {
            setLoading(true);
            await dispatch(fetchStoreBranches());
            if (parseInt(diningTableSectionId)) {
                await dispatch(fetchDiningTableSection(getQueryFilter()));
            }
            setLoading(false);
        }
		
        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, diningTableSectionId]); 
	
	React.useEffect(() => {
		if (diningTableSection) {
			const mapViewToModel = {
				id: diningTableSection.id,
				store_branch_id: diningTableSection.store_branch_id,
				name: diningTableSection.name,
				description: diningTableSection.description
			}

			setRequest(prev => { 
                return { 
                    ...prev, 
                    ...mapViewToModel 
                }
            });
		}
	}, [diningTableSection]);

    const getQueryFilter = () => {
        const queryFilter = {
            id: diningTableSectionId
        };

        return {
            query: queryFilter
        };
    }
    
	const handleInputChange = element => {
		const { name, value } = element.target;
		setRequest({...request, [name]: value});
	}

	const handleFormSubmit = async event => {
		event.preventDefault();
		setLoading(true);
		await dispatch(saveDiningTableSection(request))
			.then(unwrapResult)
			.then(() => {
				setLoading(false);
				setError({ ...error, store_branch_id: null, name: null });
				history.push('/data/dining-table-sections');
			})
			.catch(exception => {
				setLoading(false);
				setError(exception.errors);
			});
	}

    return (
        <React.Fragment>
            <PageSubHeader title={`${diningTableSectionId === "add" ? "New" : "Edit"} Dining Table Section`} />
            <div className="mx-auto w-2/4">
                <Flash />
                <div className="classic card">
                    <div className="card-header bg-light-600">
                        <h3 className="card-title">Dining Table Section Form</h3>
                    </div>
                    <div className="card-body text-left">
                        <form className="w-full" onSubmit={handleFormSubmit}>
                            <div className="form-group">
                                <label className="form-label" htmlFor="store_branch_id">Branch</label>
                                <select id="store_branch_id" name="store_branch_id" className="form-control" value={request.store_branch_id} onChange={handleInputChange}>
                                    <option value="">- Select -</option>
                                    {storeBranches.map(storeBranch => <option key={storeBranch.id} value={storeBranch.id}>{storeBranch.name}</option>)}
                                </select>
                            </div>
                            <div className="form-group">
                                <label className="form-label" htmlFor="name">Name</label>
                                <input id="name" name="name" type="text" className="form-control" value={request.name} onChange={handleInputChange} />
                            </div>
                            <div className="form-group">
                                <label className="form-label" htmlFor="description">Description</label>
                                <textarea id="description" name="description" type="text" className="form-control" value={request.description} onChange={handleInputChange} rows="5" resize="none" />
                            </div>
                            <div className="form-group text-right">
                                <NavLink to="/data/dining-table-sections" className="btn btn-secondary mr-4">
                                    <i className="las la-undo" />
                                    Back
                                </NavLink>
                                <button className="btn btn-primary mr-4">
                                    <i className="las la-save" />
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
 
export default WithLoadingPage(DiningTableSectionForm);