import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { unwrapResult } from '@reduxjs/toolkit'
import { fetchCustomerCategories } from "../../../store/thunks/customerCategoriesThunk";
import { fetchCustomer, saveCustomer } from "../../../store/thunks/customersThunk";
import { getCustomerCategories, unloadCustomerCategories } from "../../../store/slices/datasources/customerCategoriesSliceDatasource";
import { getCustomer, unloadCustomers } from "../../../store/slices/datasources/customersSliceDatasource";
import WithLoadingPage from "../commons/withLoadingPage";
import Flash from "../elements/flash";
import PageSubHeader from "../elements/pageSubHeader";

const CustomerForm = props => {
    
	const dispatch = useDispatch();
    const history = useHistory();
	const routeParams = useParams();
    const { id: customerId } = routeParams;
    const customerCategories = useSelector(getCustomerCategories);
	const customer = useSelector(getCustomer(customerId));
	const { setLoading } = props;
	const [request, setRequest] = React.useState({ id: null, customer_category_id: 0, code: "", first_name: "", last_name: "", address_line_1: "", address_line_2: "", mobile: "", email: "" });
	const [error, setError] = React.useState({ customer_category_id: null, code: null, first_name: null, address_line_1: null, mobile: null, email: null  });

    React.useEffect(() => {
        return () => {
            dispatch(unloadCustomerCategories());
            dispatch(unloadCustomers());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        async function initialize() {
            setLoading(true);
            await dispatch(fetchCustomerCategories());
            if (parseInt(customerId)) {
                await dispatch(fetchCustomer(getQueryFilter()));
            }
            setLoading(false)

        }

        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, customerId]); 
	
	React.useEffect(() => {
		if (customer) {
			const mapViewToModel = {
				id: customer.id,
				customer_category_id: customer.customer_category_id,
				code: customer.code,
				first_name: customer.first_name,
				last_name: customer.last_name,
				address_line_1: customer.address_line_1,
				address_line_2: customer.address_line_2,
				mobile: customer.mobile,
				email: customer.email
			}

			setRequest(prev => { 
                return { 
                    ...prev, 
                    ...mapViewToModel 
                }
            });
		}
	}, [customer]);

    const getQueryFilter = () => {
        const queryFilter = {
            id: customerId
        };

        return {
            query: queryFilter
        };
    }

	const handleInputChange = element => {
		const { name, value } = element.target;
		setRequest({...request, [name]: value});
	}

	const handleFormSubmit = async event => {
		event.preventDefault();
		setLoading(true);
		await dispatch(saveCustomer(request))
			.then(unwrapResult)
			.then(() => {
				setLoading(false);
				setError({ ...error, customer_category_id: null, code: null, first_name: null, address_line_1: null, mobile: null, email: null  });
				history.push('/data/customers');
			})
			.catch(exception => {
				setLoading(false);
				setError(exception.errors);
			});
	}

    return (
        <React.Fragment>
            <PageSubHeader title={`${customerId === "add" ? "New" : "Edit"} Customer`} />
            <div className="mx-auto w-2/4">
                <Flash />
                <div className="classic card">
                    <div className="card-header bg-light-600">
                        <h3 className="card-title">Customer Form</h3>
                    </div>
                    <div className="card-body text-left">
                        <form className="w-full" onSubmit={handleFormSubmit}>
                            <div className="form-group">
                                <label className="form-label" htmlFor="customer_category_id">Category</label>
                                <select id="customer_category_id" name="customer_category_id" className="form-control" value={request.customer_category_id} onChange={handleInputChange}>
                                    <option value="">- Select -</option>
                                    {customerCategories.map(customerCategory => <option key={customerCategory.id} value={customerCategory.id}>{customerCategory.name}</option>)}
                                </select>
                            </div>
                            <div className="form-group">
                                <label className="form-label" htmlFor="code">Code</label>
                                <input id="code" name="code" type="text" className="form-control" value={request.code} onChange={handleInputChange} />
                            </div>
                            <div className="form-group">
                                <div className="flex">
                                    <div className="w-1/2 mr-20">
                                        <label className="form-label" htmlFor="first_name">First Name</label>
                                        <input id="first_name" name="first_name" type="text" className="form-control" value={request.first_name} onChange={handleInputChange} />
                                    </div>
                                    <div className="w-1/2 ml-2">
                                        <label className="form-label" htmlFor="last_name">Last Name</label>
                                        <input id="last_name" name="last_name" type="text" className="form-control" value={request.last_name} onChange={handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="form-label" htmlFor="address_line_1">Address</label>
                                <input id="address_line_1" name="address_line_1" type="text" className="form-control mb-5" value={request.address_line_1} onChange={handleInputChange} />
                                <input id="address_line_2" name="address_line_2" type="text" className="form-control" value={request.address_line_2} onChange={handleInputChange} />
                            </div>
                            <div className="form-group">
                                <div className="flex">
                                    <div className="w-1/2 mr-20">
                                        <label className="form-label" htmlFor="mobile">Mobile</label>
                                        <input id="mobile" name="mobile" type="text" className="form-control" value={request.mobile} onChange={handleInputChange} />
                                    </div>
                                    <div className="w-1/2 ml-2">
                                        <label className="form-label" htmlFor="email">Email</label>
                                        <input id="email" name="email" type="text" className="form-control" value={request.email} onChange={handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group text-right">
                                <NavLink to="/data/customers" className="btn btn-secondary mr-4">
                                    <i className="las la-undo" />
                                    Back
                                </NavLink>
                                <button className="btn btn-primary mr-4">
                                    <i className="las la-save" />
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
 
export default WithLoadingPage(CustomerForm);