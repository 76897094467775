import React from "react";

const FormMenuPrices = ({ menusStoreBranches, handleDetailsInputChange }) => {

    return ( 
        <React.Fragment>
            <div className="row">
                <table className="table">
                    <thead>
                        <tr>
                            <th className="text-left" width="5%"></th>
                            <th className="text-left">Store</th>
                            <th className="text-left" width="20%">Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {menusStoreBranches.map((menusStoreBranch, row) => 
                            <tr key={row}>
                                <td>
                                    <label className="custom-checkbox">
                                        <input name="available" type="checkbox" data-id={row} data-section="menus_store_branches" checked={menusStoreBranch.available} onChange={handleDetailsInputChange} />
                                        <span></span>
                                    </label>
                                </td>
                                <td>{menusStoreBranch.store_branch_name}</td>
                                <td>
                                    <input id="price" name="price" type="text" className="form-control" value={menusStoreBranch.price} data-id={row} data-section="menus_store_branches" onChange={handleDetailsInputChange} />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
}
 
export default FormMenuPrices;