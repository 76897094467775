import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { fetchTaxes, fetchTax, saveTax, deleteTax } from "../../thunks/taxesThunk";

const dataAdapter = createEntityAdapter();
const dataView = dataAdapter.getSelectors(state => state.datasources.taxes);
const taxesSliceDatasource = createSlice({
	name: "datasources/taxes",
	initialState: dataAdapter.getInitialState({
		pagination: {
			hasPrev: false,
			hasNext: false,
			currentPage: 0,
			currentRecord: 0, 
			perPage: 0, 
			totalPage: 0, 
			totalRecords: 0, 
		}
	}),
	reducers: {
		unloaded: (taxes, _) => {
			dataAdapter.removeAll(taxes, {});
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchTaxes.fulfilled, (taxes, action) => {
				if (action.payload.hasOwnProperty("pagination")) {
					taxes.pagination = action.payload.pagination;
				}
				
				if (action.payload.hasOwnProperty("taxes")) {
					dataAdapter.setAll(taxes, action.payload.taxes);
				}
			})
			.addCase(fetchTax.fulfilled, (tax, action) => {
				if (action.payload.hasOwnProperty("tax")) {
					dataAdapter.upsertMany(tax, action.payload.tax);
				}
			})
			.addCase(saveTax.fulfilled, (tax, action) => {
				dataAdapter.upsertOne(tax, action.payload.tax);
			})
			.addCase(deleteTax.fulfilled, (tax, action) => {
				dataAdapter.removeOne(tax, action.payload.tax.id);
			});
	}
});
export default taxesSliceDatasource.reducer;

export const unloadTaxes = () => taxesSliceDatasource.actions.unloaded();
export const getTaxes = dataView.selectAll;
export const getTaxeEntities = dataView.selectEntities;
export const getTaxePagination = createSelector(
	state => state.datasources.taxes.pagination,
	pagination => pagination
);
export const getTax = id => createSelector(
	state => state.datasources.taxes.entities,
	taxes => taxes[id]
);