import { denormalize, normalize, schema } from "normalizr";

export const normalizeEntity = (datasources) => {
	const isArray = datasources instanceof Array;
	const dataset = isArray ? "stores" : "store";
	const storeDocumentSettingsEntity = new schema.Entity("storeDocumentSettings");
	const storeBranchesEntity = new schema.Entity("storeBranches");
	const storeEntity = new schema.Entity(
		dataset,
		{
			store_document_settings: [storeDocumentSettingsEntity],
			store_branches: [storeBranchesEntity],
		}
	);
	const result = normalize(
		datasources, 
		isArray ? [storeEntity] : storeEntity
	);
	
	return result.entities;
}

export const denormalizeEntity = (datasources) => {
	const isArray = datasources.stores;
	const dataset = isArray ? "stores" : "store";
	const storeEntity = new schema.Entity(dataset)
	const entities = isArray ? datasources.stores : datasources.store;

	return denormalize(
		{ [dataset]: isArray ? Object.keys(entities) : Object.keys(entities).toString() }, 
		{ [dataset]: isArray ? [storeEntity] : storeEntity }, 
		{ [dataset]: entities }
	);
}