import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { fetchIngredients, deleteIngredient } from "../../../store/thunks/ingredientsThunk";
import { getUnitOfMeasureEntities, unloadUnitOfMeasures } from "../../../store/slices/datasources/unitOfMeasuresSliceDatasource";
import { getIngredients, getIngredientPagination, unloadIngredients } from "../../../store/slices/datasources/ingredientsSliceDatasource";
import WithLoadingPage from "../commons/withLoadingPage";
import DeleteConfirmDialog from "../elements/deleteConfirmDialog";
import PageSubHeader from "../elements/pageSubHeader";
import Paginator from "../elements/paginator";
import DataTable from "./components/dataTable";

const Ingredients = props => {
    
    const dispatch = useDispatch();
    const unitOfMeasureEntities = useSelector(getUnitOfMeasureEntities);
    const ingredients = useSelector(getIngredients);
    const pagination = useSelector(getIngredientPagination);
    const { setLoading } = props;
    const [confirmDialog, setConfirmDialog] = React.useState({ showDialog: false, title: "", message: "", selected: null })
    const [search, setSearch] = React.useState("");
    const [currentPage, setCurrentPage] = React.useState(1);

    React.useEffect(() => {
        return () => {
            dispatch(unloadUnitOfMeasures());
            dispatch(unloadIngredients());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        async function initialize() {
            setLoading(true);
            await dispatch(fetchIngredients(getQueryFilter()))
            setLoading(false);
        }

        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, currentPage]);

    const getQueryFilter = () => {
        const queryFilter = {
            withUnitOfMeasure: true,
            search: search,
            page: currentPage,
            paginate: 25
        };

        return {
            query: queryFilter
        };
    }

    const handleSearchInputChange = element => {
		const { value } = element.target;
		setSearch(value);
	}

    const handleSearch = async () => {
        setLoading(true);
        await dispatch(fetchIngredients(getQueryFilter()));
        setLoading(false);
    }

    const handleDelete = ingredient => {
        setConfirmDialog({
            ...confirmDialog,
            showDialog: true, 
			title: "Delete Confirmation", 
			message: `Are you sure, you want to delete ingredient '${ingredient.name}' ?`,
			selected: ingredient
        });
    }

    const handleDeleteConfirm = async () => {
        setConfirmDialog({ ...confirmDialog, showDialog: false });
        setLoading(true);
        await dispatch(deleteIngredient({ query: {id: confirmDialog.selected.id} }))
            .then(() => setLoading(false));
    }

    const handleConfirmDialogDismiss = () => {
        setConfirmDialog({ ...confirmDialog, showDialog: false });
    }

    const handlePageChange = page => {
        if (currentPage !== page) {
			setCurrentPage(page);
		}
    }

    return (
        <React.Fragment>
            <PageSubHeader title="Ingredients" />
            <DeleteConfirmDialog
                showDialog={confirmDialog.showDialog} 
                title={confirmDialog.title} 
                message={confirmDialog.message} 
                handleConfirm={handleDeleteConfirm} 
                handleDismiss={handleConfirmDialogDismiss} 
            />
            <div className="mx-auto w-3/4">
                <div className="classic card">
                    <div className="card-header bg-light-600">
                        <div className="flex align-middle">
                            <div className="flex-grow mr-20">
                                <div className="input-group">
                                    <input id="search" name="search" type="text" className="form-control input-group-item" placeholder="Search..." value={search} onChange={handleSearchInputChange} />
                                    <button className="btn btn-primary uppercase input-group-item" onClick={handleSearch}>
                                        <i className="las la-search" />
                                    </button>
                                </div>
                            </div>
                            <div className="flex">
                                <NavLink to="/data/ingredients/add" className="btn btn-primary">
                                    <i className="las la-plus" />
                                    Add New
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="card-body text-left">
                        <DataTable ingredients={ingredients} unitOfMeasureEntities={unitOfMeasureEntities} handleDelete={handleDelete} />
                        <Paginator pagination={pagination} handlePageChange={handlePageChange} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
 
export default WithLoadingPage(Ingredients);