import { combineReducers } from 'redux';

import vendorAuthenticationReducer from "./authentications/vendorSliceAuthentication";
import storeAuthenticationReducer from "./authentications/storeSliceAuthentication";
import flashCommonReducer from "./commons/flashSliceCommon";
import customerCategoriesDatasourceReducer from "./datasources/customerCategoriesSliceDatasource";
import customersDatasourceReducer from "./datasources/customersSliceDatasource";
import diningTableSectionsDatasourceReducer from "./datasources/diningTableSectionsSliceDatasource";
import diningTablesDatasourceReducer from "./datasources/diningTablesSliceDatasource";
import ingredientsDatasourceReducer from "./datasources/ingredientsSliceDatasource";
import menuAdditionalsDatasourceReducer from "./datasources/menuAdditionalsSliceDatasource";
import menuCategoriesDatasourceReducer from "./datasources/menuCategoriesSliceDatasource";
import menuIngredientsDatasourceReducer from "./datasources/menuIngredientsSliceDatasource";
import menuOptionsDatasourceReducer from "./datasources/menuOptionsSliceDatasource";
import menusDatasourceReducer from "./datasources/menusSliceDatasource";
import menuStoreBranchesDatasourceReducer from "./datasources/menuStoreBranchesSliceDatasource";
import paymentCategoriesDatasourceReducer from "./datasources/paymentCategoriesSliceDatasource";
import paymentsDatasourceReducer from "./datasources/paymentsSliceDatasource";
import salesInvoicePaymentsDatasourceReducer from "./datasources/salesInvoicePaymentsSliceDatasource";
import salesInvoiceDetailsDatasourceReducer from "./datasources/salesInvoiceDetailsSliceDatasource";
import salesInvoicesDatasourceReducer from "./datasources/salesInvoicesSliceDatasource";
import salesOrderBatchesDatasourceReducer from "./datasources/salesOrderBatchesSliceDatasource";
import salesOrderDetailsDatasourceReducer from "./datasources/salesOrderDetailsSliceDatasource";
import salesOrdersDatasourceReducer from "./datasources/salesOrdersSliceDatasource";
import stationsDatasourceReducer from "./datasources/stationsSliceDatasource";
import storeBranchesDatasourceReducer from "./datasources/storeBranchesSliceDatasource";
import storeDocumentSettingsDatasourceReducer from "./datasources/storeDocumentSettingsSliceDatasource";
import storesDatasourceReducer from "./datasources/storesSliceDatasource";
import taxesDatasourceReducer from "./datasources/taxesSliceDatasource";
import unitOfMeasuresDatasourceReducer from "./datasources/unitOfMeasuresSliceDatasource";
import voidReasonsDatasourceReducer from "./datasources/voidReasonsSliceDatasource";
import waitersDatasourceReducer from "./datasources/waitersSliceDatasource";

export default combineReducers({
	auth: combineReducers({
		store: storeAuthenticationReducer,
		vendor: vendorAuthenticationReducer,
	}),
	common: combineReducers({
		flashMessage: flashCommonReducer
	}),
	datasources: combineReducers({
		customerCategories: customerCategoriesDatasourceReducer,
		customers: customersDatasourceReducer,
		diningTableSections: diningTableSectionsDatasourceReducer,
		diningTables: diningTablesDatasourceReducer,
		ingredients: ingredientsDatasourceReducer,
		menuAdditionals: menuAdditionalsDatasourceReducer,
		menuCategories: menuCategoriesDatasourceReducer,
		menuIngredients: menuIngredientsDatasourceReducer,
		menuOptions: menuOptionsDatasourceReducer,
		menuStoreBranches: menuStoreBranchesDatasourceReducer,
		menus: menusDatasourceReducer,
		paymentCategories: paymentCategoriesDatasourceReducer,
		payments: paymentsDatasourceReducer,
		salesInvoicePayments: salesInvoicePaymentsDatasourceReducer,
		salesInvoiceDetails: salesInvoiceDetailsDatasourceReducer,
		salesInvoices: salesInvoicesDatasourceReducer,
		salesOrderBatches: salesOrderBatchesDatasourceReducer,
		salesOrderDetails: salesOrderDetailsDatasourceReducer,
		salesOrders: salesOrdersDatasourceReducer,
		stations: stationsDatasourceReducer,
		storeBranches: storeBranchesDatasourceReducer,
		storeDocumentSettings: storeDocumentSettingsDatasourceReducer,
		stores: storesDatasourceReducer,
		taxes: taxesDatasourceReducer,
		unitOfMeasures: unitOfMeasuresDatasourceReducer,
		voidReasons: voidReasonsDatasourceReducer,
		waiters: waitersDatasourceReducer,
	})
});