import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { unwrapResult } from '@reduxjs/toolkit'
import { fetchDiningTableSections } from "../../../store/thunks/diningTableSectionsThunk";
import { fetchDiningTable, saveDiningTable } from "../../../store/thunks/diningTablesThunk";
import { getDiningTableSections, unloadDiningTableSections } from "../../../store/slices/datasources/diningTableSectionsSliceDatasource";
import { getDiningTable, unloadDiningTables } from "../../../store/slices/datasources/diningTablesSliceDatasource";
import WithLoadingPage from "../commons/withLoadingPage";
import Flash from "../elements/flash";
import PageSubHeader from "../elements/pageSubHeader";

const DiningTableForm = props => {
    
	const dispatch = useDispatch();
    const history = useHistory();
	const routeParams = useParams();
    const { id: diningTableId } = routeParams;
	const diningTableSections = useSelector(getDiningTableSections);
	const diningTable = useSelector(getDiningTable(diningTableId));
	const { setLoading } = props;
	const [request, setRequest] = React.useState({ id: null, dining_table_section_id: 0, number: "", pax: "", description: "" });
	const [error, setError] = React.useState({ dining_table_section_id: null, number: null, pax: null });
    
    React.useEffect(() => {
        return () => {
            dispatch(unloadDiningTableSections());
            dispatch(unloadDiningTables());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {	
        async function initialize() {
            setLoading(true);
            await dispatch(fetchDiningTableSections());
            if (parseInt(diningTableId)) {
                await dispatch(fetchDiningTable(getQueryFilter()));
            }
            setLoading(false);
        }
		
        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, diningTableId]); 
	
	React.useEffect(() => {
		if (diningTable) {
			const mapViewToModel = {
				id: diningTable.id,
				dining_table_section_id: diningTable.dining_table_section_id,
				number: diningTable.number,
				pax: diningTable.pax,
				description: diningTable.description
			}

			setRequest(prev => { 
                return { 
                    ...prev, 
                    ...mapViewToModel 
                }
            });
		}
	}, [diningTable]);

    const getQueryFilter = () => {
        const queryFilter = {
            id: diningTableId
        };

        return {
            query: queryFilter
        };
    }
    
	const handleInputChange = element => {
		const { name, value } = element.target;
		setRequest({...request, [name]: value});
	}

	const handleFormSubmit = async event => {
		event.preventDefault();
		setLoading(true);
		await dispatch(saveDiningTable(request))
			.then(unwrapResult)
			.then(() => {
				setLoading(false);
				setError({ ...error, dining_table_section_id: null, number: null, pax: null });
				history.push('/data/dining-tables');
			})
			.catch(exception => {
				setLoading(false);
				setError(exception.errors);
			});
	}

    return (
        <React.Fragment>
            <PageSubHeader title={`${diningTableId === "add" ? "New" : "Edit"} Dining Table`} />
            <div className="mx-auto w-2/4">
                <Flash />
                <div className="classic card">
                    <div className="card-header bg-light-600">
                        <h3 className="card-title">Dining Table Form</h3>
                    </div>
                    <div className="card-body text-left">
                        <form className="w-full" onSubmit={handleFormSubmit}>
                            <div className="form-group">
                                <label className="form-label" htmlFor="dining_table_section_id">Table Section</label>
                                <select id="dining_table_section_id" name="dining_table_section_id" className="form-control" value={request.dining_table_section_id} onChange={handleInputChange}>
                                    <option value="">- Select -</option>
                                    {diningTableSections.map(diningTableSection => <option key={diningTableSection.id} value={diningTableSection.id}>{diningTableSection.name}</option>)}
                                </select>
                            </div>
                            <div className="form-group">
                                <div className="flex">
                                    <div className="w-1/2 mr-20">
                                        <label className="form-label" htmlFor="number">Number</label>
                                        <input id="number" name="number" type="text" className="form-control" value={request.number} onChange={handleInputChange} />
                                    </div>
                                    <div className="w-1/2 ml-2">
                                        <label className="form-label" htmlFor="pax">Pax</label>
                                        <input id="pax" name="pax" type="text" className="form-control" value={request.pax} onChange={handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="form-label" htmlFor="description">Description</label>
                                <textarea id="description" name="description" type="text" className="form-control" value={request.description} onChange={handleInputChange} rows="5" resize="none" />
                            </div>
                            <div className="form-group text-right">
                                <NavLink to="/data/dining-tables" className="btn btn-secondary mr-4">
                                    <i className="las la-undo" />
                                    Back
                                </NavLink>
                                <button className="btn btn-primary mr-4">
                                    <i className="las la-save" />
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
 
export default WithLoadingPage(DiningTableForm);