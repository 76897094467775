import { denormalize, normalize, schema } from "normalizr";

export const normalizeEntity = (datasources) => {
	const isArray = datasources instanceof Array;
	const dataset = isArray ? "customers" : "customer";
	const customerCategoryEntity = new schema.Entity("customerCategory");
	const customerEntity = new schema.Entity(
		dataset, 
		{ customer_category: customerCategoryEntity }
	);
	const result = normalize(
		datasources, 
		isArray ? [customerEntity] : customerEntity
	);

	return result.entities;
}

export const denormalizeEntity = (datasources) => {
	const isArray = datasources.customers;
	const dataset = isArray ? "customers" : "customer";
	const customerEntity = new schema.Entity(dataset)
	const entities = isArray ? datasources.customers : datasources.customer;

	return denormalize(
		{ [dataset]: isArray ? Object.keys(entities) : Object.keys(entities).toString() }, 
		{ [dataset]: isArray ? [customerEntity] : customerEntity }, 
		{ [dataset]: entities }
	);
}