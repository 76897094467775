import { denormalize, normalize, schema } from "normalizr";

export const normalizeEntity = (datasources) => {
	const isArray = datasources instanceof Array;
	const dataset = isArray ? "ingredients" : "ingredient";
	const unitOfMeasureEntity = new schema.Entity("unitOfMeasure");
	const ingredientEntity = new schema.Entity(
		dataset,
		{ unit_of_measure: unitOfMeasureEntity }
	);
	const result = normalize(
		datasources, 
		isArray ? [ingredientEntity] : ingredientEntity
	);
	
	return result.entities;
}

export const denormalizeEntity = (datasources) => {
	const isArray = datasources.ingredients;
	const dataset = isArray ? "ingredients" : "ingredient";
	const ingredientEntity = new schema.Entity(dataset)
	const entities = isArray ? datasources.ingredients : datasources.ingredient;

	return denormalize(
		{ [dataset]: isArray ? Object.keys(entities) : Object.keys(entities).toString() }, 
		{ [dataset]: isArray ? [ingredientEntity] : ingredientEntity }, 
		{ [dataset]: entities }
	);
}