import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { unwrapResult } from '@reduxjs/toolkit'
import { fetchStoreBranches } from "../../../store/thunks/storeBranchesThunk";
import { fetchMenuCategories } from "../../../store/thunks/menuCategoriesThunk";
import { fetchUnitOfMeasures } from "../../../store/thunks/unitOfMeasuresThunk";
import { fetchIngredients } from "../../../store/thunks/ingredientsThunk";
import { fetchMenu, saveMenu } from "../../../store/thunks/menusThunk";
import { getStoreBranches, unloadStoreBranches } from "../../../store/slices/datasources/storeBranchesSliceDatasource";
import { getMenuCategories, unloadMenuCategories } from "../../../store/slices/datasources/menuCategoriesSliceDatasource";
import { getUnitOfMeasureEntities, unloadUnitOfMeasures } from "../../../store/slices/datasources/unitOfMeasuresSliceDatasource";
import { getIngredients, unloadIngredients } from "../../../store/slices/datasources/ingredientsSliceDatasource";
import { getMenu, getMenuIngredients, getMenuOptions, getMenuStoreBranhes, unloadMenus } from "../../../store/slices/datasources/menusSliceDatasource";
import { unloadMenuIngredients } from "../../../store/slices/datasources/menuIngredientsSliceDatasource";
import { unloadMenuOptions } from "../../../store/slices/datasources/menuOptionsSliceDatasource";
import { unloadMenuStoreBranches } from "../../../store/slices/datasources/menuStoreBranchesSliceDatasource";
import WithLoadingPage from "../commons/withLoadingPage";
import Flash from "../elements/flash";
import PageSubHeader from "../elements/pageSubHeader";
import FormMenuPrices from "./components/formPrices";
import FormMenuIngredients from "./components/formMenuIngredients";
import FormMenuOptions from "./components/formMenuOptions";

const MenuForm = props => {
    
    const dispatch = useDispatch();
    const history = useHistory();
	const routeParams = useParams();
    const { id: menuId } = routeParams;
	const storeBranches = useSelector(getStoreBranches);
	const menuCategories = useSelector(getMenuCategories);
	const unitOfMeasures = useSelector(getUnitOfMeasureEntities);
	const ingredients = useSelector(getIngredients);
	const menu = useSelector(getMenu(menuId));
	const menuIngredients = useSelector(getMenuIngredients(menuId));
	const menuOptions = useSelector(getMenuOptions(menuId));
	const menuStoreBranches = useSelector(getMenuStoreBranhes(menuId));
	const { setLoading } = props;
	const [request, setRequest] = React.useState({ 
		id: null, 
		menu_category_id: 0, 
		code: "", 
		name: "", 
		description: "", 
        menus_store_branches: [],
		menu_ingredients: [],
		menu_options: [] 
	});
	const [error, setError] = React.useState({ menu_category_id: null, code: null, name: null });

    React.useEffect(() => {
        return () => {
            dispatch(unloadStoreBranches());
            dispatch(unloadMenuCategories());
            dispatch(unloadUnitOfMeasures());
            dispatch(unloadIngredients());
            dispatch(unloadMenuStoreBranches());
            dispatch(unloadMenuIngredients());
            dispatch(unloadMenuOptions());
            dispatch(unloadMenus());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {		
        async function initialize() {
            setLoading(true);
            await dispatch(fetchStoreBranches());
            await dispatch(fetchMenuCategories());
            await dispatch(fetchUnitOfMeasures());
            await dispatch(fetchIngredients());
            if (parseInt(menuId)) {
                await dispatch(fetchMenu(getQueryFilter()));
            }
            setLoading(false);
        }
		
        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, menuId]); 
	
	React.useEffect(() => {
		if (storeBranches) {
            const mapViewToModel = {
                menus_store_branches: mapStoreBranchs(),
            }

			setRequest(prev => {
                return {
                    ...prev,
                    ...mapViewToModel
                }
            })
		}
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [storeBranches]);
	
	React.useEffect(() => {
		if (menu) {
			const mapViewToModel = {
				id: menu.id,
				menu_category_id: menu.menu_category_id,
				code: menu.code,
				name: menu.name,
				description: menu.description,
				menus_store_branches: mapMenuPrices(),
				menu_ingredients: mapMenuIngredients(),
                menu_options: mapMenuOptions()
			}

			setRequest(prev => { 
                return { 
                    ...prev, 
                    ...mapViewToModel 
                }
            });
		}
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [menu]);

    const getQueryFilter = () => {
        const queryFilter = {
            id: menuId,
            withMenuIngredients: true,
            withMenuOptions: true,
            withMenuStoreBranches: true,
        };

        return {
            query: queryFilter
        };
    }

    const mapStoreBranchs = () => {
        const results = [];

        if (storeBranches) {
            storeBranches.forEach(storeBranch => 
                results.push({ 
                    id: 0, 
                    store_branch_id: storeBranch.id, 
                    available: 0, 
                    store_branch_name: storeBranch.name, 
                    price: 0 
                })
            );
        }
        
		return results;
    }

    const mapMenuPrices = () => {
		const results = [];

        if (storeBranches) {
            storeBranches.forEach(storeBranch => 
                results.push({ 
                    id: 0, 
                    store_branch_id: storeBranch.id, 
                    available: 0, 
                    store_branch_name: storeBranch.name, 
                    price: 0 
                })
            );
        }


		if (menuStoreBranches) {
            const mapMenuStoreBranchByStoreId = menuStoreBranches.reduce((newMenuStoreBranch, menuStoreBranch) => { 
                newMenuStoreBranch[menuStoreBranch.store_branch_id] = menuStoreBranch;
                return newMenuStoreBranch;
            }, {});
            
            results.forEach(result => {
                result.id = mapMenuStoreBranchByStoreId[result.store_branch_id] ? mapMenuStoreBranchByStoreId[result.store_branch_id].id : 0;
                result.available = mapMenuStoreBranchByStoreId[result.store_branch_id] ? mapMenuStoreBranchByStoreId[result.store_branch_id].available : false;
                result.price = mapMenuStoreBranchByStoreId[result.store_branch_id] ? mapMenuStoreBranchByStoreId[result.store_branch_id].price : 0;
            });
		}

		return results;
	}

    const mapMenuIngredients = () => {
		const result = [];

		if (menuIngredients) {
			menuIngredients.forEach(menuIngredient => 
                result.push({ 
                    id: menuIngredient.id, 
                    ingredient_id: menuIngredient.ingredient_id, 
                    quantity: menuIngredient.quantity 
                })
            );
		}

		return result;
	}

    const mapMenuOptions = () => {
		const result = [];

		if (menuOptions) {
			menuOptions.forEach(menuOption => 
                result.push({ 
                    id: menuOption.id, 
                    option: menuOption.option 
                })
            );
		}
        
		return result;
	}

	const handleInputChange = element => {
		const { name, value } = element.target;
		setRequest({...request, [name]: value});
	}

    const handleDetailsInputChange = element => {
		const { dataset, name, type, value } = element.target;
		const details = request[dataset.section];

        if (type === "checkbox") {
            details[dataset.id][name] = element.target.checked ? 1 : 0;
        } else {
            details[dataset.id][name] = value;
        }
        
		setRequest({ 
            ...request, 
            [dataset.section]: [...details] 
        });
	}

    const handleNewRow = (event, section, newRow) => {
        event.preventDefault();
		const rows = request[section];
		rows.push(newRow);
		setRequest({ 
            ...request, 
            [section]: [...rows] 
        });
	}

    const handleDeleteRow = (row, section) => {
		const details = request[section];
		details.splice(row, 1);
		setRequest({ 
            ...request, 
            [section]: [...details] 
        });
	}

	const handleFormSubmit = async event => {
		event.preventDefault();
		setLoading(true);
		await dispatch(saveMenu(request))
			.then(unwrapResult)
			.then(() => {
				setLoading(false);
				setError({ ...error, menu_category_id: null, code: null, name: null });
				history.push('/data/menus');
			})
			.catch(exception => {
				setLoading(false);
				setError(exception.errors);
			});
	}

    return (
        <React.Fragment>
            <PageSubHeader title={`${menuId === "add" ? "New" : "Edit"} Menu`} />
            <div className="mx-auto w-2/4">
                <Flash />
                <form className="w-full" onSubmit={handleFormSubmit}>
                    <div className="classic card">
                        <div className="card-header bg-light-600">
                            <h3 className="card-title">Menu Form</h3>
                        </div>
                        <div className="card-body text-left">
                            <div className="form-group">
                                <label className="form-label" htmlFor="menu_category_id">Category</label>
                                <select id="menu_category_id" name="menu_category_id" className="form-control" value={request.menu_category_id} onChange={handleInputChange}>
                                    <option value="">- Select -</option>
                                    {menuCategories.map(menuCategory => <option key={menuCategory.id} value={menuCategory.id}>{menuCategory.name}</option>)}
                                </select>
                            </div>
                            <div className="form-group">
                                <div className="flex">
                                    <div className="w-1/2 mr-20">
                                        <label className="form-label" htmlFor="code">Code</label>
                                        <input id="code" name="code" type="text" className="form-control" value={request.code} onChange={handleInputChange} />
                                    </div>
                                    <div className="w-1/2 ml-2">
                                        <label className="form-label" htmlFor="name">Name</label>
                                        <input id="name" name="name" type="text" className="form-control" value={request.name} onChange={handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="form-label" htmlFor="description">Description</label>
                                <textarea id="description" name="description" type="text" className="form-control" value={request.description} onChange={handleInputChange} rows="5" resize="none" />
                            </div>
                        </div>
                    </div>

                    <div className="classic card">
                        <div className="card-header bg-light-600">
                            <h3 className="card-title">Price</h3>
                        </div>
                        <div className="card-body text-left">
                            <FormMenuPrices
                                menusStoreBranches={request.menus_store_branches}
                                handleDetailsInputChange={handleDetailsInputChange}
                            />
                        </div>
                    </div>

                    <div className="classic card">
                        <div className="card-header bg-light-600">
                            <h3 className="card-title">Ingredients</h3>
                        </div>
                        <div className="card-body text-left">
                            <FormMenuIngredients 
                                ingredients={ingredients}
                                unitOfMeasures={unitOfMeasures}
                                menuIngredients={request.menu_ingredients}
                                handleNewRow={handleNewRow}
                                handleDeleteRow={handleDeleteRow}
                                handleDetailsInputChange={handleDetailsInputChange}
                            />
                        </div>
                    </div>

                    <div className="classic card">
                        <div className="card-header bg-light-600">
                            <h3 className="card-title">Options</h3>
                        </div>
                        <div className="card-body text-left">
                            <FormMenuOptions 
                                menuOptions={request.menu_options}
                                handleNewRow={handleNewRow}
                                handleDeleteRow={handleDeleteRow}
                                handleDetailsInputChange={handleDetailsInputChange}
                            />
                        </div>
                    </div>

                    <div className="classic card">
                        <div className="card-body text-right">
                            <NavLink to="/data/menus" className="btn btn-secondary mr-4">
                                <i className="las la-undo" />
                                Back
                            </NavLink>
                            <button className="btn btn-primary mr-4">
                                <i className="las la-save" />
                                Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment>
    );
}
 
export default WithLoadingPage(MenuForm);