import { createSlice } from "@reduxjs/toolkit";
import { configure } from "../../../config/app";
import { authorize } from "../../thunks/vendorsThunk";

const defaultValue = { code: "", name: "", address_line_1: "", address_line_2: "", phone: "", fax: "" };
const initialState = localStorage.getItem(configure.StorageKey.Vendor) === null ? defaultValue : JSON.parse(localStorage.getItem(configure.StorageKey.Vendor));
const vendorSliceAuthentication = createSlice({
	name: "auth/vendor",
	initialState: initialState,
	reducers: {
		loggedout: (vendor, _) => {
			vendor.code = "";
			vendor.name = "";
			vendor.address_line_1 = "";
			vendor.address_line_2 = "";
			vendor.phone = "";
			vendor.fax = "";
		}
	},
	extraReducers: builder => {
		builder
			.addCase(authorize.fulfilled, (vendor, action) => {
				vendor.code = action.payload.vendor.code;
				vendor.name = action.payload.vendor.name;
				vendor.address_line_1 = action.payload.vendor.address_line_1;
				vendor.address_line_2 = action.payload.vendor.address_line_2;
				vendor.phone = action.payload.vendor.phone;
				vendor.fax = action.payload.vendor.fax;
			});
	}
});
export const { loggedout } = vendorSliceAuthentication.actions;
export default vendorSliceAuthentication.reducer;

export const logout = () => vendorSliceAuthentication.actions.loggedout();