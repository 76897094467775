import { NavLink } from "react-router-dom";

const DataTable = ({ menuCategories, stationEntities, handleDelete }) => {

    return (
        <table className="table table-striped">
            <thead>
                <tr>
                    <th className="text-left" width="20%">Code</th>
                    <th className="text-left" width="20%">Name</th>
                    <th className="text-left" width="20%">Station</th>
                    <th className="text-left">Description</th>
                    <th className="text-left" width="1%"/>
                    <th className="text-left" width="1%"/>
                </tr>
            </thead>
            <tbody>
                {menuCategories.map(menuCategory => 
					<tr key={menuCategory.id}>
						<td>{menuCategory.code}</td>
						<td>{menuCategory.name}</td>
						<td>{stationEntities[menuCategory.station_id]?.name}</td>
						<td>{menuCategory.description}</td>
						<td className="text-right">
							<NavLink to={`/data/menu-categories/${menuCategory.id}`} className="btn btn-primary btn-icon">
                                <span className="las la-pen" />
                            </NavLink>
                        </td>
						<td className="text-right">
							<button className="btn btn-danger btn-icon" onClick={() => handleDelete(menuCategory)}>
                                <span className="las la-trash-alt" />
                            </button>
						</td>
					</tr>
				)}
            </tbody>
        </table>
    );
}
 
export default DataTable;