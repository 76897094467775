import { configureStore } from "@reduxjs/toolkit";
import reducer from "./slices/reducer";
import authMiddleware from "./middleware/authMiddleware";
import localStorageMiddleware from "./middleware/localStorageMiddleware";

export default configureStore({
	reducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware()
		.concat(authMiddleware)
		.concat(localStorageMiddleware),
});