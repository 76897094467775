import { NavLink } from "react-router-dom";

const DataTable = ({ waiters, handleGeneratePin, handleDelete }) => {

    return (
        <table className="table table-striped">
            <thead>
                <tr>
                    <th className="text-left" width="15%">NIK</th>
                    <th className="text-left">First Name</th>
                    <th className="text-left">Last Name</th>
                    <th className="text-left" width="15%">Mobile</th>
                    <th className="text-left" width="15%">Email</th>
                    <th className="text-left" width="1%"/>
                    <th className="text-left" width="1%"/>
                    <th className="text-left" width="1%"/>
                </tr>
            </thead>
            <tbody>
                {waiters.map(waiter => 
					<tr key={waiter.id}>
						<td>{waiter.nik}</td>
						<td>{waiter.first_name}</td>
						<td>{waiter.last_name}</td>
						<td>{waiter.mobile}</td>
						<td>{waiter.email}</td>
						<td className="text-right">
							<NavLink to={`/data/waiters/${waiter.id}`} className="btn btn-primary btn-icon">
                                <span className="las la-pen" />
                            </NavLink>
                        </td>
						<td className="text-right">
							<button type="button" className="btn btn-outline-primary btn-icon" onClick={() => handleGeneratePin(waiter.id)}>
                                <span className="fas fa-fingerprint" />
                            </button>
						</td>
						<td className="text-right">
							<button type="button" className="btn btn-danger btn-icon" onClick={() => handleDelete(waiter)}>
                                <span className="las la-trash-alt" />
                            </button>
						</td>
					</tr>
				)}
            </tbody>
        </table>
    );
}
 
export default DataTable;