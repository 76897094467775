import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { fetchTaxes, deleteTax } from "../../../store/thunks/taxesThunk";
import { getTaxes, getTaxePagination, unloadTaxes } from "../../../store/slices/datasources/taxesSliceDatasource";
import WithLoadingPage from "../commons/withLoadingPage";
import DeleteConfirmDialog from "../elements/deleteConfirmDialog";
import PageSubHeader from "../elements/pageSubHeader";
import Paginator from "../elements/paginator";
import DataTable from "./components/dataTable";

const Taxes = props => {
    
    const dispatch = useDispatch();
    const taxes = useSelector(getTaxes);
    const pagination = useSelector(getTaxePagination);
    const { setLoading } = props;
    const [confirmDialog, setConfirmDialog] = React.useState({ showDialog: false, title: "", message: "", selected: null })
    const [currentPage, setCurrentPage] = React.useState(1);

    React.useEffect(() => {
        return () => {
            dispatch(unloadTaxes());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {		
        async function initialize() {
            setLoading(true);
            await dispatch(fetchTaxes(getQueryFilter()));
            setLoading(false);
        }
		
        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, currentPage]); 
	
    const getQueryFilter = () => {
        const queryFilter = {
            page: currentPage,
            paginate: 25
        };

        return {
            query: queryFilter
        };
    }

    const handleDelete = tax => {
        setConfirmDialog({
            ...confirmDialog,
            showDialog: true, 
			title: "Delete Confirmation", 
			message: `Are you sure, you want to delete tax periode '${tax.periode}' ?`,
			selected: tax
        });
    }

    const handleDeleteConfirm = async () => {
        setConfirmDialog({ ...confirmDialog, showDialog: false });
        setLoading(true);
        await dispatch(deleteTax({ query: {id: confirmDialog.selected.id} }))
            .then(() => setLoading(false));
    }

    const handleConfirmDialogDismiss = () => {
        setConfirmDialog({ ...confirmDialog, showDialog: false });
    }

    const handlePageChange = page => {
        if (currentPage !== page) {
			setCurrentPage(page);
		}
    }

    return (
        <React.Fragment>
            <PageSubHeader title="Taxes" />
            <DeleteConfirmDialog
                showDialog={confirmDialog.showDialog} 
                title={confirmDialog.title} 
                message={confirmDialog.message} 
                handleConfirm={handleDeleteConfirm} 
                handleDismiss={handleConfirmDialogDismiss} 
            />
			<div className="mx-auto w-3/4">
                <div className="classic card">
                    <div className="card-header bg-light-600">
                        <div className="flex align-middle">
                            <div className="flex-grow mr-20">
                                &nbsp;
                            </div>
                            <div className="flex">
                                <NavLink to="/data/taxes/add" className="btn btn-primary">
                                    <i className="las la-plus" />
                                    Add New
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="card-body text-left">
                        <DataTable taxes={taxes} handleDelete={handleDelete} />
                        <Paginator pagination={pagination} handlePageChange={handlePageChange} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
 
export default WithLoadingPage(Taxes);