import { denormalize, normalize, schema } from "normalizr";

export const normalizeEntity = (datasources) => {
	const isArray = datasources instanceof Array;
	const dataset = isArray ? "paymentCategories" : "paymentCategory";
	const stationEntity = new schema.Entity("station");
	const paymentCategoryEntity = new schema.Entity(
		dataset,
		{station: stationEntity}
	);
	const result = normalize(
		datasources, 
		isArray ? [paymentCategoryEntity] : paymentCategoryEntity
	);

	return result.entities;
}

export const denormalizeEntity = (datasources) => {
	const isArray = datasources.paymentCategories;
	const dataset = isArray ? "paymentCategories" : "paymentCategory";
	const paymentCategoryEntity = new schema.Entity(dataset)
	const entities = isArray ? datasources.paymentCategories : datasources.paymentCategory;

	return denormalize(
		{ [dataset]: isArray ? Object.keys(entities) : Object.keys(entities).toString() }, 
		{ [dataset]: isArray ? [paymentCategoryEntity] : paymentCategoryEntity }, 
		{ [dataset]: entities }
	);
}