import React from "react";
import LoadingAnimation from "../pages/loadingAnimation";

const WithLoadingPage = (Component) => {

    return function LoadingPage(props) {
        const [loading, showLoading] = React.useState(false);
        const [loadingMessage, setLoadingMessage] = React.useState("");

        const setLoading = (state, message = "") => {
            showLoading(state);
            setLoadingMessage(message);
        }

        return (
            <React.Fragment>
                {loading && <LoadingAnimation message={loadingMessage} />}
                <Component {...props} setLoading={setLoading} />
            </React.Fragment>
        )
    };
}
 
export default WithLoadingPage;