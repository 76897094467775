import { NavLink } from "react-router-dom";

const DataTable = ({ payments, paymentCategoryEntities, handleDelete }) => {

    return (
        <table className="table table-striped">
            <thead>
                <tr>
                    <th className="text-left" width="20%">Name</th>
                    <th className="text-left" width="20%">Category</th>
                    <th className="text-left">Description</th>
                    <th className="text-left" width="1%"/>
                    <th className="text-left" width="1%"/>
                </tr>
            </thead>
            <tbody>
                {payments.map(payment => 
					<tr key={payment.id}>
						<td>{payment.name}</td>
						<td>{paymentCategoryEntities[payment.payment_category_id]?.name}</td>
						<td>{payment.description}</td>
						<td className="text-right">
							<NavLink to={`/data/payments/${payment.id}`} className="btn btn-primary btn-icon">
                                <span className="las la-pen" />
                            </NavLink>
                        </td>
						<td className="text-right">
							<button className="btn btn-danger btn-icon" onClick={() => handleDelete(payment)}>
                                <span className="las la-trash-alt" />
                            </button>
						</td>
					</tr>
				)}
            </tbody>
        </table>
    );
}
 
export default DataTable;