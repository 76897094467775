import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fetchSalesInvoices } from "../../../store/thunks/salesInvoicesThunk";
import { fetchWaiters } from "../../../store/thunks/waitersThunk";
import { getSalesOrderEntities, unloadSalesOrders } from "../../../store/slices/datasources/salesOrdersSliceDatasource";
import { getWaiterEntities, unloadWaiters } from "../../../store/slices/datasources/waitersSliceDatasource";
import { getSalesInvoicePaymentEntities, unloadSalesInvoicePayments } from "../../../store/slices/datasources/salesInvoicePaymentsSliceDatasource";
import { getSalesInvoices, unloadSalesInvoices } from "../../../store/slices/datasources/salesInvoicesSliceDatasource";
import WithLoadingPage from "../commons/withLoadingPage";
import PageSubHeader from "../elements/pageSubHeader";

const ReportSalesInvoices = props => {
    
    const dispatch = useDispatch();
    const salesOrderEntities = useSelector(getSalesOrderEntities);
    const waiterEntities = useSelector(getWaiterEntities);
    const salesInvoicePaymentEntity = useSelector(getSalesInvoicePaymentEntities);
    const salesInvoices = useSelector(getSalesInvoices);
    const { setLoading } = props;
    const [code, setCode] = React.useState("");
    const [orderCode, setOrderCode] = React.useState("");
    const [fromDate, setFromDate] = React.useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    const [toDate, setToDate] = React.useState(new Date());

    React.useEffect(() => {
        return () => {
            dispatch(unloadSalesOrders());
            dispatch(unloadWaiters());
            dispatch(unloadSalesInvoicePayments());
            dispatch(unloadSalesInvoices());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        async function initialize() {
            setLoading(true);
            await dispatch(fetchWaiters());
            await dispatch(fetchSalesInvoices(getQueryFilter()));
            setLoading(false);
        }

        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const getQueryFilter = () => {
        const queryFilter = {
            ...(code && {code}),
            ...(orderCode && {orderCode}),
            fromDate: moment(fromDate).format("YYYYMMDD"),
            toDate: moment(toDate).format("YYYYMMDD"),
            withSalesOrder: true,
            withSalesInvoicePayments: true,
        };

        return {
            query: queryFilter
        };
    }

    const handleFilter = async () => {
		setLoading(true);
        await dispatch(fetchSalesInvoices(getQueryFilter()));
		setLoading(false);
	}

    return (
        <React.Fragment>
            <PageSubHeader title="Report Sales Invoices" />
            <div className="mx-auto w-3/4">
                <div className="classic card">
                    <div className="card-header bg-light-600">
                        <h2 className="card-title">
                            <i className="las la-search" /> Filter Sales Invoice
                        </h2>
                    </div>
                    <div className="card-body">
                        <div className="form-group">
                            <div className="flex">
                                <div className="w-1/4 mr-50">
                                    <label className="form-label" htmlFor="code">Billing</label>
                                    <input id="code" name="code" type="text" className="form-control" placeholder="Billing Number" value={code} onChange={e => setCode(e.target.value)} />
                                </div>
                                <div className="w-120 ml-2 mr-10">
                                    <label className="form-label" htmlFor="from_date">Periode</label>
                                    <div className="input-icon input-icon-right">
                                        <DatePicker id="from_date" name="from_date" className="form-control input-group-item" dateFormat="yyyy-MM-dd" selected={fromDate} onChange={e => setFromDate(e)} />
                                        <span>
                                            <i className="la la-calendar text-24" />
                                        </span>
                                    </div>
                                </div>
                                <div className="w-120 ml-2 mr-50">
                                    <label className="form-label" htmlFor="to_date">&nbsp;</label>
                                    <div className="input-icon input-icon-right">
                                        <DatePicker id="to_date" name="to_date" className="form-control input-group-item" dateFormat="yyyy-MM-dd" selected={toDate} onChange={e => setToDate(e)} />
                                        <span>
                                            <i className="la la-calendar text-24" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="flex">
                                <div className="w-1/4 mr-50">
                                    <label className="form-label" htmlFor="order_code">Order Code</label>
                                    <input id="order_code" name="order_code" type="text" className="form-control" placeholder="Order Number" value={orderCode} onChange={e => setOrderCode(e.target.value)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <button className="btn btn-primary mr-4" onClick={handleFilter}>
                            <i className="las la-filter" />
                            Process
                        </button>
                    </div>
                </div>

                <div className="classic card">
                    <div className="card-header bg-light-600">
                        <h2 className="card-title">
                            <i className="las la-list" /> Sales Invoices
                        </h2>
                    </div>
                    <div className="card-body">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th className="text-left" width="10%">Billing</th>
                                    <th className="text-left" width="10%">Order</th>
                                    <th className="text-left" width="10%">Date</th>
                                    <th className="text-left" width="10%">Waiter</th>
                                    <th className="text-left" width="10%">Cashier</th>
                                    <th className="text-right" width="5%">Pax</th>
                                    <th className="text-right" width="5%">Items</th>
                                    <th className="text-left" width="10%">Payments</th>
                                    <th className="text-right" width="10%">Total</th>
                                    <th className="text-right" width="5%">SC</th>
                                    <th className="text-right" width="5%">VAT</th>
                                    <th className="text-right" width="10%">Grand Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {salesInvoices.map(salesInvoice => 
                                    <tr key={salesInvoice.id}>
                                        <td>{salesInvoice.code}</td>
                                        <td>{salesOrderEntities[salesInvoice.sales_order_id]?.code}</td>
                                        <td>{moment(salesInvoice.invoice_date).format("YYYY-MM-DD")}</td>
                                        <td>{waiterEntities[salesOrderEntities[salesInvoice.sales_order_id]?.waiter_id]?.name}</td>
                                        <td>{waiterEntities[salesInvoice.cashier_id]?.name}</td>
                                        <td className="text-right">{Number(salesOrderEntities[salesInvoice.sales_order_id]?.pax).toLocaleString()}</td>
                                        <td className="text-right">{Number(salesInvoice.total_items).toLocaleString()}</td>
                                        <td>{salesInvoice.sales_invoice_payments.map(id => salesInvoicePaymentEntity[id].payment.name ).join(", ")}</td>
                                        <td className="text-right">{Number(salesInvoice.sub_total).toLocaleString()}</td>
                                        <td className="text-right">{Number(salesInvoice.service_charge_percent_value).toLocaleString()}</td>
                                        <td className="text-right">{Number(salesInvoice.vat_percent_value).toLocaleString()}</td>
                                        <td className="text-right">{Number(salesInvoice.grand_total).toLocaleString()}</td>
                                    </tr>
                                )}
                            </tbody>
                            <tfoot className="border-t-4 border-double border-gray-300">
                                <tr>
                                    <th className="text-right" colSpan="8"></th>
                                    <th className="text-right">{Number(salesInvoices.reduce((subTotal, salesInvoice) => subTotal + Number(salesInvoice.sub_total), 0)).toLocaleString()}</th>
                                    <th className="text-right">{Number(salesInvoices.reduce((servicChargeTotal, salesInvoice) => servicChargeTotal + Number(salesInvoice.service_charge_percent_value), 0)).toLocaleString()}</th>
                                    <th className="text-right">{Number(salesInvoices.reduce((vatTotal, salesInvoice) => vatTotal + Number(salesInvoice.vat_percent_value), 0)).toLocaleString()}</th>
                                    <th className="text-right">{Number(salesInvoices.reduce((grandTotal, salesInvoice) => grandTotal + Number(salesInvoice.grand_total), 0)).toLocaleString()}</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
 
export default WithLoadingPage(ReportSalesInvoices);