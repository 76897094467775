import { createEntityAdapter, createSelector, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { fetchStores, fetchStore } from "../../thunks/storesThunk";
import { fetchStoreBranches, fetchStoreBranch, saveStoreBranch } from "../../thunks/storeBranchesThunk";
import { fetchDiningTableSections, fetchDiningTableSection } from "../../thunks/diningTableSectionsThunk";

const dataAdapter = createEntityAdapter();
const dataView = dataAdapter.getSelectors(state => state.datasources.storeBranches);
const storeBranchesSliceDatasource = createSlice({
	name: "datasources/storeBranches",
	initialState: dataAdapter.getInitialState({
		pagination: {}
	}),
	reducers: {
		unloaded: (storeBranches, _) => {
			dataAdapter.removeAll(storeBranches, {});
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchStoreBranches.fulfilled, (storeBranches, action) => {
				if (action.payload.hasOwnProperty("pagination")) {
					storeBranches.pagination = action.payload.pagination;
				}
				
				if (action.payload.hasOwnProperty("storeBranches")) {
					dataAdapter.setAll(storeBranches, action.payload.storeBranches);
				}
			})
			.addCase(fetchStoreBranch.fulfilled, (storeBranch, action) => {
				if (action.payload.hasOwnProperty("storeBranch")) {
					dataAdapter.upsertMany(storeBranch, action.payload.storeBranch);
				}
			})
			.addCase(saveStoreBranch.fulfilled, (storeBranch, action) => {
				dataAdapter.upsertOne(storeBranch, action.payload.storeBranch);
			})
			.addMatcher(isAnyOf(fetchStores.fulfilled, fetchStore.fulfilled), (storeBranches, action) => {
				if (action.payload.hasOwnProperty("storeBranches")) {
					dataAdapter.setAll(storeBranches, action.payload.storeBranches);
				}
			})
			.addMatcher(isAnyOf(fetchDiningTableSections.fulfilled, fetchDiningTableSection.fulfilled), (storeBranch, action) => {
				if (action.payload.hasOwnProperty("storeBranch")) {
					dataAdapter.setAll(storeBranch, action.payload.storeBranch);
				}
			});
	}
});
export default storeBranchesSliceDatasource.reducer;

export const unloadStoreBranches = () => storeBranchesSliceDatasource.actions.unloaded();
export const getStoreBranches = dataView.selectAll;
export const getStoreBranchEntities = dataView.selectEntities;
export const getStoreBranchPagination = createSelector(
	state => state.datasources.storeBranches.pagination,
	pagination => pagination
);
export const getStoreBranch = id => createSelector(
	state => state.datasources.storeBranches.entities,
	storeBranches => storeBranches[id]
);