import React from "react";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
import { routes } from "../config/routes";
import AppRoutes from "./helpers/appRoutes";
import { isAuthorized, hasSelectedStore } from "./helpers/authentication";

const Application = () => {
	return (
		<React.Fragment>
			<BrowserRouter>
				<Switch>
					{routes.map((route, index) => <AppRoutes key={index} {...route} />)}
					{ isAuthorized() && !hasSelectedStore() &&  <Redirect from = "/" exact to = "/my-stores" /> }
					{ isAuthorized() && hasSelectedStore() && <Redirect from = "/" exact to = "/dashboard" /> }
					<Redirect from = "/" exact to = "/login" />
				</Switch>
			</BrowserRouter>
		</React.Fragment>
	);
};

export default Application;