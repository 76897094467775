import { createAsyncThunk } from "@reduxjs/toolkit";
import fsrHttpInstance, { fsrHttpConfiguration } from "../serviceFsrClients";
import { normalizeEntity } from "../entities/UnitOfMeasureEntity";
import { setFlashSuccess, setFlashError } from "../slices/commons/flashSliceCommon";

export const fetchUnitOfMeasures = createAsyncThunk(
	"datasources/unitOfMeasures/fetchUnitOfMeasures",
	async (request, thunkAPI) => {
		try {
			const params = request ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.get("vendor/unit-of-measures/index", {...params, ...fsrHttpConfiguration()}));
			const normalized = normalizeEntity(response.data.unitOfMeasures);
			
			return { 
				...normalized, 
				pagination: response.data.pagination || {}
			};
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The unit of measure could not be loaded. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const fetchUnitOfMeasure = createAsyncThunk(
	"datasources/unitOfMeasures/fetchUnitOfMeasure",
	async (request, thunkAPI) => {
		try {
			const params = request ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.get("vendor/unit-of-measures/view", {...params, ...fsrHttpConfiguration()}));
			const normalized = normalizeEntity(response.data.unitOfMeasure);

			return normalized;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The unit of measure could not be loaded. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const saveUnitOfMeasure = createAsyncThunk(
	"datasources/unitOfMeasures/saveUnitOfMeasure",
	async (request, thunkAPI) => {
		try {
			const formData = { ...request };
			const action = formData.id ? "edit" : "add";
			const response = await fsrHttpInstance.then(fsrService => fsrService.post(`vendor/unit-of-measures/${action}`, formData, fsrHttpConfiguration()));

			thunkAPI.dispatch(setFlashSuccess("The unit of measure has been saved."));
			return response.data;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The unit of measure could not be saved. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const deleteUnitOfMeasure = createAsyncThunk(
	"datasources/unitOfMeasures/deleteUnitOfMeasure",
	async (request, thunkAPI) => {
		try {
			const params = request.query ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.post("vendor/unit-of-measures/delete", null, {...params, ...fsrHttpConfiguration()}));

			thunkAPI.dispatch(setFlashSuccess("The unit of measure has been deleted."));
			return response.data;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The unit of measure could not be deleted. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);