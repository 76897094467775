import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { fetchVoidReasons, fetchVoidReason, saveVoidReason, deleteVoidReason } from "../../thunks/voidReasonsThunk";

const dataAdapter = createEntityAdapter();
const dataView = dataAdapter.getSelectors(state => state.datasources.voidReasons);
const voidReasonsSliceDatasource = createSlice({
	name: "datasources/voidReasons",
	initialState: dataAdapter.getInitialState({
		pagination: {}
	}),
	reducers: {
		unloaded: (voidReasons, _) => {
			dataAdapter.removeAll(voidReasons, {});
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchVoidReasons.fulfilled, (voidReasons, action) => {
				if (action.payload.hasOwnProperty("pagination")) {
					voidReasons.pagination = action.payload.pagination;
				}
				
				if (action.payload.hasOwnProperty("voidReasons")) {
					dataAdapter.setAll(voidReasons, action.payload.voidReasons);
				}
			})
			.addCase(fetchVoidReason.fulfilled, (voidReason, action) => {
				if (action.payload.hasOwnProperty("voidReason")) {
					dataAdapter.upsertMany(voidReason, action.payload.voidReason);
				}
			})
			.addCase(saveVoidReason.fulfilled, (voidReason, action) => {
				dataAdapter.upsertOne(voidReason, action.payload.voidReason);
			})
			.addCase(deleteVoidReason.fulfilled, (voidReason, action) => {
				dataAdapter.removeOne(voidReason, action.payload.voidReason.id);
			});
	}
});
export default voidReasonsSliceDatasource.reducer;

export const unloadVoidReasons = () => voidReasonsSliceDatasource.actions.unloaded();
export const getVoidReasons = dataView.selectAll;
export const getVoidReasonEntities = dataView.selectEntities;
export const getVoidReasonPagination = createSelector(
	state => state.datasources.voidReasons.pagination,
	pagination => pagination
);
export const getVoidReason = id => createSelector(
	state => state.datasources.voidReasons.entities,
	voidReasons => voidReasons[id]
);