import { createSlice } from "@reduxjs/toolkit";
import { configure } from "../../../config/app";

const defaultValue = { id: "", code: "", name: "", file_name: "", file_size: "", file_type: "", uploaded_path: "", uploaded_file_name: "", asset_path: "", asset_url: "" };
const initialState = localStorage.getItem(configure.StorageKey.CurrentStore) === null ? defaultValue : JSON.parse(localStorage.getItem(configure.StorageKey.CurrentStore));
const storeSliceAuthentication = createSlice({
	name: "auth/store",
	initialState: initialState,
	reducers: {
		selectedStore: (store, action) => {
			store.id = action.payload.store.id;
			store.code = action.payload.store.code;
			store.name = action.payload.store.name;
			store.file_name = action.payload.store.file_name; 
			store.file_size = action.payload.store.file_size; 
			store.file_type = action.payload.store.file_type; 
			store.uploaded_path = action.payload.store.uploaded_path;
			store.uploaded_file_name = action.payload.store.uploaded_file_name; 
			store.asset_path = action.payload.store.asset_path; 
			store.asset_url = action.payload.store.asset_url;
			store.asset_url = action.payload.store.asset_url;
		}
	}
});
export const { selectedStore } = storeSliceAuthentication.actions;
export default storeSliceAuthentication.reducer;

export const selectStore = store => storeSliceAuthentication.actions.selectedStore(store);