import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { fetchPayments, fetchPayment, savePayment, deletePayment } from "../../thunks/paymentsThunk";

const dataAdapter = createEntityAdapter();
const dataView = dataAdapter.getSelectors(state => state.datasources.payments);
const paymentsSliceDatasource = createSlice({
	name: "datasources/payments",
	initialState: dataAdapter.getInitialState({
		pagination: {}
	}),
	reducers: {
		unloaded: (payments, _) => {
			dataAdapter.removeAll(payments, {});
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchPayments.fulfilled, (payments, action) => {
				if (action.payload.hasOwnProperty("pagination")) {
					payments.pagination = action.payload.pagination;
				}
	
				if (action.payload.hasOwnProperty("payments")) {
					dataAdapter.setAll(payments, action.payload.payments);
				}
			})
			.addCase(fetchPayment.fulfilled, (payment, action) => {
				if (action.payload.hasOwnProperty("payment")) {
					dataAdapter.upsertMany(payment, action.payload.payment);
				}
			})
			.addCase(savePayment.fulfilled, (payment, action) => {
				dataAdapter.upsertOne(payment, action.payload.payment);
			})
			.addCase(deletePayment.fulfilled, (payment, action) => {
				dataAdapter.removeOne(payment, action.payload.payment.id);
			});
	}
});
export default paymentsSliceDatasource.reducer;

export const unloadPayments = () => paymentsSliceDatasource.actions.unloaded();
export const getPayments = dataView.selectAll;
export const getPaymentEntities = dataView.selectEntities;
export const getPaymentPagination = createSelector(
	state => state.datasources.payments.pagination,
	pagination => pagination
);
export const getPayment = id => createSelector(
	state => state.datasources.payments.entities,
	payments => payments[id]
);