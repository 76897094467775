import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { fetchMenu } from "../../thunks/menusThunk";

const dataAdapter = createEntityAdapter();
const menuIngredientsSliceDatasource = createSlice({
	name: "datasources/menuIngredients",
	initialState: dataAdapter.getInitialState(),
	reducers: {
		unloaded: (menuIngredients, _) => {
			dataAdapter.removeAll(menuIngredients, {});
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchMenu.fulfilled, (menuIngredients, action) => {
				if (action.payload.hasOwnProperty("menuIngredients")) {
					dataAdapter.setAll(menuIngredients, action.payload.menuIngredients || []);
				}
			});
	}
});
export default menuIngredientsSliceDatasource.reducer;

export const unloadMenuIngredients = () => menuIngredientsSliceDatasource.actions.unloaded();