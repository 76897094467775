import { createEntityAdapter, createSelector, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { fetchMenuCategories, fetchMenuCategory, saveMenuCategory, deleteMenuCategory } from "../../thunks/menuCategoriesThunk";
import { fetchMenus, fetchMenu } from "../../thunks/menusThunk";

const dataAdapter = createEntityAdapter();
const dataView = dataAdapter.getSelectors(state => state.datasources.menuCategories);
const menuCategoriesSliceDatasource = createSlice({
	name: "datasources/menuCategories",
	initialState: dataAdapter.getInitialState({
		pagination: {}
	}),
	reducers: {
		unloaded: (menuCategories, _) => {
			dataAdapter.removeAll(menuCategories, {});
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchMenuCategories.fulfilled, (menuCategories, action) => {
				if (action.payload.hasOwnProperty("pagination")) {
					menuCategories.pagination = action.payload.pagination;
				}
				
				if (action.payload.hasOwnProperty("menuCategories")) {
					dataAdapter.setAll(menuCategories, action.payload.menuCategories);
				}
			})
			.addCase(fetchMenuCategory.fulfilled, (menuCategory, action) => {
				if (action.payload.hasOwnProperty("menuCategory")) {
					dataAdapter.upsertMany(menuCategory, action.payload.menuCategory);
				}
			})
			.addCase(saveMenuCategory.fulfilled, (menuCategory, action) => {
				dataAdapter.upsertOne(menuCategory, action.payload.menuCategory);
			})
			.addCase(deleteMenuCategory.fulfilled, (menuCategory, action) => {
				dataAdapter.removeOne(menuCategory, action.payload.menuCategory.id);
			})
			.addMatcher(isAnyOf(fetchMenus.fulfilled, fetchMenu.fulfilled), (menuCategory, action) => {
				if (action.payload.hasOwnProperty("menuCategory")) {
					dataAdapter.setAll(menuCategory, action.payload.menuCategory);
				}
			});
	}
});
export default menuCategoriesSliceDatasource.reducer;

export const unloadMenuCategories = () => menuCategoriesSliceDatasource.actions.unloaded();
export const getMenuCategories = dataView.selectAll;
export const getMenuCategoryEntities = dataView.selectEntities;
export const getMenuCategoryPagination = createSelector(
	state => state.datasources.menuCategories.pagination,
	pagination => pagination
);
export const getMenuCategory = id => createSelector(
	state => state.datasources.menuCategories.entities,
	menuCategories => menuCategories[id]
);