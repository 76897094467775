import { createAsyncThunk } from "@reduxjs/toolkit";
import fsrHttpInstance, { fsrHttpConfiguration } from "../serviceFsrClients";
import { normalizeEntity } from "../entities/stationEntity";
import { setFlashSuccess, setFlashError } from "../slices/commons/flashSliceCommon";

export const fetchStations = createAsyncThunk(
	"datasources/stations/fetchStations",
	async (request, thunkAPI) => {
		try {
			const params = request ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.get("vendor/stations/index", {...params, ...fsrHttpConfiguration()}));
			const normalized = normalizeEntity(response.data.stations);

			return { 
				...normalized, 
				pagination: response.data.pagination || {}
			};
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The station could not be loaded. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const fetchStation = createAsyncThunk(
	"datasources/stations/fetchStation",
	async (request, thunkAPI) => {
		try {
			const params = request ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.get("vendor/stations/view", {...params, ...fsrHttpConfiguration()}));
			const normalized = normalizeEntity(response.data.station);

			return normalized;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The station could not be loaded. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const saveStation = createAsyncThunk(
	"datasources/stations/saveStation",
	async (request, thunkAPI) => {
		try {
			const formData = { ...request };
			const action = formData.id ? "edit" : "add";
			const response = await fsrHttpInstance.then(fsrService => fsrService.post(`vendor/stations/${action}`, formData, fsrHttpConfiguration()));

			thunkAPI.dispatch(setFlashSuccess("The station has been saved."));
			return response.data;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The station could not be saved. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const deleteStation = createAsyncThunk(
	"datasources/stations/deleteStation",
	async (request, thunkAPI) => {
		try {
			const params = request.query ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.post("vendor/stations/delete", null, {...params, ...fsrHttpConfiguration()}));
			
			thunkAPI.dispatch(setFlashSuccess("The station has been deleted."));
			return response.data;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The station could not be deleted. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);