import React from "react";
import PageSubHeader from "../elements/pageSubHeader";

const Dashboards = () => {
    
    return (
        <React.Fragment>
           <PageSubHeader title="Dashboard" />
        </React.Fragment>
    );
}
 
export default Dashboards;