import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { fetchCustomers } from "../../../store/thunks/customersThunk";
import { getCustomerCategoryEntities, unloadCustomerCategories } from "../../../store/slices/datasources/customerCategoriesSliceDatasource";
import { getCustomers, getCustomerPagination, unloadCustomers } from "../../../store/slices/datasources/customersSliceDatasource";
import WithLoadingPage from "../commons/withLoadingPage";
import PageSubHeader from "../elements/pageSubHeader";
import Paginator from "../elements/paginator";
import DataTable from "./components/dataTable";

const Customers = props => {
    
    const dispatch = useDispatch();
    const customerCategoryEntities = useSelector(getCustomerCategoryEntities);
    const customers = useSelector(getCustomers);
    const pagination = useSelector(getCustomerPagination);
    const { setLoading } = props;
    const [search, setSearch] = React.useState("");
    const [currentPage, setCurrentPage] = React.useState(1);

    React.useEffect(() => {
        return () => {
            dispatch(unloadCustomerCategories());
            dispatch(unloadCustomers());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        async function initialize() {
            setLoading(true);
            await dispatch(fetchCustomers(getQueryFilter()));
            setLoading(false);
        }
        
        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, currentPage]);

    const getQueryFilter = () => {
        const queryFilter = {
            withCustomerCategory: true,
            search: search,
            page: currentPage,
            paginate: 25,
        };

        return {
            query: queryFilter
        };
    }

    const handleSearchInputChange = element => {
		const { value } = element.target;
		setSearch(value);
	}

    const handleSearch = async () => {
        setLoading(true);
        await dispatch(fetchCustomers(getQueryFilter()));
        setLoading(false);
    }

    const handlePageChange = page => {
        if (currentPage !== page) {
			setCurrentPage(page);
		}
    }

    return (
        <React.Fragment>
            <PageSubHeader title="Customers" />
            <div className="mx-auto w-3/4">
                <div className="classic card">
                    <div className="card-header bg-light-600">
                        <div className="flex align-middle">
                            <div className="flex-grow mr-20">
                                <div className="input-group">
                                    <input id="search" name="search" type="text" className="form-control input-group-item" placeholder="Search..." value={search} onChange={handleSearchInputChange} />
                                    <button className="btn btn-primary uppercase input-group-item" onClick={handleSearch}>
                                        <i className="las la-search" />
                                    </button>
                                </div>
                            </div>
                            <div className="flex">
                                <NavLink to="/data/customers/add" className="btn btn-primary">
                                    <i className="las la-plus" />
                                    Add New
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="card-body text-left">
                        <DataTable customers={customers} customerCategoryEntities={customerCategoryEntities} />
                        <Paginator pagination={pagination} handlePageChange={handlePageChange} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
 
export default WithLoadingPage(Customers);