import { NavLink } from "react-router-dom";

const DataTable = ({ diningTables, diningTableSectionEntities, handleDelete }) => {

    return (
        <table className="table table-striped">
            <thead>
                <tr>
                    <th className="text-left" width="15%">Section</th>
                    <th className="text-left" width="15%">Number</th>
                    <th className="text-left" width="10%">Pax</th>
                    <th className="text-left">Description</th>
                    <th className="text-left" width="1%"/>
                    <th className="text-left" width="1%"/>
                </tr>
            </thead>
            <tbody>
                {diningTables.map(diningTable => 
                    <tr key={diningTable.id} className="hover:bg-gray-100">
                        <td>{diningTableSectionEntities[diningTable.dining_table_section_id]?.name}</td>
                        <td>{diningTable.number}</td>
                        <td>{diningTable.pax}</td>
                        <td>{diningTable.description}</td>
                        <td>
                            <NavLink to={`/data/dining-tables/${diningTable.id}`} className="btn btn-primary btn-icon">
                                <span className="las la-pen" />
                            </NavLink>
                        </td>
                        <td>
                            <button className="btn btn-danger btn-icon" onClick={() => handleDelete(diningTable)}>
                                <span className="las la-trash-alt" />
                            </button>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}
 
export default DataTable;