import { createAsyncThunk } from "@reduxjs/toolkit";
import fsrHttpInstance, { fsrHttpConfiguration } from "../serviceFsrClients";
import { normalizeEntity } from "../entities/diningTableSectionEntity";
import { setFlashSuccess, setFlashError } from "../slices/commons/flashSliceCommon";

export const fetchDiningTableSections = createAsyncThunk(
	"datasources/diningTableSections/fetchDiningTableSections",
	async (request, thunkAPI) => {
		try {
			const params = request ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.get("vendor/dining-table-sections/index", {...params, ...fsrHttpConfiguration()}));
			const normalized = normalizeEntity(response.data.diningTableSections);

			return { 
				...normalized, 
				...response.data.pagination || {} 
			};
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The dining table section could not be loaded. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const fetchDiningTableSection = createAsyncThunk(
	"datasources/diningTableSections/fetchDiningTableSection",
	async (request, thunkAPI) => {
		try {
			const params = request ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.get("vendor/dining-table-sections/view", {...params, ...fsrHttpConfiguration()}));
			const normalized = normalizeEntity(response.data.diningTableSection);

			return normalized;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The dining table section could not be loaded. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const saveDiningTableSection = createAsyncThunk(
	"datasources/diningTableSections/saveDiningTableSection",
	async (request, thunkAPI) => {
		try {
			const formData = { ...request };
			const action = formData.id ? "edit" : "add";
			const response = await fsrHttpInstance.then(fsrService => fsrService.post(`vendor/dining-table-sections/${action}`, formData, fsrHttpConfiguration()));

			thunkAPI.dispatch(setFlashSuccess("The dining table section has been saved."));
			return response.data;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The dining table section could not be saved. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const deleteDiningTableSection = createAsyncThunk(
	"datasources/diningTableSections/deleteDiningTableSection",
	async (request, thunkAPI) => {
		try {
			const params = request.query ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.post("vendor/dining-table-sections/delete", null, {...params, ...fsrHttpConfiguration()}));

			thunkAPI.dispatch(setFlashSuccess("The dining table section has been deleted."));
			return response.data;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The dining table section could not be deleted. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);