import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fetchMenus } from "../../../store/thunks/menusThunk";
import { getMenus, unloadMenus } from "../../../store/slices/datasources/menusSliceDatasource";
import WithLoadingPage from "../commons/withLoadingPage";
import PageSubHeader from "../elements/pageSubHeader";

const ReportSalesMenus = props => {
    
    const dispatch = useDispatch();
    const menus = useSelector(getMenus);
    const { setLoading } = props;
    const [fromDate, setFromDate] = React.useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    const [toDate, setToDate] = React.useState(new Date());

    React.useEffect(() => {
        return () => {
            dispatch(unloadMenus());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        async function initialize() {
            setLoading(true);
            await dispatch(fetchMenus(getQueryFilter()));
            setLoading(false);
        }

        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const getQueryFilter = () => {
        const queryFilter = {
            fromDate: moment(fromDate).format("YYYYMMDD"),
            toDate: moment(toDate).format("YYYYMMDD"),
            withSalesSummary: true
        };

        return {
            query: queryFilter
        };
    }

    const handleFilter = async () => {
		setLoading(true);
        await dispatch(fetchMenus(getQueryFilter()));
		setLoading(false);
	}

    return (
        <React.Fragment>
            <PageSubHeader title="Report Sales Menus" />
            <div className="mx-auto w-3/4">
                <div className="classic card">
                    <div className="card-header bg-light-600">
                        <h2 className="card-title">
                            <i className="las la-search" /> Filter Sales Menus
                        </h2>
                    </div>
                    <div className="card-body">
                        <div className="form-group">
                            <div className="flex">
                                <div className="w-120 ml-2 mr-10">
                                    <label className="form-label" htmlFor="from_date">Periode</label>
                                    <div className="input-icon input-icon-right">
                                        <DatePicker id="from_date" name="from_date" className="form-control input-group-item" dateFormat="yyyy-MM-dd" selected={fromDate} onChange={e => setFromDate(e)} />
                                        <span>
                                            <i className="la la-calendar text-24" />
                                        </span>
                                    </div>
                                </div>
                                <div className="w-120 ml-2 mr-50">
                                    <label className="form-label" htmlFor="to_date">&nbsp;</label>
                                    <div className="input-icon input-icon-right">
                                        <DatePicker id="to_date" name="to_date" className="form-control input-group-item" dateFormat="yyyy-MM-dd" selected={toDate} onChange={e => setToDate(e)} />
                                        <span>
                                            <i className="la la-calendar text-24" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <button className="btn btn-primary mr-4" onClick={handleFilter}>
                            <i className="las la-filter" />
                            Process
                        </button>
                    </div>
                </div>

                <div className="classic card">
                    <div className="card-header bg-light-600">
                        <h2 className="card-title">
                            <i className="las la-list" /> Sales Menus
                        </h2>
                    </div>
                    <div className="card-body">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th className="text-left" width="10%">Code</th>
                                    <th className="text-left">Menu</th>
                                    <th className="text-right" width="10%">Quantity</th>
                                    <th className="text-right" width="15%">Total</th>
                                    <th className="text-right" width="15%">Total Discount</th>
                                    <th className="text-right" width="15%">Sub Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {menus.map(menu => 
                                    <tr key={menu.id}>
                                        <td>{menu.code}</td>
                                        <td>{menu.name}</td>
                                        <td className="text-right">{Number(menu.quantity).toLocaleString()}</td>
                                        <td className="text-right">{Number(menu.total).toLocaleString()}</td>
                                        <td className="text-right">{Number(menu.total_discount).toLocaleString()}</td>
                                        <td className="text-right">{Number(menu.sub_total).toLocaleString()}</td>
                                    </tr>
                                )}
                            </tbody>
                            <tfoot className="border-t-4 border-double border-gray-300">
                                <tr>
                                    <th className="text-right" colSpan="3"></th>
                                    <th className="text-right">{Number(menus.reduce((total, menu) => total + Number(menu.total), 0)).toLocaleString()}</th>
                                    <th className="text-right">{Number(menus.reduce((totalDiscount, menu) => totalDiscount + Number(menu.total_discount), 0)).toLocaleString()}</th>
                                    <th className="text-right">{Number(menus.reduce((subTotal, menu) => subTotal + Number(menu.sub_total), 0)).toLocaleString()}</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
 
export default WithLoadingPage(ReportSalesMenus);