import { createEntityAdapter, createSelector, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { fetchMenuCategories, fetchMenuCategory } from "../../thunks/menuCategoriesThunk";
import { fetchStations, fetchStation, saveStation, deleteStation } from "../../thunks/stationsThunk";

const dataAdapter = createEntityAdapter();
const dataView = dataAdapter.getSelectors(state => state.datasources.stations);
const stationsSliceDatasource = createSlice({
	name: "datasources/stations",
	initialState: dataAdapter.getInitialState({
		pagination: {}
	}),
	reducers: {
		unloaded: (stations, _) => {
			dataAdapter.removeAll(stations, {});
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchStations.fulfilled, (stations, action) => {
				if (action.payload.hasOwnProperty("pagination")) {
					stations.pagination = action.payload.pagination;
				}
	
				if (action.payload.hasOwnProperty("stations")) {
					dataAdapter.setAll(stations, action.payload.stations);
				}
			})
			.addCase(fetchStation.fulfilled, (station, action) => {
				if (action.payload.hasOwnProperty("station")) {
					dataAdapter.upsertMany(station, action.payload.station);
				}
			})
			.addCase(saveStation.fulfilled, (station, action) => {
				dataAdapter.upsertOne(station, action.payload.station);
			})
			.addCase(deleteStation.fulfilled, (station, action) => {
				dataAdapter.removeOne(station, action.payload.station.id);
			})
			.addMatcher(isAnyOf(fetchMenuCategories.fulfilled, fetchMenuCategory.fulfilled), (station, action) => {
				if (action.payload.hasOwnProperty("station")) {
					dataAdapter.setAll(station, action.payload.station);
				}
			});
	}
});
export default stationsSliceDatasource.reducer;

export const unloadStations = () => stationsSliceDatasource.actions.unloaded();
export const getStations = dataView.selectAll;
export const getStationEntities = dataView.selectEntities;
export const getStationPagination = createSelector(
	state => state.datasources.stations.pagination,
	pagination => pagination
);
export const getStation = id => createSelector(
	state => state.datasources.stations.entities,
	stations => stations[id]
);