import { createAsyncThunk } from "@reduxjs/toolkit";
import fsrHttpInstance, { fsrHttpConfiguration } from "../serviceFsrClients";
import { normalizeEntity } from "../entities/storeBranchEntity";
import { setFlashSuccess, setFlashError } from "../slices/commons/flashSliceCommon";

export const fetchStoreBranches = createAsyncThunk(
	"datasources/storeBranches/fetchStoreBranches",
	async (request, thunkAPI) => {
		try {
			const params = request ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.get("vendor/store-branches/index", {...params, ...fsrHttpConfiguration()}));
			const normalized = normalizeEntity(response.data.storeBranches);

			return { 
				...normalized, 
				pagination: response.data.pagination || {}
			};
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The store branch could not be loaded. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const fetchStoreBranch = createAsyncThunk(
	"datasources/storeBranches/fetchStoreBranch",
	async (request, thunkAPI) => {
		try {
			const params = request ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.get("vendor/store-branches/view", {...params, ...fsrHttpConfiguration()}));
			const { storeBranch } = normalizeEntity(response.data.storeBranch);

			return {
				storeBranch
			};
		} catch (exception) {
			console.log(exception);
			thunkAPI.dispatch(setFlashError("The store branch could not be loaded. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const saveStoreBranch = createAsyncThunk(
	"datasources/storeBranches/saveStoreBranch",
	async (request, thunkAPI) => {
		try {
			const formData = { ...request };
			const action = formData.id ? "edit" : "add";
			const response = await fsrHttpInstance.then(fsrService => fsrService.post(`vendor/store-branches/${action}`, formData, fsrHttpConfiguration()));

			thunkAPI.dispatch(setFlashSuccess("The store branch has been saved."));
			return response.data;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The store branch could not be saved. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);