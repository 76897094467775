import React from "react";
import { NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

const CompactSidebar = ({ navigationItems, handleClick }) => {
    
    const handleItemClick = (event, item) => {
        event.preventDefault();
        handleClick(item);
    }

    return (
        <div className="compact-sidebar">
            <div className="sidebar-layout sidebar-layout-dark">
                <div className="items">
                    <NavLink to="/dashboard" className="link">
                        <i className="icon las la-laptop" />
                        <span className="title">Dashboard</span>
                    </NavLink>
                    <NavLink to="/stores" className="link" onClick={e => handleItemClick(e, "store")}>
                        <i className="icon las la-sign" />
                        <span className="title">Store</span>
                    </NavLink>
                    <NavLink to="/data" className="link" onClick={e => handleItemClick(e, "library")}>
                        <i className="icon las la-server" />
                        <span className="title">Library</span>
                    </NavLink>
                    {/*
                    <NavLink to="/purchase" className="link" onClick={e => handleItemClick(e, "purchase")}>
                        <i className="icon las la-shopping-cart" />
                        <span className="title">Purchase</span>
                    </NavLink>
                    */}
                    <NavLink to="/report" className="link" onClick={e => handleItemClick(e, "report")}>
                        <i className="icon las la-book" />
                        <span className="title">Report</span>
                    </NavLink>
                </div>
            </div>

            <div className={`detail ${navigationItems.store && "open"}`}>
                <PerfectScrollbar>
                    <div className="detail-items">
                        <h6>Configurations</h6>
                        <NavLink to="/stores/configuration"><span className="las la-cog"/>Manage Store</NavLink>
                        <NavLink to="/stores/branches"><span className="las la-route"/>Branches</NavLink>
                        <hr />
                        <NavLink to="/my-stores"><span className="las la-map-marked-alt" />Change Store</NavLink>
                    </div>
                </PerfectScrollbar>
            </div>
            <div className={`detail ${navigationItems.library && "open"}`}>
                <PerfectScrollbar>
                    <div className="detail-items">
                        <h6>Configurations</h6>
                        <NavLink to="/data/taxes"><span className="las la-percent"/>Taxes</NavLink>
                        <NavLink to="/data/void-reasons"><span className="las la-file-excel"/>Void Reasons</NavLink>
                        <NavLink to="/data/waiters"><span className="las la-id-card"/>Waiters</NavLink>
                        <hr />
                        <NavLink to="/data/dining-table-sections"><span className="las la-boxes"/>Sections</NavLink>
                        <NavLink to="/data/dining-tables"><span className="las la-pallet"/>Dining Tables</NavLink>
                        <hr />
                        <NavLink to="/data/unit-of-measures"><span className="las la-print"/>Unit Of Measures</NavLink>
                        <NavLink to="/data/ingredients"><span className="las la-seedling"/>Ingredients</NavLink>
                        <hr />
                        <NavLink to="/data/stations"><span className="las la-print"/>Stations</NavLink>
                        <NavLink to="/data/menu-categories"><span className="las la-sitemap"/>Menu Categories</NavLink>
                        <NavLink to="/data/menus"><span className="las la-book-open"/>Menus</NavLink>
                        <hr />
                        <h6>Payment Management</h6>
                        <NavLink to="/data/payment-categories"><span className="las la-tags"/>Category</NavLink>
                        <NavLink to="/data/payments"><span className="las la-coins"/>Payments</NavLink>
                        <hr />
                        <h6>Customer Management</h6>
                        <NavLink to="/data/customer-categories"><span className="las la-tags"/>Categories</NavLink>
                        <NavLink to="/data/customers"><span className="las la-user-tie"/>Customers</NavLink>
                    </div>
                </PerfectScrollbar>
            </div>
            <div className={`detail ${navigationItems.purchase && "open"}`}>
                <PerfectScrollbar>
                    <div className="detail-items">
                        <h6>Configurations</h6>
                        <NavLink to="/purchase/suppliers"><span className="las la-store-alt"/>Suppliers</NavLink>
                        <hr />
                        <h6>Transactions</h6>
                        <NavLink to="/purchase/orders"><span className="las la-edit"/>Order</NavLink>
                        <NavLink to="/purchase/invoices"><span className="las la-file-invoice-dollar"/>Invoice</NavLink>
                        <NavLink to="/purchase/payments"><span className="lab la-cc-visa"/>Payments</NavLink>
                        <NavLink to="/purchase/returns"><span className="las la-share-square"/>Returns</NavLink>
                    </div>
                </PerfectScrollbar>
            </div>
            <div className={`detail ${navigationItems.report && "open"}`}>
                <PerfectScrollbar>
                    <div className="detail-items">
                        <NavLink to="/report/sales-invoices"><span className="las la-book"/>Sales Invoice</NavLink>
                        <NavLink to="/report/sales-menu"><span className="las la-book"/>Sales Menu</NavLink>
                    </div>
                </PerfectScrollbar>
            </div>
        </div>
    );
}
 
export default CompactSidebar;