import { NavLink } from "react-router-dom";

const DataTable = ({ taxes, handleDelete }) => {

    return (
        <table className="table table-striped">
            <thead>
                <tr>
                    <th className="px-6 py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider" width="15%">Periode</th>
                    <th className="px-6 py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider" width="15%">Service Charge</th>
                    <th className="px-6 py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider" width="15%">VAT</th>
                    <th className="px-6 py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Description</th>
                    <th className="px-6 py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider" width="1%"/>
                    <th className="px-6 py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider" width="1%"/>
                </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
                {taxes.map(tax => 
                    <tr key={tax.id}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-700">{tax.periode}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-700">{Number(tax.service_charge_percent).toLocaleString()}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-700">{Number(tax.vat_percent).toLocaleString()}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-700">{tax.description}</td>
                        <td className="text-right">
                            <NavLink to={`/data/taxes/${tax.id}`} className="btn btn-outlined btn-round btn-primary btn-icon">
                                <span className="las la-pen" />
                            </NavLink>
                        </td>
                        <td className="text-right">
                            <button className="btn btn-outlined btn-round btn-danger btn-icon" onClick={() => handleDelete(tax)}>
                                <span className="las la-trash-alt" />
                            </button>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}
 
export default DataTable;