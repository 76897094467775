import { denormalize, normalize, schema } from "normalizr";

export const normalizeEntity = (datasources) => {
	const isArray = datasources instanceof Array;
	const dataset = isArray ? "voidReasons" : "voidReason";
	const voidReasonEntity = new schema.Entity(dataset);
	const result = normalize(
		datasources, 
		isArray ? [voidReasonEntity] : voidReasonEntity
	);
	
	return result.entities;
}

export const denormalizeEntity = (datasources) => {
	const isArray = datasources.voidReasons;
	const dataset = isArray ? "voidReasons" : "voidReason";
	const voidReasonEntity = new schema.Entity(dataset)
	const entities = isArray ? datasources.voidReasons : datasources.voidReason;

	return denormalize(
		{ [dataset]: isArray ? Object.keys(entities) : Object.keys(entities).toString() }, 
		{ [dataset]: isArray ? [voidReasonEntity] : voidReasonEntity }, 
		{ [dataset]: entities }
	);
}