import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { unwrapResult } from '@reduxjs/toolkit'
import { fetchStoreBranch, saveStoreBranch } from "../../../store/thunks/storeBranchesThunk";
import { getStoreBranch, unloadStoreBranches } from "../../../store/slices/datasources/storeBranchesSliceDatasource";
import WithLoadingPage from "../commons/withLoadingPage";
import Flash from "../elements/flash";
import PageSubHeader from "../elements/pageSubHeader";

const StoreBranchForm = props => {
    
	const dispatch = useDispatch();
    const history = useHistory();
	const routeParams = useParams();
    const { id: storeBranchId } = routeParams;
	const storeBranch = useSelector(getStoreBranch(storeBranchId));
	const { setLoading } = props;
	const [request, setRequest] = React.useState({ id: null, name: "", address_line_1: "", address_line_2: "", phone: "" });
	const [error, setError] = React.useState({ name: null, address_line_1: null, phone: null });

    React.useEffect(() => {
        return () => {
            dispatch(unloadStoreBranches());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {		
        async function initialize() {
            setLoading(true);
            if (parseInt(storeBranchId)) {
                await dispatch(fetchStoreBranch(getQueryFilter()));
            }
            setLoading(false);
        }
		
        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, storeBranchId]); 
	
	React.useEffect(() => {
		if (storeBranch) {
			const mapViewToModel = {
				id: storeBranch.id,
				code: storeBranch.code,
				name: storeBranch.name,
				address_line_1: storeBranch.address_line_1,
				address_line_2: storeBranch.address_line_2,
				phone: storeBranch.phone
			}

			setRequest(prev => { 
                return { 
                    ...prev, 
                    ...mapViewToModel 
                }
            });
		}
	}, [storeBranch]);

    const getQueryFilter = () => {
        const queryFilter = {
            id: storeBranchId,
        };

        return {
            query: queryFilter
        };
    }
    
	const handleInputChange = element => {
		const { name, value } = element.target;
		setRequest({...request, [name]: value});
	}

	const handleFormSubmit = async event => {
		event.preventDefault();
		setLoading(true);
		await dispatch(saveStoreBranch(request))
			.then(unwrapResult)
			.then(() => {
				setLoading(false);
				setError({ ...error, name: null, address_line_1: null, phone: null });
				history.push('/stores/branches');
			})
			.catch(exception => {
				setLoading(false);
				setError(exception.errors);
			});
	}

    return (
        <React.Fragment>
            <PageSubHeader title={`${storeBranchId === "add" ? "New" : "Edit"} Branch`} />
            <div className="mx-auto w-2/4">
                <Flash />
                <div className="classic card">
                    <div className="card-header bg-light-600">
                        <h3 className="card-title">Branch Form</h3>
                    </div>
                    <div className="card-body text-left">
                        <form className="w-full" onSubmit={handleFormSubmit}>
                            <div className="form-group">
                                <label className="form-label" htmlFor="name">Name</label>
                                <input id="name" name="name" type="text" className="form-control" value={request.name} onChange={handleInputChange} />
                            </div>
                            <div className="form-group">
                                <label className="form-label" htmlFor="address_line_1">Address</label>
                                <input id="address_line_1" name="address_line_1" type="text" className="form-control mb-5" value={request.address_line_1} onChange={handleInputChange} />
                                <input id="address_line_2" name="address_line_2" type="text" className="form-control" value={request.address_line_2} onChange={handleInputChange} />
                            </div>
                            <div className="form-group">
                                <label className="form-label" htmlFor="phone">Phone</label>
                                <input id="phone" name="phone" type="text" className="form-control" value={request.phone} onChange={handleInputChange} />
                            </div>
                            <div className="form-group text-right">
                                <NavLink to="/stores/branches" className="btn btn-secondary mr-4">
                                    <i className="las la-undo" />
                                    Back
                                </NavLink>
                                <button className="btn btn-primary mr-4">
                                    <i className="las la-save" />
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
 
export default WithLoadingPage(StoreBranchForm);