import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { unwrapResult } from '@reduxjs/toolkit'
import { fetchStations } from "../../../store/thunks/stationsThunk";
import { fetchMenuCategory, saveMenuCategory } from "../../../store/thunks/menuCategoriesThunk";
import { getStations, unloadStations } from "../../../store/slices/datasources/stationsSliceDatasource";
import { getMenuCategory, unloadMenuCategories } from "../../../store/slices/datasources/menuCategoriesSliceDatasource";
import WithLoadingPage from "../commons/withLoadingPage";
import Flash from "../elements/flash";
import PageSubHeader from "../elements/pageSubHeader";

const MenuCategoryForm = props => {
    
	const dispatch = useDispatch();
    const history = useHistory();
	const routeParams = useParams();
    const { id: menuCategoryId } = routeParams;
	const stations = useSelector(getStations);
	const menuCategory = useSelector(getMenuCategory(menuCategoryId));
	const { setLoading } = props;
	const [request, setRequest] = React.useState({ id: null, station_id: 0, code: "", name: "", description: "" });
	const [error, setError] = React.useState({ station_id: null, code: null, name: null });

    React.useEffect(() => {
        return () => {
            dispatch(unloadStations());
            dispatch(unloadMenuCategories());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {		
        async function initialize() {
            setLoading(true);
            await dispatch(fetchStations());
            if (parseInt(menuCategoryId)) {
                await dispatch(fetchMenuCategory(getQueryFilter()));
            }
            setLoading(false);
        }
		
        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, menuCategoryId]); 
	
	React.useEffect(() => {
		if (menuCategory) {
			const mapViewToModel = {
				id: menuCategory.id,
				station_id: menuCategory.station_id,
				code: menuCategory.code,
				name: menuCategory.name,
				description: menuCategory.description
			}

			setRequest(prev => { 
                return { 
                    ...prev, 
                    ...mapViewToModel 
                }
            });
		}
	}, [menuCategory]);

    const getQueryFilter = () => {
        const queryFilter = {
            id: menuCategoryId
        };

        return {
            query: queryFilter
        };
    }
    
	const handleInputChange = element => {
		const { name, value } = element.target;
		setRequest({...request, [name]: value});
	}

	const handleFormSubmit = async event => {
		event.preventDefault();
		setLoading(true);
		await dispatch(saveMenuCategory(request))
			.then(unwrapResult)
			.then(() => {
				setLoading(false);
				setError({ ...error, station_id: null, code: null, name: null });
				history.push('/data/menu-categories');
			})
			.catch(exception => {
				setLoading(false);
				setError(exception.errors);
			});
	}

    return (
        <React.Fragment>
            <PageSubHeader title={`${menuCategoryId === "add" ? "New" : "Edit"} Menu Category`} />
            <div className="mx-auto w-2/4">
                <Flash />
                <div className="classic card">
                    <div className="card-header bg-light-600">
                        <h3 className="card-title">Menu Category Form</h3>
                    </div>
                    <div className="card-body text-left">
                        <form className="w-full" onSubmit={handleFormSubmit}>
                            <div className="form-group">
                                <label className="form-label" htmlFor="station_id">Station</label>
                                <select id="station_id" name="station_id" className="form-control" value={request.station_id} onChange={handleInputChange}>
                                    <option value="">- Select -</option>
                                    {stations.map(station => <option key={station.id} value={station.id}>{station.name}</option>)}
                                </select>
                            </div>
                            <div className="form-group">
                                <div className="flex">
                                    <div className="w-1/2 mr-20">
                                        <label className="form-label" htmlFor="code">Code</label>
                                        <input id="code" name="code" type="text" className="form-control" value={request.code} onChange={handleInputChange} />
                                    </div>
                                    <div className="w-1/2 ml-2">
                                        <label className="form-label" htmlFor="name">Name</label>
                                        <input id="name" name="name" type="text" className="form-control" value={request.name} onChange={handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="form-label" htmlFor="description">Description</label>
                                <textarea id="description" name="description" type="text" className="form-control" value={request.description} onChange={handleInputChange} rows="5" resize="none" />
                            </div>
                            <div className="form-group text-right">
                                <NavLink to="/data/menu-categories" className="btn btn-secondary mr-4">
                                    <i className="las la-undo" />
                                    Back
                                </NavLink>
                                <button className="btn btn-primary mr-4">
                                    <i className="las la-save" />
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
 
export default WithLoadingPage(MenuCategoryForm);