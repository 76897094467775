import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { fetchMenus, fetchMenu, saveMenu, deleteMenu } from "../../thunks/menusThunk";

const dataAdapter = createEntityAdapter();
const dataView = dataAdapter.getSelectors(state => state.datasources.menus);
const menusSliceDatasource = createSlice({
	name: "datasources/menus",
	initialState: dataAdapter.getInitialState({
		pagination: {}
	}),
	reducers: {
		unloaded: (menus, _) => {
			dataAdapter.removeAll(menus, {});
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchMenus.fulfilled, (menus, action) => {
				if (action.payload.hasOwnProperty("pagination")) {
					menus.pagination = action.payload.pagination;
				}
				
				dataAdapter.setAll(menus, []);
				if (action.payload.hasOwnProperty("menus")) {
					dataAdapter.setAll(menus, action.payload.menus);
				}
			})
			.addCase(fetchMenu.fulfilled, (menu, action) => {
				if (action.payload.hasOwnProperty("menu")) {
					dataAdapter.upsertMany(menu, action.payload.menu);
				}
			})
			.addCase(saveMenu.fulfilled, (menu, action) => {
				dataAdapter.upsertOne(menu, action.payload.menu);
			})
			.addCase(deleteMenu.fulfilled, (menu, action) => {
				dataAdapter.removeOne(menu, action.payload.menu.id);
			});
	}
});
export default menusSliceDatasource.reducer;

export const unloadMenus = () => menusSliceDatasource.actions.unloaded();
export const getMenus = dataView.selectAll;
export const getMenuEntities = dataView.selectEntities;
export const getMenuPagination = createSelector(
	state => state.datasources.menus.pagination,
	pagination => pagination
);
export const getMenu = id => createSelector(
	state => state.datasources.menus.entities,
	menus => menus[id]
);
export const getMenuAdditionals = menuId => createSelector(
	state => dataView.selectById(state, menuId),
	state => state.datasources.menuAdditionals.ids.map(id => state.datasources.menuAdditionals.entities[id]),
	(menu, menuAdditionals) => {
		return Object.keys(menuAdditionals)
			.map(key => menuAdditionals[key])
			.filter(menuAdditional => menu.menu_additionals.includes(menuAdditional.id));
	}
);
export const getMenuIngredients = menuId => createSelector(
	state => dataView.selectById(state, menuId),
	state => state.datasources.menuIngredients.ids.map(id => state.datasources.menuIngredients.entities[id]),
	(menu, menuIngredients) => {
		return Object.keys(menuIngredients)
			.map(key => menuIngredients[key])
			.filter(menuIngredient => menu.menu_ingredients.includes(menuIngredient.id));
	}
);
export const getMenuOptions = menuId => createSelector(
	state => dataView.selectById(state, menuId),
	state => state.datasources.menuOptions.ids.map(id => state.datasources.menuOptions.entities[id]),
	(menu, menuOptions) => {
		return Object.keys(menuOptions)
			.map(key => menuOptions[key])
			.filter(menuOption => menu.menu_options.includes(menuOption.id));
	}
);
export const getMenuStoreBranhes = menuId => createSelector(
	state => dataView.selectById(state, menuId),
	state => state.datasources.menuStoreBranches.ids.map(id => state.datasources.menuStoreBranches.entities[id]),
	(menu, menuStoreBranches) => {
		return Object.keys(menuStoreBranches)
			.map(key => menuStoreBranches[key])
			.filter(menuStoreBranch => menu.menus_store_branches.includes(menuStoreBranch.id));
	}
);