import { createAsyncThunk } from "@reduxjs/toolkit";
import fsrHttpInstance, { fsrHttpConfiguration } from "../serviceFsrClients";
import { normalizeEntity } from "../entities/customerEntity";
import { setFlashSuccess, setFlashError } from "../slices/commons/flashSliceCommon";

export const fetchCustomers = createAsyncThunk(
	"datasources/customers/fetchCustomers",
	async (request, thunkAPI) => {
		try {
			const params = request ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.get("vendor/customers/index", {...params, ...fsrHttpConfiguration()}));
			const normalized = normalizeEntity(response.data.customers);

			return { 
				...normalized, 
				pagination: response.data.pagination || {}
			};
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The customer could not be loaded. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const fetchCustomer = createAsyncThunk(
	"datasources/customers/fetchCustomer",
	async (request, thunkAPI) => {
		try {
			const params = request ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.get("vendor/customers/view", {...params, ...fsrHttpConfiguration()}));
			const normalized = normalizeEntity(response.data.customer);

			return normalized;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The customer could not be loaded. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const saveCustomer = createAsyncThunk(
	"datasources/customers/saveCustomer",
	async (request, thunkAPI) => {
		try {
			const formData = { ...request };
			const action = formData.id ? "edit" : "add";
			const response = await fsrHttpInstance.then(fsrService => fsrService.post(`vendor/customers/${action}`, formData, fsrHttpConfiguration()));

			thunkAPI.dispatch(setFlashSuccess("The customer has been saved."));
			return response.data;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The customer could not be saved. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const deleteCustomer = createAsyncThunk(
	"datasources/customers/deleteCustomer",
	async (request, thunkAPI) => {
		try {
			const params = request.query ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.post("vendor/customers/delete", null, {...params, ...fsrHttpConfiguration()}));

			thunkAPI.dispatch(setFlashSuccess("The customer has been deleted."));
			return response.data;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The customer could not be deleted. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);