import { denormalize, normalize, schema } from "normalizr";

export const normalizeEntity = (datasources) => {
	const isArray = datasources instanceof Array;
	const dataset = isArray ? "menus" : "menu";
	const menuCategoryEntity = new schema.Entity("menuCategory");
	const menuAdditionalsEntity = new schema.Entity("menuAdditionals");
	const menuIngredientsEntity = new schema.Entity("menuIngredients");
	const menuOptionsEntity = new schema.Entity("menuOptions");
	const menuStoreBranchesEntity = new schema.Entity("menuStoreBranches");
	const menuEntity = new schema.Entity(
		dataset,
		{ 
			menu_category: menuCategoryEntity,
			menu_additionals: [menuAdditionalsEntity],
			menu_ingredients: [menuIngredientsEntity],
			menu_options: [menuOptionsEntity],
			menus_store_branches: [menuStoreBranchesEntity],
		}
	);
	const result = normalize(datasources, isArray ? [menuEntity] : menuEntity);
	
	return result.entities;
}

export const denormalizeEntity = (datasources) => {
	const isArray = datasources.menus;
	const dataset = isArray ? "menus" : "menu";
	const menuEntity = new schema.Entity(dataset)
	const entities = isArray ? datasources.menus : datasources.menu;

	return denormalize(
		{ [dataset]: isArray ? Object.keys(entities) : Object.keys(entities).toString() }, 
		{ [dataset]: isArray ? [menuEntity] : menuEntity }, 
		{ [dataset]: entities }
	);
}