import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { fetchIngredients, fetchIngredient, saveIngredient, deleteIngredient } from "../../thunks/ingredientsThunk";

const dataAdapter = createEntityAdapter();
const dataView = dataAdapter.getSelectors(state => state.datasources.ingredients);
const ingredientsSliceDatasource = createSlice({
	name: "datasources/ingredients",
	initialState: dataAdapter.getInitialState({
		pagination: {}
	}),
	reducers: {
		unloaded: (ingredients, _) => {
			dataAdapter.removeAll(ingredients, {});
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchIngredients.fulfilled, (ingredients, action) => {
				if (action.payload.hasOwnProperty("pagination")) {
					ingredients.pagination = action.payload.pagination;
				}
				
				if (action.payload.hasOwnProperty("ingredients")) {
					dataAdapter.setAll(ingredients, action.payload.ingredients);
				}
			})
			.addCase(fetchIngredient.fulfilled, (ingredient, action) => {
				if (action.payload.hasOwnProperty("ingredient")) {
					dataAdapter.upsertMany(ingredient, action.payload.ingredient);
				}
			})
			.addCase(saveIngredient.fulfilled, (ingredient, action) => {
				dataAdapter.upsertOne(ingredient, action.payload.ingredient);
			})
			.addCase(deleteIngredient.fulfilled, (ingredient, action) => {
				dataAdapter.removeOne(ingredient, action.payload.ingredient.id);
			});
	}
});
export default ingredientsSliceDatasource.reducer;

export const unloadIngredients = () => ingredientsSliceDatasource.actions.unloaded();
export const getIngredients = dataView.selectAll;
export const getIngredientEntities = dataView.selectEntities;
export const getIngredientPagination = createSelector(
	state => state.datasources.ingredients.pagination,
	pagination => pagination
);
export const getIngredient = id => createSelector(
	state => state.datasources.ingredients.entities,
	ingredients => ingredients[id]
);