import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { unwrapResult } from '@reduxjs/toolkit'
import { fetchPaymentCategories } from "../../../store/thunks/paymentCategoriesThunk";
import { fetchPayment, savePayment } from "../../../store/thunks/paymentsThunk";
import { getPaymentCategories, unloadPaymentCategories } from "../../../store/slices/datasources/paymentCategoriesSliceDatasource";
import { getPayment, unloadPayments } from "../../../store/slices/datasources/paymentsSliceDatasource";
import WithLoadingPage from "../commons/withLoadingPage";
import Flash from "../elements/flash";
import PageSubHeader from "../elements/pageSubHeader";

const PaymentForm = props => {
    
	const dispatch = useDispatch();
    const history = useHistory();
	const routeParams = useParams();
    const { id: paymentId } = routeParams;
    const paymentCategories = useSelector(getPaymentCategories);
	const payment = useSelector(getPayment(paymentId));
	const { setLoading } = props;
	const [request, setRequest] = React.useState({ id: null, payment_category_id: 0, name: "", description: "", payment_model: 0 });
	const [error, setError] = React.useState({ payment_category_id: null, name: null });

    React.useEffect(() => {
        return () => {
            dispatch(unloadPaymentCategories());
            dispatch(unloadPayments());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        async function initialize() {
            setLoading(true)
            await dispatch(fetchPaymentCategories());
            if (parseInt(paymentId)) {
                await dispatch(fetchPayment(getQueryFilter()));
            }
            setLoading(false);
        }
        
        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, paymentId]); 
	
	React.useEffect(() => {
		if (payment) {
			const mapViewToModel = {
				id: payment.id,
                payment_category_id: payment.payment_category_id,
				name: payment.name,
				description: payment.description,
				payment_model: payment.payment_model,
			}

			setRequest(prev => { 
                return { 
                    ...prev, 
                    ...mapViewToModel 
                }
            });
		}
	}, [payment]);

    const getQueryFilter = () => {
        const queryFilter = {
            id: paymentId
        };

        return {
            query: queryFilter
        };
    }

	const handleInputChange = element => {
		const { name, value } = element.target;
		setRequest({...request, [name]: value});
	}

	const handleFormSubmit = async event => {
		event.preventDefault();
		setLoading(true);
		await dispatch(savePayment(request))
			.then(unwrapResult)
			.then(() => {
				setLoading(false);
				setError({ ...error, payment_category_id: null, name: null });
				history.push('/data/payments');
			})
			.catch(exception => {
				setLoading(false);
				setError(exception.errors);
			});
	}

    return (
        <React.Fragment>
            <PageSubHeader title={`${paymentId === "add" ? "New" : "Edit"} Payment`} />
            <div className="mx-auto w-2/4">
                <Flash />
                <div className="classic card">
                    <div className="card-header bg-light-600">
                        <h3 className="card-title">Payment Form</h3>
                    </div>
                    <div className="card-body text-left">
                        <form className="w-full" onSubmit={handleFormSubmit}>
                            <div className="form-group">
                                <label className="form-label" htmlFor="payment_category_id">Category</label>
                                <select id="payment_category_id" name="payment_category_id" className="form-control" value={request.payment_category_id} onChange={handleInputChange}>
                                    <option value="">- Select -</option>
                                    {paymentCategories.map(paymentCategory => <option key={paymentCategory.id} value={paymentCategory.id}>{paymentCategory.name}</option>)}
                                </select>
                            </div>
                            <div className="form-group">
                                <label className="form-label" htmlFor="name">Name</label>
                                <input id="name" name="name" type="text" className="form-control" value={request.name} onChange={handleInputChange} />
                            </div>
                            <div className="form-group">
                                <label className="form-label" htmlFor="description">Description</label>
                                <textarea id="description" name="description" type="text" className="form-control" value={request.description} onChange={handleInputChange} rows="5" resize="none" />
                            </div>
                            <div className="form-group">
                                <label className="form-label" htmlFor="payment_model">Payment Model</label>
                                <select id="payment_model" name="payment_model" className="form-control" value={request.payment_model} onChange={handleInputChange}>
                                    <option value="0">- Select -</option>
                                    <option value="1">Cash</option>
                                    <option value="2">Card</option>
                                    <option value="3">Other</option>
                                </select>
                            </div>
                            <div className="form-group text-right">
                                <NavLink to="/data/payments" className="btn btn-secondary mr-4">
                                    <i className="las la-undo" />
                                    Back
                                </NavLink>
                                <button className="btn btn-primary mr-4">
                                    <i className="las la-save" />
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
 
export default WithLoadingPage(PaymentForm);