import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { fetchMenu } from "../../thunks/menusThunk";

const dataAdapter = createEntityAdapter();
const menuAdditionalsSliceDatasource = createSlice({
	name: "datasources/menuAdditionals",
	initialState: dataAdapter.getInitialState(),
	reducers: {
		unloaded: (menuAdditionals, _) => {
			dataAdapter.removeAll(menuAdditionals, {});
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchMenu.fulfilled, (menuAdditionals, action) => {
				if (action.payload.hasOwnProperty("menuAdditionals")) {
					dataAdapter.setAll(menuAdditionals, action.payload.menuAdditionals || []);
				}
			});
	}
});
export default menuAdditionalsSliceDatasource.reducer;

export const unloadMenuAdditionals = () => menuAdditionalsSliceDatasource.actions.unloaded();