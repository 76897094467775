import { denormalize, normalize, schema } from "normalizr";

export const normalizeEntity = (datasources) => {
	const isArray = datasources instanceof Array;
	const dataset = isArray ? "diningTables" : "diningTable";
	const diningTableSectionEntity = new schema.Entity("diningTableSection");
	const diningTableEntity = new schema.Entity(
		dataset, 
		{ dining_table_section: diningTableSectionEntity }
	);
	const result = normalize(
		datasources, 
		isArray ? [diningTableEntity] : diningTableEntity
	);

	return result.entities;
}

export const denormalizeEntity = (datasources) => {
	const isArray = datasources.diningTables;
	const dataset = isArray ? "diningTables" : "diningTable";
	const diningTableEntity = new schema.Entity(dataset)
	const entities = isArray ? datasources.diningTables : datasources.diningTable;

	return denormalize(
		{ [dataset]: isArray ? Object.keys(entities) : Object.keys(entities).toString() }, 
		{ [dataset]: isArray ? [diningTableEntity] : diningTableEntity }, 
		{ [dataset]: entities }
	);
}