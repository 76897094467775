import { createAsyncThunk } from "@reduxjs/toolkit";
import fsrHttpInstance, { fsrHttpConfiguration } from "../serviceFsrClients";
import { normalizeEntity } from "../entities/ingredientEntity";
import { setFlashSuccess, setFlashError } from "../slices/commons/flashSliceCommon";

export const fetchIngredients = createAsyncThunk(
	"datasources/ingredients/fetchIngredients",
	async (request, thunkAPI) => {
		try {
			const params = request ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.get("vendor/ingredients/index", {...params, ...fsrHttpConfiguration()}));
			const normalized = normalizeEntity(response.data.ingredients);

			return { 
				...normalized, 
				pagination: response.data.pagination || {} 
			};
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The ingredient could not be loaded. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const fetchIngredient = createAsyncThunk(
	"datasources/ingredients/fetchIngredient",
	async (request, thunkAPI) => {
		try {
			const params = request ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.get("vendor/ingredients/view", {...params, ...fsrHttpConfiguration()}));
			const normalized = normalizeEntity(response.data.ingredient);
			
			return normalized;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The ingredient could not be loaded. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const saveIngredient = createAsyncThunk(
	"datasources/ingredients/saveIngredient",
	async (request, thunkAPI) => {
		try {
			const formData = { ...request };
			const action = formData.id ? "edit" : "add";
			const response = await fsrHttpInstance.then(fsrService => fsrService.post(`vendor/ingredients/${action}`, formData, fsrHttpConfiguration()));

			thunkAPI.dispatch(setFlashSuccess("The ingredient has been saved."));
			return response.data;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The ingredient could not be saved. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const deleteIngredient = createAsyncThunk(
	"datasources/ingredients/deleteIngredient",
	async (request, thunkAPI) => {
		try {
			const params = request.query ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.post("vendor/ingredients/delete", null, {...params, ...fsrHttpConfiguration()}));

			thunkAPI.dispatch(setFlashSuccess("The ingredient has been deleted."));
			return response.data;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The ingredient could not be deleted. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);