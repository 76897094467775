import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { fetchMenu } from "../../thunks/menusThunk";

const dataAdapter = createEntityAdapter();
const menuOptionsSliceDatasource = createSlice({
	name: "datasources/menuOptions",
	initialState: dataAdapter.getInitialState(),
	reducers: {
		unloaded: (menuOptions, _) => {
			dataAdapter.removeAll(menuOptions, {});
		}
	},
	extraReducers: build => {
		build
			.addCase(fetchMenu.fulfilled, (menuOptions, action) => {
				if (action.payload.hasOwnProperty("menuOptions")) {
					dataAdapter.setAll(menuOptions, action.payload.menuOptions || []);
				}
			});
	}
});
export default menuOptionsSliceDatasource.reducer;

export const unloadMenuOptions = () => menuOptionsSliceDatasource.actions.unloaded();