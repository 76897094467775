import { denormalize, normalize, schema } from "normalizr";

export const normalizeEntity = (datasources) => {
	const isArray = datasources instanceof Array;
	const dataset = isArray ? "salesOrders" : "salesOrder";
	const diningTableEntity = new schema.Entity("diningTable");
	const waiterEntity = new schema.Entity("waiter");
	const customerEntity = new schema.Entity("customer");
	const menuEntity = new schema.Entity("menu");
	const salesOrderDetailsEntity = new schema.Entity(
		"salesOrderDetails",
		{ menu: menuEntity }
	);
	const salesOrderBatchesEntity = new schema.Entity(
		"salesOrderBatches",
		{ sales_order_details: [salesOrderDetailsEntity] }
	);
	const salesOrderEntity = new schema.Entity(
		dataset,
		{ 
			dining_table: diningTableEntity,
			waiter: waiterEntity,
			customer: customerEntity,
			sales_order_batches: [salesOrderBatchesEntity],
			sales_order_details: [salesOrderDetailsEntity],
		}
	);
	const result = normalize(
		datasources, 
		isArray ? [salesOrderEntity] : salesOrderEntity
	);
	
	return result.entities;
}

export const denormalizeEntity = (datasources) => {
	const isArray = datasources.salesOrders;
	const dataset = isArray ? "salesOrders" : "salesOrder";
	const salesOrderEntity = new schema.Entity(dataset)
	const entities = isArray ? datasources.salesOrders : datasources.salesOrder;

	return denormalize(
		{ [dataset]: isArray ? Object.keys(entities) : Object.keys(entities).toString() }, 
		{ [dataset]: isArray ? [salesOrderEntity] : salesOrderEntity }, 
		{ [dataset]: entities }
	);
}