import { createEntityAdapter, createSelector, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { fetchPayments, fetchPayment } from "../../thunks/paymentsThunk";
import { fetchPaymentCategories, fetchPaymentCategory, savePaymentCategory, deletePaymentCategory } from "../../thunks/paymentCategoriesThunk";

const dataAdapter = createEntityAdapter();
const dataView = dataAdapter.getSelectors(state => state.datasources.paymentCategories);
const paymentCategoriesSliceDatasource = createSlice({
	name: "datasources/paymentCategories",
	initialState: dataAdapter.getInitialState({
		pagination: {}
	}),
	reducers: {
		unloaded: (paymentCategories, _) => {
			dataAdapter.removeAll(paymentCategories, {});
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchPaymentCategories.fulfilled, (paymentCategories, action) => {
				if (action.payload.hasOwnProperty("pagination")) {
					paymentCategories.pagination = action.payload.pagination;
				}
	
				if (action.payload.hasOwnProperty("paymentCategories")) {
					dataAdapter.setAll(paymentCategories, action.payload.paymentCategories);
				}
			})
			.addCase(fetchPaymentCategory.fulfilled, (paymentCategory, action) => {
				if (action.payload.hasOwnProperty("paymentCategory")) {
					dataAdapter.upsertMany(paymentCategory, action.payload.paymentCategory);
				}
			})
			.addCase(savePaymentCategory.fulfilled, (paymentCategory, action) => {
				dataAdapter.upsertOne(paymentCategory, action.payload.paymentCategory);
			})
			.addCase(deletePaymentCategory.fulfilled, (paymentCategory, action) => {
				dataAdapter.removeOne(paymentCategory, action.payload.paymentCategory.id);
			})
			.addMatcher(isAnyOf(fetchPayments.fulfilled, fetchPayment.fulfilled), (paymentCategory, action) => {
				if (action.payload.hasOwnProperty("paymentCategory")) {
					dataAdapter.setAll(paymentCategory, action.payload.paymentCategory);
				}
			});
	}
});
export default paymentCategoriesSliceDatasource.reducer;

export const unloadPaymentCategories = () => paymentCategoriesSliceDatasource.actions.unloaded();
export const getPaymentCategories = dataView.selectAll;
export const getPaymentCategoryEntities = dataView.selectEntities;
export const getPaymentCategoryPagination = createSelector(
	state => state.datasources.paymentCategories.pagination,
	pagination => pagination
);
export const getPaymentCategory = id => createSelector(
	state => state.datasources.paymentCategories.entities,
	paymentCategories => paymentCategories[id]
);