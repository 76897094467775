import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { unwrapResult } from '@reduxjs/toolkit'
import { fetchStore, saveStore } from "../../../store/thunks/storesThunk";
import { getStore, getStoreDocumentSettings, unloadStores } from "../../../store/slices/datasources/storesSliceDatasource";
import { unloadStoreDocumentSettings } from "../../../store/slices/datasources/storeDocumentSettingsSliceDatasource";
import { getCurrentStore } from "../../helpers/authentication";
import WithLoadingPage from "../commons/withLoadingPage";
import Flash from "../elements/flash";
import PageSubHeader from "../elements/pageSubHeader";

const StoreForm = props => {
    
	const dispatch = useDispatch();
    const history = useHistory();
    const { id: storeId } = getCurrentStore();
	const store = useSelector(getStore(storeId));
	const storeDocumentSettings = useSelector(getStoreDocumentSettings(storeId));
	const { setLoading } = props;
	const [request, setRequest] = React.useState({ id: null, name: "", address_line_1: "", address_line_2: "", phone: "", store_document_settings: [] });
	const [error, setError] = React.useState({ name: null, address_line_1: null, phone: null });
    const [microtime, setMicrotime] = React.useState([]);

    React.useEffect(() => {
        return () => {
            dispatch(unloadStoreDocumentSettings());
            dispatch(unloadStores());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {		
        async function initialize() {
            setLoading(true);
            if (parseInt(storeId)) {
                await dispatch(fetchStore(getQueryFilter()));
            }
            setLoading(false);
        }
		
        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, storeId]); 
	
	React.useEffect(() => {
		if (store) {
			const mapViewToModel = {
				id: store.id,
				code: store.code,
				name: store.name,
				store_document_settings: mapStoreDocumentSettings()
			}

			setRequest(prev => { 
                return { 
                    ...prev, 
                    ...mapViewToModel 
                }
            });
		}
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [store]);

    const getQueryFilter = () => {
        const queryFilter = {
            withStoreDocumentSettings: true,
        };

        return {
            query: queryFilter
        };
    }

	const mapStoreDocumentSettings = () => {
		const result = [];
        
		if (storeDocumentSettings) {
            storeDocumentSettings.forEach((storeDocumentSetting, row) => {
                setMicrotime(prev => {
                    return {
                        ...prev,
                        [row]: storeDocumentSetting.microtime === 1
                    }
                });
				result.push({
					id: storeDocumentSetting.id, 
					document: storeDocumentSetting.document, 
					microtime: storeDocumentSetting.microtime,
					reset_mode: storeDocumentSetting.reset_mode,
					prefix: storeDocumentSetting.prefix,
					digit: storeDocumentSetting.digit,
					suffix: storeDocumentSetting.suffix,
				});
            });
		}
        
		return result;
	}

	const handleInputChange = element => {
		const { dataset, name, value } = element.target;
		const details = request.store_document_settings;
		details[dataset.id][name] = value
		setRequest({ 
            ...request, 
            store_document_settings: [...details] 
        });

        if (name === "microtime") {
            setMicrotime({ ...microtime, [dataset.id]: value === "1" });
            details[dataset.id].reset_mode = 0;
            details[dataset.id].prefix = "";
            details[dataset.id].digit = 0;
            details[dataset.id].suffix = "";
        }
	}

	const handleFormSubmit = async event => {
		event.preventDefault();
		setLoading(true);
		await dispatch(saveStore(request))
			.then(unwrapResult)
			.then(() => {
				setLoading(false);
				setError({ ...error, name: null, address_line_1: null, phone: null });
				history.push('/');
			})
			.catch(exception => {
				setLoading(false);
				setError(exception.errors);
			});
	}

    return (
        <React.Fragment>
            <PageSubHeader title="Configure Store" />
            <div className="mx-auto w-2/4">
                <Flash />
                <div className="classic card">
                    <div className="card-header bg-light-600">
                        <h3 className="card-title">Configuration {request.name}</h3>
                    </div>
                    <div className="card-body text-left">
                        <form className="w-full" onSubmit={handleFormSubmit}>
                            <div className="form-group">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th className="text-left">Document</th>
                                            <th className="text-left" width="15%">Random</th>
                                            <th className="text-left" width="15%">Reset Mode</th>
                                            <th className="text-left" width="15%">Prefix</th>
                                            <th className="text-left" width="15%">Digit</th>
                                            <th className="text-left" width="15%">Suffix</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {request.store_document_settings.map((storeDocumentSetting, row) => 
                                            <tr key={row}>
                                                <td>
                                                    {storeDocumentSetting.document}
                                                </td>
                                                <td>
                                                    <select id="microtime" name="microtime" className="form-control" value={storeDocumentSetting.microtime} data-id={row} onChange={handleInputChange}>
                                                        <option value="0">Predicted</option>
                                                        <option value="1">Random</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <select id="reset_mode" name="reset_mode" className="form-control" value={storeDocumentSetting.reset_mode} data-id={row} onChange={handleInputChange} hidden={microtime[row]}>
                                                        <option value="0">- Select -</option>
                                                        <option value="1">Daily</option>
                                                        <option value="2">Monthly</option>
                                                        <option value="3">Yearly</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <input id="prefix" name="prefix" type="text" className="form-control" value={storeDocumentSetting.prefix} data-id={row} onChange={handleInputChange} hidden={microtime[row]} />
                                                </td>
                                                <td>
                                                    <input id="digit" name="digit" type="text" className="form-control" value={storeDocumentSetting.digit} data-id={row} onChange={handleInputChange} hidden={microtime[row]} />
                                                </td>
                                                <td>
                                                    <input id="suffix" name="suffix" type="text" className="form-control" value={storeDocumentSetting.suffix} data-id={row} onChange={handleInputChange} hidden={microtime[row]} />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div className="form-group text-right">
                                <NavLink to="/" className="btn btn-secondary mr-4">
                                    <i className="las la-undo" />
                                    Back
                                </NavLink>
                                <button className="btn btn-primary mr-4">
                                    <i className="las la-save" />
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
 
export default WithLoadingPage(StoreForm);