import React from "react";
import { useDispatch } from "react-redux";
import { NavLink, Redirect, useHistory } from "react-router-dom";
import { unwrapResult } from '@reduxjs/toolkit'
import { authorize } from "../../../store/thunks/vendorsThunk";
import { isAuthorized } from "../../helpers/authentication";
import WithLoadingPage from "../commons/withLoadingPage";
import Flash from "../elements/flash";

const Login = props => {
    
	const dispatch = useDispatch();
    const history = useHistory();
	const { setLoading } = props;
	const [request, setRequest] = React.useState({ email: "", password: "" });
	const [showPassword, setShowPassword] = React.useState(false);

    const handleInputChange = element => {
		const { name, value } = element.target;
		setRequest({...request, [name]: value});
	}

	const handleFormSubmit = async event => {
		event.preventDefault();
		setLoading(true);
		await dispatch(authorize(request))
			.then(unwrapResult)
			.then(() => {
				setLoading(false);
				history.replace('/my-stores');
			}).catch(() => {
				setLoading(false);
			});;
	}

    if (isAuthorized()) return <Redirect to="/dashboard" />;
    return (  
        <React.Fragment>
            <div className="flex flex-row flex-grow justify-between">
                <div className="flex cover"></div>
                <div className="flex w-1/4">
                    <div className="authentication-page pt-80 px-48">
                        <div className="text-center">
                            <img src={require("../../assets/images/logo.png").default} alt="logo" />
                        </div>
                        <h5 className="mb-0 mt-16">Welcome to Vendor FSR!</h5>
				        <p className="font-size-13 text-muted mb-16">Login to manage dashboard and managing your restaurant!</p>
                        <Flash />
                        <form className="mt-20" onSubmit={handleFormSubmit}>
                            <div className="form-group">
                                <label className="form-label block" htmlFor="email">Your email address</label>
                                <input id="email" name="email" type="email" className="form-control" value={request.email} onChange={handleInputChange} placeholder="Enter your email address here" />
                            </div>
                            <div className="form-group">
                                <label className="form-label block" htmlFor="password">Password</label>
                                <div className="input-icon input-icon-right">
                                    <input id="password" name="password" type={showPassword ? "text" : "password"} className="form-control input-group-item" value={request.password} onChange={handleInputChange} placeholder="Enter your password here" />
                                    <span>
                                        <i className={`${showPassword ? "la la-eye" : "la la-eye-slash"} cursor-pointer text-xl leading-none text-gray-600 dark:text-gray-600"`} onClick={() => setShowPassword(!showPassword)} />
                                    </span>
                                </div>
                                <div className="text-right">
                                    <NavLink to="/forgot-password" className="text-12">
                                        Forgot your password ?
                                    </NavLink>
                                </div>
                            </div>
                            <div className="row mt-40">
                                <button className="btn btn-primary mr-8 uppercase">Login</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
 
export default WithLoadingPage(Login);