import { createAsyncThunk } from "@reduxjs/toolkit";
import fsrHttpInstance, { fsrHttpConfiguration } from "../serviceFsrClients";
import { normalizeEntity } from "../entities/customerCategoryEntity";
import { setFlashSuccess, setFlashError } from "../slices/commons/flashSliceCommon";

export const fetchCustomerCategories = createAsyncThunk(
	"datasources/customerCategories/fetchCustomerCategories",
	async (request, thunkAPI) => {
		try {
			const params = request ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.get("vendor/customer-categories/index", {...params, ...fsrHttpConfiguration()}));
			const normalized = normalizeEntity(response.data.customerCategories);
			
			return { 
				...normalized, 
				pagination: response.data.pagination || {}
			};
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The customer category could not be loaded. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const fetchCustomerCategory = createAsyncThunk(
	"datasources/customerCategories/fetchCustomerCategory",
	async (request, thunkAPI) => {
		try {
			const params = request ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.get("vendor/customer-categories/view", {...params, ...fsrHttpConfiguration()}));
			const normalized = normalizeEntity(response.data.customerCategory);

			return normalized;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The customer category could not be loaded. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const saveCustomerCategory = createAsyncThunk(
	"datasources/customerCategories/saveCustomerCategory",
	async (request, thunkAPI) => {
		try {
			const formData = { ...request };
			const action = formData.id ? "edit" : "add";
			const response = await fsrHttpInstance.then(fsrService => fsrService.post(`vendor/customer-categories/${action}`, formData, fsrHttpConfiguration()));

			thunkAPI.dispatch(setFlashSuccess("The customer category section has been saved."));
			return response.data;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The customer category could not be saved. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const deleteCustomerCategory = createAsyncThunk(
	"datasources/customerCategories/deleteCustomerCategory",
	async (request, thunkAPI) => {
		try {
			const params = request.query ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.post("vendor/customer-categories/delete", null, {...params, ...fsrHttpConfiguration()}));

			thunkAPI.dispatch(setFlashSuccess("The customer category has been deleted."));
			return response.data;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The customer category could not be deleted. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);