import { NavLink } from "react-router-dom";

const DataTable = ({ storeBranches }) => {

    return (
        <table className="table table-striped">
            <thead>
                <tr>
                    <th className="text-left" width="10%">Code</th>
                    <th className="text-left" width="20%">Name</th>
                    <th className="text-left">Address</th>
                    <th className="text-left" width="10%">Phone</th>
                    <th className="text-left" width="5%">Status</th>
                    <th className="text-left" width="1%"/>
                </tr>
            </thead>
            <tbody>
                {storeBranches.map(storeBranch => 
					<tr key={storeBranch.id}>
						<td>{storeBranch.code}</td>
						<td>{storeBranch.name}</td>
						<td>
                            {storeBranch.address_line_1} 
                            <br />
                            {storeBranch.address_line_2} 
                        </td>
						<td>{storeBranch.phone}</td>
						<td>{storeBranch.is_verified === 0 ? "Pending" : "Verified"}</td>
						<td className="text-right">
							<NavLink to={`/stores/branches/${storeBranch.id}`} className="btn btn-primary btn-icon">
                                <span className="las la-pen" />
                            </NavLink>
                        </td>
					</tr>
				)}
            </tbody>
        </table>
    );
}
 
export default DataTable;