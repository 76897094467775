import { denormalize, normalize, schema } from "normalizr";

export const normalizeEntity = (datasources) => {
	const isArray = datasources instanceof Array;
	const dataset = isArray ? "stations" : "station";
	const stationEntity = new schema.Entity(dataset);
	const result = normalize(
		datasources, 
		isArray ? [stationEntity] : stationEntity
	);
	
	return result.entities;
}

export const denormalizeEntity = (datasources) => {
	const isArray = datasources.stations;
	const dataset = isArray ? "stations" : "station";
	const stationEntity = new schema.Entity(dataset)
	const entities = isArray ? datasources.stations : datasources.station;

	return denormalize(
		{ [dataset]: isArray ? Object.keys(entities) : Object.keys(entities).toString() }, 
		{ [dataset]: isArray ? [stationEntity] : stationEntity }, 
		{ [dataset]: entities }
	);
}