const Paginator = ({pagination, handlePageChange}) => {

    const paginatePrev = () => {    
        return (
            <button className="btn btn-round mr-2 mb-2 lg:mb-0 btn-outlined btn-secondary" onClick={() => handlePageChange(pagination.currentPage - 1)}>
                <i className="las la-angle-left" />
            </button>
        );
    }

    const paginateNumber = () => {
        const items = [];
        for (let page = 1; page <= pagination.totalPage; page++) {
            items.push(
                <button key={page} className={`btn btn-round mr-2 mb-2 lg:mb-0 ${pagination.currentPage === page ? "btn-primary" : "btn-outlined btn-secondary"}`} onClick={() => handlePageChange(page)}>
                    {page}
                </button>
            );
        }
        
        return items;
    }

    const paginateNext = () => {    
        return (
            <button className="btn btn-round mr-2 mb-2 lg:mb-0 btn-outlined btn-secondary" onClick={() => handlePageChange(pagination.currentPage + 1)}>
                <i className="las la-angle-right" />
            </button>
        );
    }

    return ( 
        <nav className="flex flex-wrap p-5">
            { pagination.hasPrev && paginatePrev() }
            { pagination.totalPage > 1 && paginateNumber() }
            { pagination.hasNext && paginateNext() }
        </nav>
    );
}
 
export default Paginator;