import Main from "../resources/templates/layouts/main";
import Authentication from "../resources/templates/layouts/authentication";

import Login from "../resources/templates/vendors/login";
import ForgotPassword from "../resources/templates/vendors/forgotPassword";
import StoreForm from "../resources/templates/stores/form";
import Stores from "../resources/templates/stores/index";
import Dashboards from "../resources/templates/dashboards";
import StoreBranchForm from "../resources/templates/storeBranches/form";
import StoreBranches from "../resources/templates/storeBranches/index";
import TaxForm from "../resources/templates/taxes/form";
import Taxes from "../resources/templates/taxes/index";
import VoidReasonForm from "../resources/templates/voidReasons/form";
import VoidReasons from "../resources/templates/voidReasons/index";
import WaiterForm from "../resources/templates/waiters/form";
import Waiters from "../resources/templates/waiters/index";
import DiningTableSectionForm from "../resources/templates/diningTableSections/form";
import DiningTableSections from "../resources/templates/diningTableSections/index";
import DiningTableForm from "../resources/templates/diningTables/form";
import DiningTables from "../resources/templates/diningTables/index";
import UnitOfMeasureForm from "../resources/templates/unitOfMeasures/form";
import UnitOfMeasures from "../resources/templates/unitOfMeasures/index";
import IngredientForm from "../resources/templates/ingredients/form";
import Ingredients from "../resources/templates/ingredients/index";
import StationForm from "../resources/templates/stations/form";
import Stations from "../resources/templates/stations/index";
import MenuCategoryForm from "../resources/templates/menuCategories/form";
import MenuCategories from "../resources/templates/menuCategories/index";
import MenuForm from "../resources/templates/menus/form";
import Menus from "../resources/templates/menus/index";
import PaymentCategoryForm from "../resources/templates/paymentCategories/form";
import PaymentCategories from "../resources/templates/paymentCategories/index";
import PaymentForm from "../resources/templates/payments/form";
import Payments from "../resources/templates/payments/index";
import CustomerCategoryForm from "../resources/templates/customerCategories/form";
import CustomerCategories from "../resources/templates/customerCategories/index";
import CustomerForm from "../resources/templates/customers/form";
import Customers from "../resources/templates/customers/index";
import ReportSalesInvoices from "../resources/templates/reports/salesInvoices";
import ReportSalesMenus from "../resources/templates/reports/salesMenus";

export const routes = [
	{ path: "/login", layout: Authentication, component: Login },
	{ path: "/forgot-password", layout: Authentication, component: ForgotPassword },
	{ path: "/my-stores", layout: Authentication, component: Stores },

	{ path: "/dashboard", layout: Main, component: Dashboards, authorizedRequired: true, selectedStoreRequired: true },
	
	{ path: "/stores/configuration", layout: Main, component: StoreForm, authorizedRequired: true, selectedStoreRequired: true },
	{ path: "/stores/branches/:id", layout: Main, component: StoreBranchForm, authorizedRequired: true, selectedStoreRequired: true },
	{ path: "/stores/branches", layout: Main, component: StoreBranches, authorizedRequired: true, selectedStoreRequired: true },

	{ path: "/data/taxes/:id", layout: Main, component: TaxForm, authorizedRequired: true, selectedStoreRequired: true },
	{ path: "/data/taxes", layout: Main, component: Taxes, authorizedRequired: true, selectedStoreRequired: true },
	{ path: "/data/void-reasons/:id", layout: Main, component: VoidReasonForm, authorizedRequired: true, selectedStoreRequired: true },
	{ path: "/data/void-reasons", layout: Main, component: VoidReasons, authorizedRequired: true, selectedStoreRequired: true },
	{ path: "/data/waiters/:id", layout: Main, component: WaiterForm, authorizedRequired: true, selectedStoreRequired: true },
	{ path: "/data/waiters", layout: Main, component: Waiters, authorizedRequired: true, selectedStoreRequired: true },
	{ path: "/data/dining-table-sections/:id", layout: Main, component: DiningTableSectionForm, authorizedRequired: true, selectedStoreRequired: true },
	{ path: "/data/dining-table-sections", layout: Main, component: DiningTableSections, authorizedRequired: true, selectedStoreRequired: true },
	{ path: "/data/dining-tables/:id", layout: Main, component: DiningTableForm, authorizedRequired: true, selectedStoreRequired: true },
	{ path: "/data/dining-tables", layout: Main, component: DiningTables, authorizedRequired: true, selectedStoreRequired: true },
	{ path: "/data/unit-of-measures/:id", layout: Main, component: UnitOfMeasureForm, authorizedRequired: true, selectedStoreRequired: true },
	{ path: "/data/unit-of-measures", layout: Main, component: UnitOfMeasures, authorizedRequired: true, selectedStoreRequired: true },
	{ path: "/data/ingredients/:id", layout: Main, component: IngredientForm, authorizedRequired: true, selectedStoreRequired: true },
	{ path: "/data/ingredients", layout: Main, component: Ingredients, authorizedRequired: true, selectedStoreRequired: true },
	{ path: "/data/stations/:id", layout: Main, component: StationForm, authorizedRequired: true, selectedStoreRequired: true },
	{ path: "/data/stations", layout: Main, component: Stations, authorizedRequired: true, selectedStoreRequired: true },
	{ path: "/data/menu-categories/:id", layout: Main, component: MenuCategoryForm, authorizedRequired: true, selectedStoreRequired: true },
	{ path: "/data/menu-categories", layout: Main, component: MenuCategories, authorizedRequired: true, selectedStoreRequired: true },
	{ path: "/data/menus/:id", layout: Main, component: MenuForm, authorizedRequired: true, selectedStoreRequired: true },
	{ path: "/data/menus", layout: Main, component: Menus, authorizedRequired: true, selectedStoreRequired: true },
	{ path: "/data/payment-categories/:id", layout: Main, component: PaymentCategoryForm, authorizedRequired: true, selectedStoreRequired: true },
	{ path: "/data/payment-categories", layout: Main, component: PaymentCategories, authorizedRequired: true, selectedStoreRequired: true },
	{ path: "/data/payments/:id", layout: Main, component: PaymentForm, authorizedRequired: true, selectedStoreRequired: true },
	{ path: "/data/payments", layout: Main, component: Payments, authorizedRequired: true, selectedStoreRequired: true },
	{ path: "/data/customer-categories/:id", layout: Main, component: CustomerCategoryForm, authorizedRequired: true, selectedStoreRequired: true },
	{ path: "/data/customer-categories", layout: Main, component: CustomerCategories, authorizedRequired: true, selectedStoreRequired: true },
	{ path: "/data/customers/:id", layout: Main, component: CustomerForm, authorizedRequired: true, selectedStoreRequired: true },
	{ path: "/data/customers", layout: Main, component: Customers, authorizedRequired: true, selectedStoreRequired: true },

	{ path: "/report/sales-invoices", layout: Main, component: ReportSalesInvoices, authorizedRequired: true, selectedStoreRequired: true },
	{ path: "/report/sales-menu", layout: Main, component: ReportSalesMenus, authorizedRequired: true, selectedStoreRequired: true },
];