import { denormalize, normalize, schema } from "normalizr";

export const normalizeEntity = (datasources) => {
	const isArray = datasources instanceof Array;
	const dataset = isArray ? "waiters" : "waiter";
	const waiterEntity = new schema.Entity(dataset);
	const result = normalize(
		datasources, 
		isArray ? [waiterEntity] : waiterEntity
	);
	
	return result.entities;
}

export const denormalizeEntity = (datasources) => {
	const isArray = datasources.waiters;
	const dataset = isArray ? "waiters" : "waiter";
	const waiterEntity = new schema.Entity(dataset)
	const entities = isArray ? datasources.waiters : datasources.waiter;

	return denormalize(
		{ [dataset]: isArray ? Object.keys(entities) : Object.keys(entities).toString() }, 
		{ [dataset]: isArray ? [waiterEntity] : waiterEntity }, 
		{ [dataset]: entities }
	);
}