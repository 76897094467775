import { createEntityAdapter, createSelector, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { fetchUnitOfMeasures, fetchUnitOfMeasure, saveUnitOfMeasure, deleteUnitOfMeasure } from "../../thunks/unitOfMeasuresThunk";
import { fetchIngredients, fetchIngredient } from "../../thunks/ingredientsThunk";

const dataAdapter = createEntityAdapter();
const dataView = dataAdapter.getSelectors(state => state.datasources.unitOfMeasures);
const unitOfMeasuresSliceDatasource = createSlice({
	name: "datasources/unitOfMeasures",
	initialState: dataAdapter.getInitialState({
		pagination: {
			hasPrev: false,
			hasNext: false,
			currentPage: 0,
			currentRecord: 0, 
			perPage: 0, 
			totalPage: 0, 
			totalRecords: 0, 
		}
	}),
	reducers: {
		unloaded: (unitOfMeasures, _) => {
			dataAdapter.removeAll(unitOfMeasures, {});
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchUnitOfMeasures.fulfilled, (unitOfMeasures, action) => {
				if (action.payload.hasOwnProperty("pagination")) {
					unitOfMeasures.pagination = action.payload.pagination;
				}
				
				if (action.payload.hasOwnProperty("unitOfMeasures")) {
					dataAdapter.setAll(unitOfMeasures, action.payload.unitOfMeasures);
				}
			})
			.addCase(fetchUnitOfMeasure.fulfilled, (unitOfMeasure, action) => {
				if (action.payload.hasOwnProperty("unitOfMeasure")) {
					dataAdapter.upsertMany(unitOfMeasure, action.payload.unitOfMeasure);
				}
			})
			.addCase(saveUnitOfMeasure.fulfilled, (unitOfMeasure, action) => {
				dataAdapter.upsertOne(unitOfMeasure, action.payload.unitOfMeasure);
			})
			.addCase(deleteUnitOfMeasure.fulfilled, (unitOfMeasure, action) => {
				dataAdapter.removeOne(unitOfMeasure, action.payload.unitOfMeasure.id);
			})
			.addMatcher(isAnyOf(fetchIngredients.fulfilled, fetchIngredient.fulfilled), (unitOfMeasure, action) => {
				if (action.payload.hasOwnProperty("unitOfMeasure")) {
					dataAdapter.setAll(unitOfMeasure, action.payload.unitOfMeasure);
				}
			});
	}
});
export default unitOfMeasuresSliceDatasource.reducer;

export const unloadUnitOfMeasures = () => unitOfMeasuresSliceDatasource.actions.unloaded();
export const getUnitOfMeasures = dataView.selectAll;
export const getUnitOfMeasureEntities = dataView.selectEntities;
export const getUnitOfMeasurePagination = createSelector(
	state => state.datasources.unitOfMeasures.pagination,
	pagination => pagination
);
export const getUnitOfMeasure = id => createSelector(
	state => state.datasources.unitOfMeasures.entities,
	unitOfMeasures => unitOfMeasures[id]
);