import { createSelector, createSlice } from "@reduxjs/toolkit";

const flashSliceCommon = createSlice({
	name: "common/flashMessage",
	initialState: {
		show: false,
		color: "",
		message: ""
	},
	reducers: {
		closed: (flash, _) => {
			flash.show = false;
		},

		succeed: (flash, action) => {
			flash.show = true;
			flash.color = "alert-info";
			flash.message = action.payload.message;
		},

		failed: (flash, action) => {
			flash.show = true;
			flash.color = "alert-danger";
			flash.message = action.payload.message;
		},
	}
});
export default flashSliceCommon.reducer;

export const closeFlashMessage = () => flashSliceCommon.actions.closed();
export const getFlashMessage = createSelector(
	state => state.common.flashMessage,
	flashMessage => flashMessage
);
export const setFlashSuccess = message => flashSliceCommon.actions.succeed({ message });
export const setFlashError = message => flashSliceCommon.actions.failed({ message });