import { createAsyncThunk } from "@reduxjs/toolkit";
import fsrHttpInstance, { fsrHttpConfiguration } from "../serviceFsrClients";
import { normalizeEntity } from "../entities/paymentEntity";
import { setFlashSuccess, setFlashError } from "../slices/commons/flashSliceCommon";

export const fetchPayments = createAsyncThunk(
	"datasources/payments/fetchPayments",
	async (request, thunkAPI) => {
		try {
			const params = request ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.get("vendor/payments/index", {...params, ...fsrHttpConfiguration()}));
			const normalized = normalizeEntity(response.data.payments);
			
			return { 
				...normalized, 
				pagination: response.data.pagination || {} 
			};
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The payment could not be loaded. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const fetchPayment = createAsyncThunk(
	"datasources/payments/fetchPayment",
	async (request, thunkAPI) => {
		try {
			const params = request ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.get("vendor/payments/view", {...params, ...fsrHttpConfiguration()}));
			const normalized = normalizeEntity(response.data.payment);
			
			return normalized;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The payment could not be loaded. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const savePayment = createAsyncThunk(
	"datasources/payments/savePayment",
	async (request, thunkAPI) => {
		try {
			const formData = { ...request };
			const action = formData.id ? "edit" : "add";
			const response = await fsrHttpInstance.then(fsrService => fsrService.post(`vendor/payments/${action}`, formData, fsrHttpConfiguration()));
			
			thunkAPI.dispatch(setFlashSuccess("The payment has been saved."));
			return response.data;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The payment could not be saved. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const deletePayment = createAsyncThunk(
	"datasources/payments/deletePayment",
	async (request, thunkAPI) => {
		try {
			const params = request.query ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.post("vendor/payments/delete", null, {...params, ...fsrHttpConfiguration()}));

			thunkAPI.dispatch(setFlashSuccess("The payment has been deleted."));
			return response.data;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The payment could not be deleted. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);