import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { fetchDiningTables, fetchDiningTable, saveDiningTable, deleteDiningTable } from "../../thunks/diningTablesThunk";

const dataAdapter = createEntityAdapter();
const dataView = dataAdapter.getSelectors(state => state.datasources.diningTables);
const diningTablesSliceDatasource = createSlice({
	name: "datasources/diningTables",
	initialState: dataAdapter.getInitialState({
		pagination: {}
	}),
	reducers: {
		unloaded: (diningTables, _) => {
			dataAdapter.removeAll(diningTables, {});
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchDiningTables.fulfilled, (diningTables, action) => {
				if (action.payload.hasOwnProperty("pagination")) {
					diningTables.pagination = action.payload.pagination;
				}

				if (action.payload.hasOwnProperty("diningTables")) {
					dataAdapter.setAll(diningTables, action.payload.diningTables);
				}
			})
			.addCase(fetchDiningTable.fulfilled, (diningTable, action) => {
				if (action.payload.hasOwnProperty("diningTable")) {
					dataAdapter.upsertMany(diningTable, action.payload.diningTable);
				}
			})
			.addCase(saveDiningTable.fulfilled, (diningTable, action) => {
				dataAdapter.upsertOne(diningTable, action.payload.diningTable);
			})
			.addCase(deleteDiningTable.fulfilled, (diningTable, action) => {
				dataAdapter.removeOne(diningTable, action.payload.diningTable.id);
			});
	}
});
export default diningTablesSliceDatasource.reducer;

export const unloadDiningTables = () => diningTablesSliceDatasource.actions.unloaded();
export const getDiningTables = dataView.selectAll;
export const getDiningTableEntities = dataView.selectEntities;
export const getDiningTablePagination = createSelector(
	state => state.datasources.diningTables.pagination,
	pagination => pagination
);
export const getDiningTable = id => createSelector(
	state => state.datasources.diningTables.entities,
	diningTables => diningTables[id]
);