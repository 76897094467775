import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { unwrapResult } from '@reduxjs/toolkit'
import { fetchUnitOfMeasure, saveUnitOfMeasure } from "../../../store/thunks/unitOfMeasuresThunk";
import { getUnitOfMeasure, unloadUnitOfMeasures } from "../../../store/slices/datasources/unitOfMeasuresSliceDatasource";
import WithLoadingPage from "../commons/withLoadingPage";
import Flash from "../elements/flash";
import PageSubHeader from "../elements/pageSubHeader";

const UnitOfMeasureForm = props => {
    
	const dispatch = useDispatch();
    const history = useHistory();
	const routeParams = useParams();
    const { id: unitOfMeasureId } = routeParams;
	const unitOfMeasure = useSelector(getUnitOfMeasure(unitOfMeasureId));
	const { setLoading } = props;
	const [request, setRequest] = React.useState({ id: null, name: "", description: "" });
	const [error, setError] = React.useState({ name: null });

    React.useEffect(() => {
        return () => {
            dispatch(unloadUnitOfMeasures());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        async function initialize() {
            setLoading(true)
            if (parseInt(unitOfMeasureId)) {
                await dispatch(fetchUnitOfMeasure(getQueryFilter()));
            }
            setLoading(false);
        }
        
        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, unitOfMeasureId]); 
	
	React.useEffect(() => {
		if (unitOfMeasure) {
			const mapViewToModel = {
				id: unitOfMeasure.id,
				name: unitOfMeasure.name,
				description: unitOfMeasure.description
			}

			setRequest(prev => { 
                return { 
                    ...prev, 
                    ...mapViewToModel 
                }
            });
		}
	}, [unitOfMeasure]);

    const getQueryFilter = () => {
        const queryFilter = {
            id: unitOfMeasureId,
        };

        return {
            query: queryFilter
        };
    }

	const handleInputChange = element => {
		const { name, value } = element.target;
		setRequest({...request, [name]: value});
	}

	const handleFormSubmit = async event => {
		event.preventDefault();
		setLoading(true);
		await dispatch(saveUnitOfMeasure(request))
			.then(unwrapResult)
			.then(() => {
				setLoading(false);
				setError({ ...error, name: null });
				history.push('/data/unit-of-measures');
			})
			.catch(exception => {
				setLoading(false);
				setError(exception.errors);
			});
	}

    return (
        <React.Fragment>
            <PageSubHeader title={`${unitOfMeasureId === "add" ? "New" : "Edit"} Unit Of Measure`} />
            <div className="mx-auto w-2/4">
                <Flash />
                <div className="classic card">
                    <div className="card-header bg-light-600">
                        <h3 className="card-title">Unit Of Measure Form</h3>
                    </div>
                    <div className="card-body text-left">
                        <form className="w-full" onSubmit={handleFormSubmit}>
                            <div className="form-group">
                                <label className="form-label" htmlFor="name">Name</label>
                                <input id="name" name="name" type="text" className="form-control" value={request.name} onChange={handleInputChange} />
                            </div>
                            <div className="form-group">
                                <label className="form-label" htmlFor="description">Description</label>
                                <textarea id="description" name="description" type="text" className="form-control" value={request.description} onChange={handleInputChange} rows="5" resize="none" />
                            </div>
                            <div className="form-group text-right">
                                <NavLink to="/data/unit-of-measures" className="btn btn-secondary mr-4">
                                    <i className="las la-undo" />
                                    Back
                                </NavLink>
                                <button className="btn btn-primary mr-4">
                                    <i className="las la-save" />
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
 
export default WithLoadingPage(UnitOfMeasureForm);