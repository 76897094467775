import { configure } from "../../config/app";
import { authorize } from "../thunks/vendorsThunk";
import { selectedStore } from "../slices/authentications/storeSliceAuthentication";
import { loggedout } from "../slices/authentications/vendorSliceAuthentication";

const localStorageMiddleware = store => next => action => {
	if (action.type === authorize.fulfilled.type) {
		localStorage.setItem(configure.StorageKey.Vendor, JSON.stringify(action.payload.vendor));
	}

	if (action.type === selectedStore.type) {
		localStorage.setItem(configure.StorageKey.CurrentStore, JSON.stringify(action.payload.store));
	}
	
	if (action.type === loggedout.type) {
		localStorage.removeItem(configure.StorageKey.Vendor);
		localStorage.removeItem(configure.StorageKey.CurrentStore);
	}

	next(action);
}

export default localStorageMiddleware;