import { createAsyncThunk } from "@reduxjs/toolkit";
import fsrHttpInstance, { fsrHttpConfiguration } from "../serviceFsrClients";
import { normalizeEntity } from "../entities/voidReasonEntity";
import { setFlashSuccess, setFlashError } from "../slices/commons/flashSliceCommon";

export const fetchVoidReasons = createAsyncThunk(
	"datasources/voidReasons/fetchVoidReasons",
	async (request, thunkAPI) => {
		try {
			const params = request ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.get("vendor/void-reasons/index", {...params, ...fsrHttpConfiguration()}));
			const normalized = normalizeEntity(response.data.voidReasons);
			
			return { 
				...normalized, 
				pagination: response.data.pagination || {}
			};
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The void reason could not be loaded. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const fetchVoidReason = createAsyncThunk(
	"datasources/voidReasons/fetchVoidReason",
	async (request, thunkAPI) => {
		try {
			const params = request ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.get("vendor/void-reasons/view", {...params, ...fsrHttpConfiguration()}));
			const normalized = normalizeEntity(response.data.voidReason);
			
			return normalized;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The void reason could not be loaded. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const saveVoidReason = createAsyncThunk(
	"datasources/voidReasons/saveVoidReason",
	async (request, thunkAPI) => {
		try {
			const formData = { ...request };
			const action = formData.id ? "edit" : "add";
			const response = await fsrHttpInstance.then(fsrService => fsrService.post(`vendor/void-reasons/${action}`, formData, fsrHttpConfiguration()));

			thunkAPI.dispatch(setFlashSuccess("The void reason has been saved."));
			return response.data
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The void reason could not be saved. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const deleteVoidReason = createAsyncThunk(
	"datasources/voidReasons/deleteVoidReason",
	async (request, thunkAPI) => {
		try {
			const params = request.query ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.post("vendor/void-reasons/delete", null, {...params, ...fsrHttpConfiguration()}));

			thunkAPI.dispatch(setFlashSuccess("The void reason has been deleted."));
			return response.data;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The void reason could not be deleted. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);