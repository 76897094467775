import React from "react";
import { fetchStoreBranches } from "../../../store/thunks/storeBranchesThunk";
import { useDispatch, useSelector } from "react-redux";
import { getStoreBranches, getStoreBranchPagination, unloadStoreBranches } from "../../../store/slices/datasources/storeBranchesSliceDatasource";
import WithLoadingPage from "../commons/withLoadingPage";
import PageSubHeader from "../elements/pageSubHeader";
import Paginator from "../elements/paginator";
import DataTable from "./components/dataTable";

const StoreBranches = props => {
    
    const dispatch = useDispatch();
    const storeBranches = useSelector(getStoreBranches);
    const pagination = useSelector(getStoreBranchPagination);
    const { setLoading } = props;
    const [search, setSearch] = React.useState("");
    const [currentPage, setCurrentPage] = React.useState(1);

    React.useEffect(() => {
        return () => {
            dispatch(unloadStoreBranches());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {		
        async function initialize() {
            setLoading(true);
            await dispatch(fetchStoreBranches(getQueryFilter()));
            setLoading(false);
        }
		
        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, currentPage]); 
	
    const getQueryFilter = () => {
        const queryFilter = {
            search: search,
            page: currentPage,
            paginate: 25
        };

        return {
            query: queryFilter
        };
    }

    const handleSearchInputChange = element => {
		const { value } = element.target;
		setSearch(value);
	}

    const handleSearch = async () => {
        setLoading(true);
        await dispatch(fetchStoreBranches(getQueryFilter()));
        setLoading(false);
    }

    const handlePageChange = page => {
        if (currentPage !== page) {
			setCurrentPage(page);
		}
    }

    return (
        <React.Fragment>
            <PageSubHeader title="Store Branches" />
            <div className="mx-auto w-3/4">
                <div className="classic card">
                    <div className="card-header bg-light-600">
                        <div className="flex align-middle">
                            <div className="flex-grow">
                                <div className="input-group">
                                    <input id="search" name="search" type="text" className="form-control input-group-item" placeholder="Search..." value={search} onChange={handleSearchInputChange} />
                                    <button className="btn btn-primary uppercase input-group-item" onClick={handleSearch}>
                                        <i className="las la-search" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body text-left">
                        <DataTable storeBranches={storeBranches} />
                        <Paginator pagination={pagination} handlePageChange={handlePageChange} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
 
export default WithLoadingPage(StoreBranches);