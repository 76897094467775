import React from "react";
import Tippy from "@tippyjs/react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../../store/slices/authentications/vendorSliceAuthentication";
import { getCurrentStore } from "../../helpers/authentication";

const PageHeader = () => {
    
    const history = useHistory();
	const dispatch = useDispatch();
    const { name: storeBranchName } = getCurrentStore();

    return (
        <div className="pageHeader">
            <div className="compact-header">
                <div className="flex">
                    <div className="brand">
                        <div className="logo">
                            <h1 className="text-white">FSR</h1>
                        </div>
                    </div>
                </div>
                <div className="flex items-center ml-10">
                    <h3>{storeBranchName}</h3>
                </div>
                <div className="flex items-center ml-auto">
                    <div className="dropdown">
                        <Tippy 
                            content={
                                <div className="w-250">
                                    {/*
                                    <div className="p-5">
                                        <Link to="/profile" className="dark:text-gray-500 dark:hover:text-primary flex items-center text-gray-700 hover:text-primary">
                                            <span className="la la-user-circle text-2xl leading-none mr-2"/>View Profile
                                        </Link>
                                        <Link to="/change-password" className="dark:text-gray-500 dark:hover:text-primary flex items-center text-gray-700 hover:text-primary mt-5">
                                            <span className="la la-key text-2xl leading-none mr-2" />Change Password
                                        </Link>
                                    </div>
                                    <hr className="dark:border-gray-900 mb-0" />
                                    */}
                                    <div className="p-20">
                                        <Link to="/logout" className="flex text-lg text-gray-700 hover:text-primary items-center" onClick={e => { e.preventDefault(); dispatch(logout()); history.replace('/login'); }}>
                                            <span className="la la-power-off text-24 leading-none mr-8" />Logout
                                        </Link>
                                    </div>
                                </div>
                            }
                            animation="shift-toward-extreme"
                            arrow={true}
                            theme="light-border"
                            interactive="true"
                            allowHTML="true"
                            placement="bottom-start"
                            appendTo="parent"
                        >
                            <button className="flex items-center ml-16 text-gray-700 focus:outline-none">
                                <span className="dark:bg-gray-900 dark:border-gray-600 dark:text-gray-500 flex items-center justify-center w-48 h-48 rounded-full bg-gray-200 border-2 border-gray-600 text-xl text-gray-700">
                                    <i className="las la-user text-32 leading-none" />
                                </span>
                            </button>
                        </Tippy>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default PageHeader;