import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { fetchSalesInvoices, fetchSalesInvoice } from "../../thunks/salesInvoicesThunk";

const dataAdapter = createEntityAdapter();
const dataView = dataAdapter.getSelectors(state => state.datasources.salesInvoicePayments);
const salesInvoicePaymentsSliceDatasource = createSlice({
	name: "datasources/salesInvoicePayments",
	initialState: dataAdapter.getInitialState(),
	reducers: {
		unloaded: (salesInvoicePayments, _) => {
			dataAdapter.removeAll(salesInvoicePayments, {});
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchSalesInvoices.fulfilled, (salesInvoicePayments, action) => {
				if (action.payload.hasOwnProperty("salesInvoicePayments")) {
					dataAdapter.upsertMany(salesInvoicePayments, action.payload.salesInvoicePayments);
				}
			})
			.addCase(fetchSalesInvoice.fulfilled, (salesInvoicePayments, action) => {
                if (action.payload.hasOwnProperty("salesInvoicePayments")) {
                    dataAdapter.upsertMany(salesInvoicePayments, action.payload.salesInvoicePayments);
                }
			});
	}
});
export default salesInvoicePaymentsSliceDatasource.reducer;

export const unloadSalesInvoicePayments = () => salesInvoicePaymentsSliceDatasource.actions.unloaded();
export const getSalesInvoicePaymentEntities = dataView.selectEntities;