import { NavLink } from "react-router-dom";

const DataTable = ({ diningTableSections, storeBranchEntities, handleDelete }) => {

    return (
        <table className="table table-striped">
            <thead>
                <tr>
                    <th className="text-left" width="25%">Branch</th>
                    <th className="text-left" width="15%">Name</th>
                    <th className="text-left">Description</th>
                    <th className="text-left" width="1%"/>
                    <th className="text-left" width="1%"/>
                </tr>
            </thead>
            <tbody>
                {diningTableSections.map(diningTableSection => 
					<tr key={diningTableSection.id}>
						<td>{storeBranchEntities[diningTableSection.store_branch_id]?.name}</td>
						<td>{diningTableSection.name}</td>
						<td>{diningTableSection.description}</td>
						<td className="text-right">
							<NavLink to={`/data/dining-table-sections/${diningTableSection.id}`} className="btn btn-primary btn-icon">
                                <span className="las la-pen" />
                            </NavLink>
                        </td>
						<td className="text-right">
							<button className="btn btn-danger btn-icon" onClick={() => handleDelete(diningTableSection)}>
                                <span className="las la-trash-alt" />
                            </button>
						</td>
					</tr>
				)}
            </tbody>
        </table>
    );
}
 
export default DataTable;