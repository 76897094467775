import { createAsyncThunk } from "@reduxjs/toolkit";
import fsrHttpInstance, { fsrHttpConfiguration } from "../serviceFsrClients";
import { normalizeEntity } from "../entities/waiterEntity";
import { setFlashSuccess, setFlashError } from "../slices/commons/flashSliceCommon";

export const fetchWaiters = createAsyncThunk(
	"datasources/waiters/fetchWaiters",
	async (request, thunkAPI) => {
		try {
			const params = request ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.get("vendor/waiters/index", {...params, ...fsrHttpConfiguration()}));
			const normalized = normalizeEntity(response.data.waiters);
			
			return { 
				...normalized, 
				pagination: response.data.pagination || {}
			};
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The waiter could not be loaded. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const fetchWaiter = createAsyncThunk(
	"datasources/waiters/fetchWaiter",
	async (request, thunkAPI) => {
		try {
			const params = request ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.get("vendor/waiters/view", {...params, ...fsrHttpConfiguration()}));
			const normalized = normalizeEntity(response.data.waiter);
			
			return normalized;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The waiter could not be loaded. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const saveWaiter = createAsyncThunk(
	"datasources/waiters/saveWaiter",
	async (request, thunkAPI) => {
		try {
			const formData = { ...request };
			const action = formData.id ? "edit" : "add";
			const response = await fsrHttpInstance.then(fsrService => fsrService.post(`vendor/waiters/${action}`, formData, fsrHttpConfiguration()));

			thunkAPI.dispatch(setFlashSuccess("The waiter has been saved."));
			return response.data;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The waiter could not be saved. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const deleteWaiter = createAsyncThunk(
	"datasources/waiters/deleteWaiter",
	async (request, thunkAPI) => {
		try {
			const params = request.query ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.post("vendor/waiters/delete", null, {...params, ...fsrHttpConfiguration()}));

			thunkAPI.dispatch(setFlashSuccess("The waiter has been deleted."));
			return response.data;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The waiter could not be deleted. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const generatePin = createAsyncThunk(
	"datasources/waiters/generatePin",
	async (request, thunkAPI) => {
		try {
			const params = request ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.get("vendor/waiters/generate-pin", {...params, ...fsrHttpConfiguration()}));

			thunkAPI.dispatch(setFlashSuccess("The pin has been generated, and send to email."));
			return response.data;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The pin could not be generated. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);