import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { fetchCustomers, fetchCustomer, saveCustomer, deleteCustomer } from "../../thunks/customersThunk";

const dataAdapter = createEntityAdapter();
const dataView = dataAdapter.getSelectors(state => state.datasources.customers);
const customersSliceDatasource = createSlice({
	name: "datasources/customers",
	initialState: dataAdapter.getInitialState({
		pagination: {}
	}),
	reducers: {
		unloaded: (customers, _) => {
			dataAdapter.removeAll(customers, {});
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchCustomers.fulfilled, (customers, action) => {
				if (action.payload.hasOwnProperty("pagination")) {
					customers.pagination = action.payload.pagination;
				}

				if (action.payload.hasOwnProperty("customers")) {
					dataAdapter.setAll(customers, action.payload.customers);
				}
			})
			.addCase(fetchCustomer.fulfilled, (customer, action) => {
				if (action.payload.hasOwnProperty("customer")) {
					dataAdapter.upsertMany(customer, action.payload.customer);
				}
			})
			.addCase(saveCustomer.fulfilled, (customer, action) => {
				dataAdapter.upsertOne(customer, action.payload.customer);
			})
			.addCase(deleteCustomer.fulfilled, (customer, action) => {
				dataAdapter.removeOne(customer, action.payload.customer.id);
			});
	}
});
export default customersSliceDatasource.reducer;

export const unloadCustomers = () => customersSliceDatasource.actions.unloaded();
export const getCustomers = dataView.selectAll;
export const getCustomerEntities = dataView.selectEntities;
export const getCustomerPagination = createSelector(
	state => state.datasources.customers.pagination,
	pagination => pagination
);
export const getCustomer= id => createSelector(
	state => dataView.selectById(state, id),
	customer => customer
);