import { createAsyncThunk } from "@reduxjs/toolkit";
import fsrHttpInstance, { fsrHttpConfiguration } from "../serviceFsrClients";
import { setFlashError } from "../slices/commons/flashSliceCommon";
import { normalizeEntity } from "../entities/salesOrderEntity";

export const fetchSalesOrders = createAsyncThunk(
	"datasource/salesOrders/fetchSalesOrders",
	async (request, thunkAPI) => {
		try {
			const params = request ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.get("vendor/sales-orders/index", {...params, ...fsrHttpConfiguration()}));
			const normalized = normalizeEntity(response.data.salesOrders);

			return normalized;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The order could not be loaded. Please, try again."))
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const fetchSalesOrder = createAsyncThunk(
	"datasource/salesOrders/fetchSalesOrder",
	async (request, thunkAPI) => {
		try {
			const params = request ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.get("vendor/sales-orders/view", {...params, ...fsrHttpConfiguration()}));
			const normalized = normalizeEntity(response.data.salesOrder);

			return { 
				...normalized
			};
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The order could not be loaded. Please, try again."))
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);